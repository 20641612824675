import React, { useState, useRef, useEffect } from "react";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { SpinnerCircular } from "spinners-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSocket } from "../../context/SocketContext";
import ImagePreview from "../../utils/ImagePreview";
import { UploadFile } from "@mui/icons-material";
import {
  base64ToBlob,
  blobToBase64,
  calculateImageSizeInMB,
  compressImage,
} from "../../utils/ImageCompression";
import Modal from "../Modal";
import ApiInterceptor from "../../utils/ApiInterceptor";

function Formaffiliate() {
  const socket = useSocket();
  const [showModal, setShowModal] = useState(false);
  const [campId, setId] = useState();
  const [name, setName] = useState("");
  const [commissionPercentage, setCommissionPercentage] = useState(null);
  const [guidelines, setGuidelines] = useState("");
  const [platformsAllowed, setPlatformsAllowed] = useState("");
  const [url, setUrl] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // Followers useState
  const [micro, setMicro] = useState(false);
  const [nano, setNano] = useState(true);
  const [mega, setMega] = useState(false);
  const [any, setAny] = useState(false);
  const [mid, setMid] = useState(false);
  const [macro, setMacro] = useState(false);
  const [requiredInfluencers, setRequiredInfluencers] = useState("");
  const [cpm, setCpm] = useState("");
  const [category, setCategory] = useState("");
  const [type, setType] = useState("post");
  const [summary, setSummary] = useState("");
  // const [cost, setCost] = useState("");
  // const [visible1, setVisible1] = useState(false);
  // const [visible2, setVisible2] = useState(false);
  const formRef = useRef();

  // image useState starts
  const [cover, setCover] = useState("");
  const [sample, setSample] = useState("");
  const [sample1, setSample1] = useState("");
  const [sample2, setSample2] = useState("");
  const [selectedImg1, setSelectedImg1] = useState("");
  const [selectedImg2, setSelectedImg2] = useState("");
  const [selectedImg3, setSelectedImg3] = useState("");
  const [selectedImg4, setSelectedImg4] = useState("");
  const [previewSource1, setPreviewSource1] = useState("");
  const [previewSource2, setPreviewSource2] = useState("");
  const [previewSource3, setPreviewSource3] = useState("");
  const [previewSource4, setPreviewSource4] = useState("");
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [fileInputState1, setFileInputState1] = useState("");
  const [fileInputState2, setFileInputState2] = useState("");
  const [fileInputState3, setFileInputState3] = useState("");
  const [fileInputState4, setFileInputState4] = useState("");
  // image useState ends
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const handleFileInputChange1 = (e) => {
    const file = e.target.files[0];
    if (file) {
      previewFile1(file);
      setSelectedImg1(file);
      setFileInputState1(e.target.value);
      setCategory("");
      setType("");
      setSummary("");
      setRequiredInfluencers("");
      setCpm("");
      handleImage1(file);
    }
  };

  const handleFileInputChange2 = (e) => {
    const file = e.target.files[0];
    if (file) {
      previewFile2(file);
      setSelectedImg2(file);
      setFileInputState2(e.target.value);
      handleImage2(file);
    }
  };
  const handleFileInputChange3 = (e) => {
    const file = e.target.files[0];
    if (file) {
      previewFile3(file);
      setSelectedImg3(file);
      setFileInputState3(e.target.value);
      handleImage3(file);
    }
  };
  const [uploadProgress, setUploadProgress] = useState(0);
  const handleFileInputChange4 = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg"
      ) {
        return toast.error("Please upload a valid  file");
      }
      previewFile4(file);
      setSelectedImg4(file);
      setFileInputState4(e.target.value);
      uploadImage4(file);
      // handleImage4(file)
    }
  };

  const previewFile1 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource1(reader.result);
    };
  };

  const previewFile2 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource2(reader.result);
    };
  };

  const previewFile3 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource3(reader.result);
    };
  };
  const previewFile4 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource4(reader.result);
    };
  };

  const [uploaded, setUploaded] = useState(false);
  const [uploaded2, setUploaded2] = useState(false);
  const [uploaded3, setUploaded3] = useState(false);
  const [uploaded4, setUploaded4] = useState(false);
  const handleImage1 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      uploadImage1(file);
      setUploaded(true);
    };
    reader.onerror = () => {
      setUploaded(false);
      console.error("AHHHHHHHH!!");
    };
  };

  const handleImage2 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      uploadImage2(file);
      setUploaded2(true);
    };
    reader.onerror = () => {
      console.error("AHHHHHHHH!!");
    };
  };
  const handleImage3 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      uploadImage3(file);
      setUploaded3(true);
    };
    reader.onerror = () => {
      console.error("AHHHHHHHH!!");
    };
  };
  // const handleImage4 = (file) => {

  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onloadend = () => {
  //     uploadImage4(reader.result);
  //     setUploaded4(true);
  //   };
  //   reader.onerror = () => {
  //     console.error("AHHHHHHHH!!");
  //   };
  // };

  const uploadImage1 = async (file) => {
    try {
      const maxSizeMB = 10;
      const maxSizeBytes = maxSizeMB * 1024 * 1024;

      if (file.size > maxSizeBytes) {
        toast.error(`Image size should be less than ${maxSizeMB} MB`);
        return;
      }

      setLoading1(true);

      const formData = new FormData();
      formData.append("file", file);

      const { data } = await ApiInterceptor.post(
        "upload/files",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        }
      );

      setFileInputState1("");
      setPreviewSource1("");
      setCover(data.publicUrl);
      setLoading1(false);
      toast.success("Image uploaded");
    } catch (err) {
      console.error(err);
      toast.error(err.message || "An unexpected error occurred.");
    } finally {
      setLoading1(false);
    }
  };

  const uploadImage2 = async (file) => {
    try {
      const maxSizeMB = 10;
      const maxSizeBytes = maxSizeMB * 1024 * 1024;

      if (file.size > maxSizeBytes) {
        toast.error(`Image size should be less than ${maxSizeMB} MB`);
        return;
      }

      setLoading2(true);

      const formData = new FormData();
      formData.append("file", file);

      const { data } = await ApiInterceptor.post(
        "upload/files",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        }
      );
      setFileInputState2("");
      setPreviewSource2("");
      setSample(data.publicUrl);
      setLoading2(false);
      toast.success("Image uploaded");
    } catch (err) {
      console.error(err);
      toast.error(err.message || "An unexpected error occurred.");
    } finally {
      setLoading2(false);
    }
  };

  const uploadImage3 = async (file) => {
    try {
      const maxSizeMB = 10;
      const maxSizeBytes = maxSizeMB * 1024 * 1024;

      if (file.size > maxSizeBytes) {
        toast.error(`Image size should be less than ${maxSizeMB} MB`);
        return;
      }

      setLoading3(true);

      const formData = new FormData();
      formData.append("file", file);

      const { data } = await ApiInterceptor.post(
        "upload/files",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        }
      );

      setFileInputState3("");
      setPreviewSource3("");
      setSample1(data.publicUrl); // Assuming data contains the URL of the uploaded image
      toast.success("Image uploaded");
      setUploaded3(true);
    } catch (err) {
      console.error(err);
      toast.error(err.message || "An unexpected error occurred.");
    } finally {
      setLoading3(false);
    }
  };

  const uploadImage4 = async (file) => {
    try {
      const maxSizeMB = 150;
      const maxSizeBytes = maxSizeMB * 1024 * 1024;

      if (file.size > maxSizeBytes) {
        toast.error(`Video size should be less than ${maxSizeMB} MB`);
        return;
      }

      setLoading4(true);
      const formData = new FormData();
      formData.append("file", file);

      const { data } = await ApiInterceptor.post("upload/files", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      });
      setFileInputState4("");
      setPreviewSource4("");
      setSample2(data.publicUrl); // Assuming data contains the URL of the uploaded video thumbnail
      toast.success("Video uploaded");
      setUploaded4(true);
    } catch (err) {
      console.error(err);
      toast.error(err.message || "An unexpected error occurred.");
    } finally {
      setLoading4(false);
    }
  };

  const [userId, setUserId] = useState("");
  const currentUserId = Cookies.get("userid");

  useEffect(() => {
    setUserId(currentUserId);
  }, []);

  const create = async (e) => {
    e.preventDefault();
    if (name === "") {
      toast.error("Please enter the campaign name");
    }
    if (commissionPercentage === "") {
      toast.error("Please enter the commission");
    }
    if (platformsAllowed === "") {
      toast.error("Please enter the platform allows");
    }

    if (guidelines.length < 30) {
      return toast.error("Guidelines must be atleast 20 characters long");
    }
    if (url === "") {
      toast.error("Please enter the url");
    }
    if (!selectedImg1 || !selectedImg2 || !selectedImg3)
      return toast.error("Please upload all images");

    try {
      const imagePaths = [sample, sample1, sample2];

      const { data } = await ApiInterceptor.post(
        "affiliate",
        {
          company: currentUserId,
          affiliate_campaign_name: name,
          commission: commissionPercentage,
          start_date: selectedDate,
          end_date: endDate,
          guide_lines: guidelines,
          platform_allows: platformsAllowed,
          url: url,
          followers_category: Object.keys({
            nano,
            micro,
            mega,
            mid,
            macro,
            any,
          }).find((key) => ({ nano, micro, mega, mid, macro, any }[key])),
          cover_image: cover,
          sample_posts: imagePaths,
        },
        config
      );
      const notification = await ApiInterceptor.post("notifications/post", {
        data: {
          influencerId: currentUserId,
          notificationTitle: "Affiliate",
          notificationDesc: `Your Affiliate Campaign ${name} is successfully created`,
        },
      });

      toast.success("Campaign created successfully");
      setShowModal(true);
      setId(data?.affiliate_id);
      formRef.current.reset();

      //reset form values after affiliate campaign is created
      setName("");
      setCommissionPercentage(null);
      setGuidelines("");
      setPlatformsAllowed("");
      setUrl("");
      setSelectedDate(null);
      setEndDate(null);
      setMicro(false);
      setNano(true);
      setMega(false);
      setAny(false);
      setMid(false);
      setMacro(false);
      setSelectedImg1("");
      // previewSource1("");
      // previewSource2("");
      // previewSource3("");
      // previewSource4("");
      setCover("");
      setSample("");
      setSample1("");
      setSample2("");
      setUploaded(false);
      setUploaded2(false);
      setUploaded3(false);
      setUploaded4(false);
      setSelectedImg2("");
      setSelectedImg3("");
      setSelectedImg4("");
    } catch (error) {
      console.log(error);
    }
  };

  const threeDaysAhead = (start) => {
    const date = new Date(start);
    date.setDate(date.getDate() + 3);
    return date;
  };

  return (
    <div className="p-[20px] md:p-2 shadow-lg shadow-gray-400 bg-white h-auto w-full">
      <div className="md:w-full md:p-[15px]">
        <h1 className="text-3xl mb-5 font-bold  text-center text-gray-700">
          Create Affiliate Campaign
        </h1>
        <form ref={formRef} onSubmit={create}>
          <div className="flex flex-col gap-1">
            <label>Affiliate Campaign Name</label>
            <input
              className="bg-white pl-2 text-[#b2aeae] my-[10px] p-3 w-full focus:outline-none border border-blue-200 focus:border-blue-400 rounded-md"
              type="text"
              placeholder="Affiliate Campaign name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="flex flex-col">
            <label>Commission (%)</label>
            <input
              className="bg-white pl-2 text-[#b2aeae] my-[10px] p-3 w-full focus:outline-none border border-blue-200 focus:border-blue-400 rounded-md"
              type="number"
              name="commision"
              placeholder="Commission"
              required
              value={commissionPercentage}
              onChange={(e) => setCommissionPercentage(e.target.value)}
            />
          </div>

          {/* date 1  */}
          <div className="w-full mb-[10px] flex justify-between space-x-[20px]">
            <div className="w-[50%]">
              <label>Start date</label>
              <DatePicker
                className="bg-white pl-2 text-[#b2aeae] my-[10px] p-3 w-full focus:outline-none border border-blue-200 focus:border-blue-400 rounded-md"
                selected={selectedDate}
                dateFormat="dd/MM/yyyy"
                name="startDate"
                placeholderText="Start Date"
                required
                onChange={(date) => {
                  setSelectedDate(date);
                  setEndDate(null);
                }}
                minDate={new Date()}
                maxDate={endDate}
              />
            </div>
            <div className="w-[50%]">
              <label>End date</label>
              {selectedDate ? (
                <DatePicker
                  className="bg-white pl-2 text-[#b2aeae] my-[10px] p-3 w-full focus:outline-none border border-blue-200 focus:border-blue-400 rounded-md"
                  selected={endDate}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="End Date"
                  name="endDate"
                  required
                  onChange={(date) => setEndDate(date)}
                  minDate={threeDaysAhead(selectedDate)}
                />
              ) : (
                <div className="pl-2 text-[#b2aeae] my-[10px] p-3 w-full  rounded-md">
                  Select Start Date First
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-col gap-1">
            <label>Guidelines</label>
            <textarea
              className="w-full mb-[10px] pl-2 focus:outline-none p-3 border border-blue-200 focus:border-blue-400 rounded-md"
              placeholder="Provide Guidelines"
              rows={6}
              name="guidelines"
              value={guidelines}
              required
              onChange={(e) => setGuidelines(e.target.value)}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label className="text-gray-700 font-medium">
              Platforms Allowed
            </label>
            <select
              className="bg-white pl-3 text-gray-600 p-3 w-full focus:outline-none border border-blue-300 focus:border-blue-500 rounded-md shadow-sm"
              name="platformAllows"
              value={platformsAllowed}
              required
              onChange={(e) => setPlatformsAllowed(e.target.value)}
            >
              <option value="" disabled className="text-gray-500">
                Select a platform
              </option>
              <option
                value="instagram"
                className="bg-blue-100 text-black font-semibold"
              >
                Instagram Reels
              </option>
              <option
                value="youtube"
                className="bg-blue-100 text-black font-semibold"
              >
                YouTube Shorts
              </option>
            </select>
          </div>

          {/* Select Followers */}
          <h1 className="font-bold my-[10px]">Select Followers Limits</h1>
          <div className="flex flex-wrap gap-[10px]">
            <h1
              className={
                !nano
                  ? "w-fit cursor-pointer px-[9px] text-[#b2aeae] py-2 border border-blue-300 rounded-3xl"
                  : "text-white cursor-pointer px-[9px] w-fit py-2 bg-blue-500 rounded-3xl"
              }
              onClick={() => {
                setNano(!nano);
                setMacro(false);
                setAny(false);
                setMega(false);
                setMicro(false);
                setMid(false);
              }}
            >
              Nano Influencers: 5K - 10K
            </h1>
            <h1
              className={
                !micro
                  ? "px-[9px] w-fit cursor-pointer text-[#b2aeae] py-2 border border-blue-300 rounded-3xl"
                  : "text-white cursor-pointer px-[9px] w-fit py-2 bg-blue-500 rounded-3xl"
              }
              onClick={() => {
                setMicro(!micro);
                setMacro(false);
                setAny(false);
                setMega(false);
                setNano(false);
                setMid(false);
              }}
            >
              Micro: 10K - 50K
            </h1>
            <h1
              className={
                !mega
                  ? "px-[9px] w-fit cursor-pointer text-[#b2aeae] py-2 border border-blue-300 rounded-3xl"
                  : "text-white cursor-pointer px-[9px] w-fit py-2 bg-blue-500 rounded-3xl"
              }
              onClick={() => {
                setMega(!mega);
                setMacro(false);
                setAny(false);
                setNano(false);
                setMicro(false);
                setMid(false);
              }}
            >
              Mega: 1M - 20M
            </h1>
            <h1
              className={
                !mid
                  ? "px-[9px] w-fit cursor-pointer text-[#b2aeae] py-2 border border-blue-300 rounded-3xl"
                  : "text-white cursor-pointer px-[9px] w-fit py-2 bg-blue-500 rounded-3xl"
              }
              onClick={() => {
                setMid(!mid);
                setMacro(false);
                setAny(false);
                setMega(false);
                setMicro(false);
                setNano(false);
              }}
            >
              Mid Tier: 50K - 500K
            </h1>
            <h1
              className={
                !macro
                  ? "px-[9px] w-fit cursor-pointer text-[#b2aeae] py-2 border border-blue-300 rounded-3xl"
                  : "text-white cursor-pointer px-[9px] w-fit py-2 bg-blue-500 rounded-3xl"
              }
              onClick={() => {
                setMacro(!macro);
                setNano(false);
                setAny(false);
                setMega(false);
                setMicro(false);
                setMid(false);
              }}
            >
              Macro: 500K - 1M
            </h1>
            <h1
              className={
                !any
                  ? "px-[9px] w-fit cursor-pointer text-[#b2aeae] py-2 border border-blue-300 rounded-3xl"
                  : "text-white cursor-pointer px-[9px] w-fit py-2 bg-blue-500 rounded-3xl"
              }
              onClick={() => {
                setAny(!any);
                setMacro(false);
                setNano(false);
                setMega(false);
                setMicro(false);
                setMid(false);
              }}
            >
              Get any followers
            </h1>
          </div>

          <h1 className="font-bold my-[10px]">Redirect url</h1>
          <input
            className="pl-6 border border-blue-300 rounded-3xl text-blue-500 p-3 w-full focus:outline-none"
            type="text"
            placeholder="Link in bio or swipe up url(story)"
            name="url"
            value={url}
            required
            onChange={(e) => setUrl(e.target.value)}
          />

          <h1 className="font-bold my-[15px]">Drop Your Images </h1>

          {/* cover upload  */}
          <div className="bg-white px-5 py-8 rounded-lg border border-gray-300">
            {!loading1 ? (
              <label
                className="flex flex-col items-center justify-center space-y-2 cursor-pointer"
                htmlFor="pt1"
              >
                <img
                  className="w-32 h-24 object-cover rounded-lg"
                  src={cover || "/Images/cover.png"}
                  alt="Cover Image"
                />
                {selectedImg1 && cover && <h1>Uploaded</h1>}
              </label>
            ) : (
              <div className="flex items-center justify-center">
                <SpinnerCircular
                  size="90"
                  thickness="100"
                  speed="600"
                  color="blue"
                  secondaryColor="gray"
                />
              </div>
            )}
            <div className="flex items-center justify-center border-2 border-dashed border-gray-400 rounded-md py-6 mt-4 ">
              <input
                id="coverImage"
                accept="image/png, image/jpeg, image/jpg"
                type="file"
                hidden
                onChange={handleFileInputChange1}
              />
              <label
                htmlFor="coverImage"
                className="text-sm text-gray-500 cursor-pointer"
              >
                <UploadFile className="w-6 h-6 mr-2" /> Upload
              </label>
            </div>
          </div>

          {/* <h6 className="text-center text-blue-400 mt-[0.5vw]">1920*1080</h6> */}
          <div className="flex justify-between w-full mt-[1vw] mobile:flex-col xsm:flex-col xsm:space-y-[25px]">
            {/* second upload  */}
            <div className="shadow-1xl">
              <div className="bg-white px-5 py-[30px] border-2 border-dashed border-gray-400 rounded-md">
                {!loading2 ? (
                  <label className="flex items-center flex-col" htmlFor="pt2">
                    <img
                      className="w-32 h-24 cursor-pointer"
                      src={sample ? sample : "/Images/upl.png"}
                      alt="drop"
                    />
                    {selectedImg2 && sample && <h1>Uploaded</h1>}
                  </label>
                ) : (
                  <SpinnerCircular
                    size="90"
                    className="w-full flex items-center mx-auto"
                    thickness="100"
                    speed="600"
                    color="white"
                    secondaryColor="black"
                  />
                )}

                <input
                  id="pt2"
                  accept="image/png, image/jpeg, image/jpg"
                  type="file"
                  hidden
                  onChange={handleFileInputChange2}
                />
              </div>

              <h6 className="text-center text-blue-400 mt-[1vw]">Image</h6>
            </div>

            {/* third  */}
            <div className="shadow-1xl">
              <div className="bg-white px-5 py-[30px] border-2 border-dashed border-gray-400 rounded-md">
                {!loading3 ? (
                  <label className="flex items-center flex-col" htmlFor="pt3">
                    <img
                      className="w-32 h-24 cursor-pointer"
                      src={sample1 ? sample1 : "/Images/upl.png"}
                      alt="drop"
                    />

                    {selectedImg3 && sample1 && <h1>Uploaded</h1>}
                  </label>
                ) : (
                  <SpinnerCircular
                    size="90"
                    className="w-full flex items-center mx-auto"
                    thickness="100"
                    speed="600"
                    color="white"
                    secondaryColor="black"
                  />
                )}

                <input
                  id="pt3"
                  accept="image/png, image/jpeg, image/jpg"
                  type="file"
                  hidden
                  onChange={handleFileInputChange3}
                />
              </div>
              <h6 className="text-center text-blue-400 mt-[1vw] ">Image</h6>
            </div>
            {/* fourth  */}
            <div className="shadow-1yxl">
              <div className="bg-white px-5 py-[30px] border-2 border-dashed border-gray-400 rounded-md">
                {!loading4 ? (
                  <label className="flex items-center flex-col" htmlFor="pt4">
                    {sample2 ? (
                      <video
                        className="w-32 h-24"
                        controls
                        src={sample2}
                        alt="Video preview"
                      />
                    ) : (
                      <img
                        className="w-32 h-24 cursor-pointer"
                        src="/Images/upl.png"
                        alt="drop"
                      />
                    )}
                    {selectedImg4 && sample2 && <h1>Uploaded</h1>}
                  </label>
                ) : (
                  <div className="w-full flex flex-col items-center mx-auto">
                    <SpinnerCircular
                      size="90"
                      thickness="100"
                      speed="600"
                      color="white"
                      secondaryColor="black"
                    />
                    {/* <div className="mt-2 text-center">{uploadProgress}%</div> */}
                  </div>
                )}
                <input
                  id="pt4"
                  accept="video/*"
                  type="file"
                  hidden
                  onChange={handleFileInputChange4}
                />
              </div>
              <h6 className="text-center text-blue-400 mt-[1vw] ">
                Only video allowed
              </h6>
            </div>
          </div>

          <input
            className="text-center cursor-pointer py-2 text-white w-full my-[10px] rounded-lg bg-blue-400"
            type="submit"
          />
        </form>
        <Modal
          showModal={showModal}
          setShowModal={setShowModal}
          campId={campId}
          url={`https://app.cloutin.co/affiliatehome/${campId}`}
        />
      </div>
    </div>
  );
}

export default Formaffiliate;
