import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllBusiness, getAllAffiliatesStatusCounts } from "./Api";
import ButtonCampaign from "../ButtonCampaign";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import axios from "axios";
import { FaEdit } from "react-icons/fa";
import { config } from "../../../config";

const AffiliateCampaigns = () => {
  const [dataBusiness, setDataBusiness] = useState([]);
  const [affiliateStatusCounts, setAffiliateStatusCounts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(7);

  const getStatusCounts = (id) => {
    let index = affiliateStatusCounts.findIndex((item) => item.id === id);
    return {
      approved: affiliateStatusCounts[index]?.approved || 0,
      pending: affiliateStatusCounts[index]?.pending || 0,
      disapproved: affiliateStatusCounts[index]?.disapproved || 0,
    };
  };

  useEffect(() => {
    getAllBusiness(setDataBusiness);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch affiliate status counts for each business
        if (Array.isArray(dataBusiness) && dataBusiness.length > 0) {
          const promises = dataBusiness
            .filter((business) => business.companyId !== null)
            .map((business) =>
              getAllAffiliatesStatusCounts(business.companyId._id)
            );
          const results = await Promise.all(promises);
          setAffiliateStatusCounts(results);
        } else {
          console.error(
            "Business data is either not an array or empty:",
            dataBusiness
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (dataBusiness.length > 0) {
      fetchData();
    }
  }, [dataBusiness]);

  const navigate = useNavigate();

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const filteredData = dataBusiness.filter((business) =>
    business?.companyId?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const [thresholdPrice, setThresholdPrice] = useState(0);

  useEffect(() => {
    const fetchThresholdPrice = async () => {
      try {
        const response = await axios.get(
          // "https://api.cloutin.co/getAffiliateThresholdPrice"
          `${config.REACT_APP_BASE_URL}getAffiliateThresholdPrice`
        );
        const price = response.data;
        setThresholdPrice(price);
      } catch (error) {
        console.error("Error fetching tax data:", error);
      }
    };

    fetchThresholdPrice();
  }, []);

  const [modalOpen, setModalOpen] = useState(false);

  const handleEditClick = () => {
    setNewRate(thresholdPrice);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const [newRate, setNewRate] = useState(0);

  const handleSaveRate = async () => {
    try {
      const response = await axios.put(
        `${config.REACT_APP_BASE_URL}updateAffiliateThresholdPrice`,
        {
          price: newRate,
        }
      );

      if (response.status === 200) {
        setThresholdPrice(newRate);
        setModalOpen(false);
      } else {
        console.error("Failed to update rate");
      }
    } catch (error) {
      console.error("Error updating rate:", error);
    }
  };

  const handlePageChange = (event, value) => setCurrentPage(value);

  return (
    <div className="p-4 md:p-2 space-y-4 md:space-y-10">
      <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row">
        {/* graph */}
        {/* section */}
        <div className="xl:w-[484px] space-y-[25px] w-full lg:w-[350px] flex flex-col mb-[20px] lg:mb-0 px-[10px] pb-[20px] lg:pb-0 8k:w-[60vh] 4k:w-[40vw]  3xl:w-[40%]  3xl:h-[25vw]  4k:space-y-[1vw] 4k:ml-[1vw] rk:w-[100%]">
          {/* section-1 */}
          <div className="flex flex-col lg:flex-row space-y-[20px] lg:space-y-0 4k:w-[26vw] 4k:mt-[2vw]  8k:ml-[2vh] 8k:mt-[2vh] 8k:h-[12vh]   3xl:text-[1.3vw] 3xl:h-[8vw]  lgk:text-[1vw] lgk:w-[40vw]">
            {/* 1card  */}
            {/* 2 card  */}
          </div>
        </div>
      </div>
      <h1 className="text-3xl text-center text-gray-600">
        Business Affiliate Campaigns
      </h1>

      <div className="flex items-center gap-2 justify-end">
        <p className="font-medium bg-[#dfe5a2] rounded-full px-2 py-1">
          Threshold Price Value:{" "}
          <span className="font-semibold text-red-500">₹{thresholdPrice}</span>
        </p>
        <button
          className="text-white bg-blue-500 rounded-full px-3 py-2 ml-2"
          onClick={() => handleEditClick()}
        >
          <FaEdit />
        </button>
      </div>

      <div className="bg-white shadow-md py-[10px] w-[95%] p-3 overflow-x-scroll md:w-full border-2 border-e-gray-200 rounded-md">
        <div className="flex items-center mb-4">
          <input
            type="text"
            placeholder="Search by business name..."
            className="border border-gray-300 rounded-md p-2 mb-4"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        <table className="table-auto md:gap-2 text-center lg:w-[95%] sm:w-[95%]">
          <thead>
            <tr className="bg-blue-400 rounded-sm text-white">
              <th className="py-2 text-xs pr-[20px] md:text-[14px] font-semibold pl-8">
                Business name
              </th>
              <th className="text-xs pr-[20px] md:text-[14px] pl-2">
                {" "}
                Status Active
              </th>
              <th className="text-xs pr-[20px] md:text-[14px] pl-2">
                {" "}
                Status Pending
              </th>
              <th className="text-xs pr-[20px] md:text-[14px] pl-2">
                {" "}
                Status Disapproved
              </th>
            </tr>
          </thead>
          <tbody className="my-[20px] mx-[20px] space-y-[20px] font-medium">
            {currentItems.map((business) =>
              business.companyId == undefined || business.companyId == null ? (
                <></>
              ) : (
                <tr key={business.companyId._id}>
                  <td
                    className="text-[13px] pl-[20px] pt-[20px] md:text-lg cursor-pointer"
                    onClick={() => {
                      navigate(
                        `/admin/affiliateCampaignDetails/page2/${business.companyId._id}`
                      );
                    }}
                  >
                    <div className="flex font-semibold text-[14px] justify-center items-center text-xs text-[#707070]">
                      <h1>{business.companyId.name}</h1>
                    </div>
                  </td>
                  <td className={`text-md pt-[20px] font-bold text-green-500`}>
                    Active
                    <p className=" inline text-green-900">
                      [{getStatusCounts(business.companyId._id).approved}]
                    </p>
                  </td>
                  <td className={`text-md pt-[20px] font-bold text-yellow-500`}>
                    Pending
                    <p className=" inline text-yellow-800">
                      [{getStatusCounts(business.companyId._id).pending}]
                    </p>
                  </td>
                  <td className={`text-md pt-[20px] font-bold text-[#FF0000]`}>
                    Disapproved
                    <p className=" inline text-red-700">
                      [{getStatusCounts(business.companyId._id).disapproved}]
                    </p>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center">
        <Stack spacing={2}>
          <Pagination
            count={Math.ceil(filteredData.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
          />
        </Stack>
      </div>

      {modalOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-4 rounded shadow-md">
            <p className="text-lg font-bold mb-2">
              Enter new Threshold Price Value
            </p>
            <input
              type="number"
              className="border border-gray-300 rounded p-2 mb-2"
              value={newRate}
              onChange={(e) => setNewRate(e.target.value)}
            />
            <div className="flex justify-end">
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded mr-2"
                onClick={handleSaveRate}
              >
                Done
              </button>
              <button
                className="px-4 py-2 bg-gray-500 text-white rounded"
                onClick={handleModalClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AffiliateCampaigns;
