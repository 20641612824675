import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import ApiInterceptor from "../utils/ApiInterceptor";
import toast from "react-hot-toast";
import { useDispatch } from 'react-redux';
import { logout } from "../redux/Slice/userSlice";
import { clearData, clearGraphData } from "../redux/Slice/dashboardSlice";


function EmailVerify() {
 
  const { id } = useParams();
  const navigate = useNavigate();
  const current1 = Cookies.get("userid");


  useEffect(()=>{
    if(!current1){
      navigate("/login");
    }
  },[])


  const [email, setEmail] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [emailVerificationStatus, setEmailVerificationStatus] = useState("Verify Email");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(60);


  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };


  const handleVerifyEmail = async (e) => {
    e.preventDefault();

    console.log("Hello");

    if (!email || !validateEmail(email)) {
      return toast.error("Please enter a valid email");
    }

    if (emailVerificationStatus === "Verify Email") {
      setEmailVerificationStatus("OTP Sending");

      const response = await ApiInterceptor.post("verify/user/mail", { email ,id});
      if (response.status === 200) {
        if (response.data.message === "Mail Sent") {
          setEmailVerificationStatus("OTP Sent");
          setIsOtpSent(true);
          setIsButtonDisabled(true);
          setTimer(60); // Start the timer
          toast.success(
            "Mail sent successfully. Please check your email for the OTP."
          );
        } else {
          setEmailVerificationStatus("Verify Email");
          toast(response.data.message);
        }
      } else {
        console.error("Failed to send OTP");
      }
    }
  };


  const handleOtpChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Ensure only digits are entered
    if (value.length <= 4) {
      setOtp(value);
    }
  };

  const handleSubmitOtp = async (e) => {
    e.preventDefault();
    try {
      // Simulate OTP verification
      const response = await ApiInterceptor.post("user/verify-otp", {
        email,
        otp,
      });

      // Log the response and OTP
      console.log("OTP Submitted:", otp);

      // Update UI based on response
      if (response.status === 200) {
        setEmailVerificationStatus("Verified"); // Update verification status
        setIsOtpSent(false); // Reset the state indicating OTP was sent
        setOtp(""); // Clear the OTP input field
        setIsButtonDisabled(true); // Disable the submit button after verification

        // Optionally, show a success message to the user
        toast.success("Email successfully verified!");
      navigate("/maindashboard");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);

      // Check if the error response has a message
      if (error.response && error.response.data) {
        // Use the message from the error response if available
        toast.error(error.response.data.message);
      } else {
        // Fallback for any other errors
        toast.error(
          "An error occurred while verifying the OTP. Please try again."
        );
      }
    }
  };
  
  useEffect(() => {
    if (isOtpSent && timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else if (timer === 0) {
      setEmailVerificationStatus("Verify Email");
    }
  }, [isOtpSent, timer]);


  const dispatch = useDispatch();

  const handleLogOut = ()=>{
      dispatch(logout());
      dispatch(clearGraphData());
      dispatch(clearData());
      Cookies.remove("email");
      Cookies.remove("username");
      Cookies.remove("image");
      Cookies.remove("token");
      localStorage.clear();
      navigate("/login");
      toast.success("Logout successfully ");
    
  }

  return (
    <div className="flex flex-col md:flex-row h-screen">
       <div className="hidden md:flex md:w-1/2 lg:w-1/3 bg-gradient-to-b from-indigo-800 to-purple-700 text-white p-8 flex-col justify-center items-center md:items-start">
        <div className="mb-8 text-center md:text-left">
          {/* <h1 className="text-4xl md:text-5xl font-bold mb-2">Cloutin</h1>*/}
          <img
            src="../Images/logo/cloutin_light.png"
            alt="Cloutin Logo"
            className="h-24 w-full object-cover"
          />
          <p className="text-gray-300">
            Marketplace, Influencer, and Campaigns
          </p>
        </div>
        <div className="max-w-md">
          <h2 className="text-2xl md:text-3xl font-bold mb-4">Features</h2>
          <ul className="list-disc pl-4 text-lg">
            <li className="mb-2">Seamless marketplace experience</li>
            <li className="mb-2">Connect with top influencers</li>
            <li className="mb-2">Powerful campaign management tools</li>
          </ul>
        </div>
      </div>

      <div className="flex-1 bg-white p-8 flex flex-col justify-center">
      <div className="max-w-md w-full mx-auto">
         
      <h2 className="text-3xl md:text-4xl font-bold mb-6 text-center">
            Verify Email
          </h2>

          <div className="flex flex-col">
              <label
                htmlFor="companyEmail"
                className="block mb-2 font-bold text-gray-700"
              >
                 Email:
              </label>
              <div className="flex">
                <input
                  type="email"
                  id="companyEmail"
                  className="border border-gray-300 rounded-l-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  placeholder="Enter your company email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={isOtpSent || emailVerificationStatus === "Verified"}
                />
                <button
                  onClick={handleVerifyEmail}
                  className={`bg-indigo-500 text-white px-4 py-2 rounded-r-md hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 ${
                    isButtonDisabled ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  disabled={isButtonDisabled}
                >
                  {emailVerificationStatus === "Verified"
                    ? "Verified"
                    : emailVerificationStatus === "OTP Sending"
                    ? "Sending Mail"
                    : "Verify"}
                </button>
              </div>

              {isOtpSent && (
                <div className="flex flex-col mt-4">
                  <label
                    htmlFor="otp"
                    className="block mb-2 font-bold text-gray-700"
                  >
                    Enter OTP
                  </label>
                  <div className="flex">
                    <input
                      type="text"
                      id="otp"
                      className="border border-gray-300 rounded-l-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      placeholder="Enter 4-digit OTP"
                      value={otp}
                      onChange={handleOtpChange}
                      maxLength={4}
                      disabled={timer === 0}
                    />
                    <button
                      onClick={
                        otp.length === 4 ? handleSubmitOtp : handleVerifyEmail
                      }
                      className={`bg-indigo-500 text-white px-4 py-2 rounded-r-md hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 ${
                        otp.length !== 4 && timer > 0
                          ? "opacity-50 cursor-not-allowed"
                          : ""
                      }`}
                      disabled={otp.length !== 4 && timer > 0}
                    >
                      {otp.length === 4 && timer > 0
                        ? "Submit"
                        : timer > 0
                        ? `Resend OTP in ${timer}s`
                        : "Resend OTP"}
                    </button>
                  </div>
                </div>
              )}
            </div>
     
        </div>

  
      <p className="text-black text-center text-md font-semibold hover:text-indigo-700 transition duration-300 ease-in-out mt-4 mb-4  p-4 rounded-lg cursor-pointer" onClick={handleLogOut}>

      Want to sign in with a different account?
    </p>
  
        </div>
        
      </div>
  );
}

export default EmailVerify;
