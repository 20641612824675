import React, { useState, useEffect, useCallback } from "react";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import { SocialIcon } from "react-social-icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserAccountStatus,
  setUserAccountStatus,
} from "../../redux/Slice/UserAccounStatusSlice";
import OverlayDisableAccountPage from "../../Pages/OverlayDisableAccountPage";
import Loader from "../../utils/Loader";
import { FaPlus } from "react-icons/fa";
import WarningMessage from "../../utils/WarningModel";
import ErrorPage from "../../utils/ErrorPage";
import ApiInterceptor from "../../utils/ApiInterceptor";

function Affiliate() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [allAffiliates, setAllAffiliates] = useState([]);
  const [affiliateLoading, setAffiliateLoading] = useState(false);
  const [openDisablePageModal, setOpenDisablePageModal] = useState(false);
  const { userAccountStatus } = useSelector((state) => state.userAccountStatus);
  const companyId = Cookies.get("userid");
  const [searchInput, setSearchInput] = useState("");
  const [filteredAffiliates, setFilteredAffiliates] = useState([]);
  const [errors, setErrors] = useState(false);

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "long", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  const getStatusColor = useCallback((status) => {
    const colors = {
      pending: "#FFC300",
      approved: "#00B528",
      rejected: "#FF0000",
      deleted: "#FF0000",
    };
    return colors[status] || "#000000";
  }, []);

  useEffect(() => {
    if (companyId) {
      dispatch(getUserAccountStatus({ companyId })).then((response) => {
        dispatch(setUserAccountStatus(response?.payload));
      });
    }
  }, [dispatch, companyId]);

  useEffect(() => {
    const fetchAffiliates = async () => {
      try {
        setAffiliateLoading(true);
        // const response = await axios.get(
        //   `https://api.cloutin.co/api/affiliate/${companyId}`
        // );
        const response = await ApiInterceptor.get(`affiliate/${companyId}`);
        setAllAffiliates(Array.isArray(response.data) ? response.data : []);
        setFilteredAffiliates(response.data);
      } catch (error) {
        setErrors(true);
        toast.error("Error fetching affiliates", {
          duration: 1500,
          position: "top-center",
        });
      } finally {
        setAffiliateLoading(false);
      }
    };
    fetchAffiliates();
  }, [companyId]);

  useEffect(() => {
    if (userAccountStatus) {
      setOpenDisablePageModal(true);
    }
  }, [userAccountStatus]);

  const searchAffiliateByName = (e) => {
    const inputValue = e.target.value.toLowerCase();
    setSearchInput(inputValue);
    const filteredData = allAffiliates.filter((affiliate) =>
      affiliate.affiliate_campaign_name.toLowerCase().includes(inputValue)
    );
    setFilteredAffiliates(filteredData);
  };

  const [BusinessWalletBalance, setBusinessWalletBalance] = useState(0);
  const [warningModel2, setWarningModel2] = useState(false);

  useEffect(() => {
    const fetchWallet = async () => {
      const companyId = Cookies.get("userid");
      try {
        // const walletResponse = await axios.get(
        //   `https://api.cloutin.co/api/businesswallet/getBusinessWalletByBusinessId/${companyId}`,
        //   {
        //     headers: {
        //       "Content-Type": "application/json",
        //       Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        //     },
        //   }
        // );
        const walletResponse = await ApiInterceptor.get(
          `businesswallet/getBusinessWalletByBusinessId/${companyId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
          }
        );
        if (walletResponse?.data?.currentBalance < 2000) setWarningModel2(true);

        setBusinessWalletBalance(walletResponse?.data?.currentBalance);
      } catch (error) {
      } finally {
        setErrors(false);
      }
    };
    fetchWallet();
  }, []);

  if (errors) return <ErrorPage />;
  if (userAccountStatus) return <OverlayDisableAccountPage isOpen={true} />;

  return (
    <div className="p-4 w-full max-w-[1500px] m-auto">
      {errors && <ErrorPage />}
      {warningModel2 && allAffiliates.length > 0 && (
        <WarningMessage
          message={
            "Your All Affiliate Campaigns are Paused... to continue, your Wallet Balance must be atleast ₹2000"
          }
        />
      )}

      <div className="flex flex-col space-y-4">
        <h1 className="text-2xl font-bold">List of all Affiliate Campaigns</h1>
        <input
          type="text"
          placeholder="Search by campaign name..."
          value={searchInput}
          onChange={searchAffiliateByName}
          className="p-2 border border-gray-300 rounded-lg mb-4"
        />
        {affiliateLoading && <Loader loadingText={"Affiliates"} />}

        {filteredAffiliates.length === 0 && !affiliateLoading ? (
          <div className="flex flex-col items-center justify-center h-[60vh]">
            <p className="text-xl text-gray-500 mb-4">
              No Affiliate Campaigns Found
            </p>
            <CreateAffiliateButton
              BusinessWalletBalance={BusinessWalletBalance}
              setWarningModel2={setWarningModel2}
            />
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {filteredAffiliates.map((affiliate) => (
              <AffiliateCard
                key={affiliate.affiliate_id}
                affiliate={affiliate}
                getStatusColor={getStatusColor}
                formatDate={formatDate}
                navigate={navigate}
              />
            ))}
          </div>
        )}

        {filteredAffiliates.length > 0 && (
          <div className="fixed bottom-6 right-6">
            <CreateAffiliateButton
              BusinessWalletBalance={BusinessWalletBalance}
              setWarningModel2={setWarningModel2}
            />
          </div>
        )}
      </div>
    </div>
  );
}

const AffiliateCard = ({ affiliate, getStatusColor, formatDate, navigate }) => (
  <div
    onClick={() => navigate(`/affiliatehome/${affiliate?.affiliate_id}`)}
    className="cursor-pointer flex flex-col shadow-md hover:shadow-xl p-5 border border-gray-200 rounded-xl bg-white transition-all duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-102"
  >
    <div className="w-full h-56 mb-4 overflow-hidden rounded-lg">
      {affiliate?.cover_image ? (
        <img
          className="w-full h-full object-cover transition-transform duration-300 hover:scale-110"
          src={affiliate?.cover_image}
          alt="Affiliate cover"
        />
      ) : (
        <div className="w-full h-full bg-gradient-to-br from-gray-100 to-gray-200 flex items-center justify-center rounded-lg">
          <p className="text-gray-500 text-center font-medium">
            No image available
          </p>
        </div>
      )}
    </div>
    <div className="flex flex-col flex-grow">
      <div className="flex justify-between items-center mb-3">
        <div className="flex items-center">
          <div
            className="h-3 w-3 rounded-full mr-2"
            style={{
              backgroundColor: getStatusColor(affiliate?.affiliate_status),
            }}
          ></div>
          <h2
            className="font-semibold text-sm uppercase tracking-wide"
            style={{ color: getStatusColor(affiliate.affiliate_status) }}
          >
            {affiliate?.affiliate_status}
          </h2>
        </div>
        <SocialIcon
          style={{ width: "28px", height: "28px" }}
          network={
            affiliate?.platform_allows?.toLowerCase() === "instagram"
              ? "instagram"
              : "youtube"
          }
        />
      </div>
      <h1 className="font-bold text-xl mb-2 line-clamp-2 hover:text-blue-600 transition-colors duration-200">
        {affiliate?.affiliate_campaign_name}
      </h1>
      <div className="text-gray-600 text-sm mb-3 flex items-center">
        <svg
          className="w-4 h-4 mr-1"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
          />
        </svg>
        {formatDate(affiliate?.start_date)} &mdash;{" "}
        {formatDate(affiliate?.end_date)}
      </div>
      <div className="flex justify-between items-center mt-auto pt-3 border-t border-gray-100">
        <div className="flex items-center">
          <svg
            className="w-4 h-4 mr-1 text-green-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <h2 className="text-sm font-medium">
            Commission:{" "}
            <span className="font-bold text-green-600">
              {affiliate?.commission}%
            </span>
          </h2>
        </div>
        <div className="flex items-center">
          <svg
            className="w-4 h-4 mr-1 text-blue-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
            />
          </svg>
          <h2 className="text-sm font-medium">
            Applied:{" "}
            <span className="font-bold text-blue-600">
              {affiliate?.influencers_applied?.length}
            </span>
          </h2>
        </div>
      </div>
    </div>
  </div>
);

const CreateAffiliateButton = ({ BusinessWalletBalance, setWarningModel2 }) => {
  const navigate = useNavigate();
  const [warningModel, setWarningModel] = useState(false);

  const handleClick = () => {
    if (BusinessWalletBalance < 5000) {
      setWarningModel(true);
      setWarningModel2(false);
    } else {
      navigate("/affiliateform/12345");
    }
  };

  return (
    <>
      {warningModel ? (
        <WarningMessage
          message={
            "For creating affiliate campaign your Wallet Balance should be at least ₹5000"
          }
        />
      ) : (
        <button
          onClick={handleClick}
          className="flex items-center justify-center px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors duration-300"
        >
          <span className="mr-2">Create Affiliate Campaign</span>
          <FaPlus />
        </button>
      )}
    </>
  );
};

export default Affiliate;
