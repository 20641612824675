
import ApiInterceptor from "../../../utils/ApiInterceptor";
const getAllBusiness = (setDataBusiness) => {
  ApiInterceptor.get("Business/")
    .then(({ data }) => {
      setDataBusiness(data["company"]);
    })
    .catch((error) => {
      console.error("Error fetching business data:", error);
    });
};

// Fetch all influencers by business ID
const getAllInfluencer = (setInfluencer, setEachInfluencerCurrAnalytic, id) => {
  ApiInterceptor.get(`influencer/businessId/${id}`)
    .then(({ data }) => {
      setInfluencer(data?.response);
      setEachInfluencerCurrAnalytic(data?.influencerStats);
    })
    .catch((error) => {
      console.error("Error fetching influencer data:", error);
    });
};

export { getAllBusiness, getAllInfluencer };
