import React from "react";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function Wallet() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <CheckCircleIcon sx={{ fontSize: 100, color: "green" }} />
      <h1 className="text-3xl font-semibold text-green-700 mb-4">
        Payment Successful
      </h1>
      <p className="text-lg text-gray-600 max-w-md text-center">
        Thank you adding payment. Your transaction has been successfully
        processed.
      </p>
      <button
        onClick={() => {
          window.location.href = "/";
        }}
        className="mt-8 bg-blue-500 text-white px-6 py-3 rounded-lg hover:bg-blue-600 transition-colors duration-300"
      >
        Continue to Website
      </button>
    </div>
  );
}

export default Wallet;
