import axios from "axios";
import { config } from "../config";

const ApiInterceptor = axios.create({
  baseURL: config.REACT_APP_BACKEND_SERVICE_URL,
});

ApiInterceptor.interceptors.request.use((req) => {
  return req;
});

export default ApiInterceptor;
