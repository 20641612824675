import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { Dialog, Transition } from '@headlessui/react';
import ApiInterceptor from '../../utils/ApiInterceptor';

function AdminKycVerification() {
  const [kycDetails, setKycDetails] = useState([]);
  const [selectedKyc, setSelectedKyc] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDocModalOpen, setIsDocModalOpen] = useState(false);

  useEffect(() => {
    fetchKycDetails();
  }, []);

  const fetchKycDetails = async () => {
    try {
      const response = await ApiInterceptor.get('kyc/all'); // Replace with your actual API endpoint
      setKycDetails(response.data);
    } catch (error) {
      console.error('Error fetching KYC details:', error);
    }
  };

  const handleVerify = async (kyc) => {
    try {
      await ApiInterceptor.put(`kyc/update`, null, {
        params: {
          influencer_id: kyc.influencer_id,
          status: 1, // Set status to 1 for approval
        },
      });

      setKycDetails(kycDetails.map(item => item._id === kyc._id ? { ...item, status: 1 } : item));
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error verifying KYC:', error);
    }
  };

  const handleDisapprove = async (kyc) => {
    try {
      await ApiInterceptor.put(`kyc/update`, null, {
        params: {
          influencer_id: kyc.influencer_id,
          status: 2, // Set status to 2 for disapproval
        },
      });

      setKycDetails(kycDetails.map(item => item._id === kyc._id ? { ...item, status: 2 } : item));
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error disapproving KYC:', error);
    }
  };

  const renderKycCard = (kyc) => (
    <div
      key={kyc._id}
      className="bg-gradient-to-r from-black to-blue-800 text-white shadow-lg rounded-xl p-6 m-4 cursor-pointer hover:shadow-2xl transition transform hover:-translate-y-1"
      onClick={() => { setSelectedKyc(kyc); setIsModalOpen(true); }}
    >
      <h3 className="text-xl font-semibold mb-2">{kyc.name}</h3>
      <p className="text-sm"><strong>IFSC Code:</strong> {kyc.ifscCode}</p>
      <p className="text-sm mt-1"><strong>Account Number:</strong> {kyc.account_number}</p>
      <p className="text-sm mt-1"><strong>Status:</strong> {kyc.status === 0 ? 'Pending' : kyc.status === 1 ? 'Approved' : 'Disapproved'}</p>
    </div>
  );

  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      <h1 className="text-4xl font-extrabold text-gray-800 mb-8 text-center">KYC Verification</h1>

      <div>
        <h2 className="text-2xl font-bold text-gray-700 mb-4">Pending</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {kycDetails.filter(kyc => kyc.status === 0).map(renderKycCard)}
        </div>
      </div>

      <div className="mt-12">
        <h2 className="text-2xl font-bold text-gray-700 mb-4">Approved</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {kycDetails.filter(kyc => kyc.status === 1).map(renderKycCard)}
        </div>
      </div>

      <div className="mt-12">
        <h2 className="text-2xl font-bold text-gray-700 mb-4">Disapproved</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {kycDetails.filter(kyc => kyc.status === 2).map(renderKycCard)}
        </div>
      </div>

      {/* KYC Details Modal */}
      {selectedKyc && (
        <Transition.Root show={isModalOpen} as={Fragment}>
          <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setIsModalOpen}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-50 transition-opacity" />
              </Transition.Child>

              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-6 py-8 shadow-2xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                  <Dialog.Title as="h3" className="text-2xl leading-6 font-bold text-gray-900">
                    KYC Details
                  </Dialog.Title>
                  <div className="mt-4 flex flex-col justify-center items-center">
                    <p className="text-gray-700"><strong>Name:</strong> {selectedKyc.name}</p>
                    <p className="text-gray-700 mt-2"><strong>IFSC Code:</strong> {selectedKyc.ifscCode}</p>
                    <p className="text-gray-700 mt-2"><strong>Account Number:</strong> {selectedKyc.account_number}</p>
                    <p className="text-gray-700 mt-2"><strong>Address:</strong> {selectedKyc.address}</p>
                    <p className="text-gray-700 mt-2"><strong>Phone Number:</strong> {selectedKyc.phone_number}</p>
                    <button
                      onClick={() => setIsDocModalOpen(true)}
                      className="text-white font-bold bg-blue-400 rounded-md shadow-lg mt-4 block  transition p-2"
                    >
                      View Document
                    </button>
                  </div>

                  <div className="mt-6 flex justify-end gap-4">
                    {selectedKyc.status === 0 && (
                      <>
                        <button
                          onClick={() => handleVerify(selectedKyc)}
                          className="bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700 transition"
                        >
                          Verify
                        </button>
                        <button
                          onClick={() => handleDisapprove(selectedKyc)}
                          className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700 transition"
                        >
                          Disapprove
                        </button>
                      </>
                    )}
                    {selectedKyc.status === 1 && (
                      <button
                        onClick={() => handleDisapprove(selectedKyc)}
                        className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700 transition"
                      >
                        Disapprove
                      </button>
                    )}
                    {selectedKyc.status === 2 && (
                       <button
                       onClick={() => handleVerify(selectedKyc)}
                       className="bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700 transition"
                     >
                       Verify
                     </button>
                    )}
                    <button
                      onClick={() => setIsModalOpen(false)}
                      className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600 transition"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      )}

      {/* Document Viewing Modal */}
      {selectedKyc && (
        <Transition.Root show={isDocModalOpen} as={Fragment}>
          <Dialog as="div" className="fixed z-20 inset-0 overflow-y-auto" onClose={setIsDocModalOpen}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-50 transition-opacity" />
              </Transition.Child>

              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg max-w-4xl mx-auto p-6 shadow-2xl transform transition-all sm:my-8 sm:align-middle sm:w-full">
                  <Dialog.Title as="h3" className="text-2xl leading-6 font-bold text-gray-900">
                    Document Viewer
                  </Dialog.Title>
                  <iframe
                    src={selectedKyc.doc_url}
                    className="w-full h-96 mt-4 rounded-lg border border-gray-300"
                    title="Document Viewer"
                  ></iframe>
                  <div className="mt-4 flex justify-end">
                    <button
                      onClick={() => setIsDocModalOpen(false)}
                      className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700 transition"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </div>
  );
}

export default AdminKycVerification;
