import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import ApiInterceptor from "../../../utils/ApiInterceptor";

const CampaignAdmin3 = () => {
  const navigate = useNavigate();
  const campaignId = window.location.pathname.split("/").pop();
  const [campaignData, setCampaignData] = useState({});
  const [appliedInfluencers, setAppliedInfluencers] = useState([]);
  const [acceptedInfluencers, setAcceptedInfluencers] = useState([]);

  const fetchCampaignData = async (campaignId) => {
    try {
      const response = await ApiInterceptor.get(
        `collabb/admin/campaign/particularcamp/${campaignId}`
      );
      if (response.status === 200) {
        const data = response?.data;
        setCampaignData(data?.campaign);
        setAppliedInfluencers(data?.appliedInfluencers || []);
        setAcceptedInfluencers(data?.acceptedInfluencers || []);
      } else {
        console.error("Failed to fetch campaign data");
      }
    } catch (error) {
      console.error("Error fetching campaign data:", error);
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    if (campaignId) {
      fetchCampaignData(campaignId);
    }
  }, [campaignId]);

  console.log(campaignData, "campaign data fetched from CampaignAdmin3.js");

  return (
    <div className="p-4 md:p-6 space-y-6">
      <div className="bg-white shadow-md rounded-lg p-6 flex flex-col md:flex-row">
        <div className="md:flex-shrink-0">
          <img
            className="w-full h-64 object-cover md:w-64 md:h-full rounded-lg"
            src={campaignData.cover_image_url}
            alt="tag"
          />
        </div>
        <div className="mt-6 md:mt-0 md:ml-6 flex flex-col justify-between">
          <div>
            <div className="flex items-center justify-between">
              <h1 className="font-extrabold text-black text-2xl">
                {`${campaignData.name} Campaign`}
              </h1>
              <img
                className="w-8 h-8"
                src={
                  campaignData.platform === "Youtube"
                    ? "/Images/youtube.png"
                    : campaignData.platform === "Instagram"
                    ? "/Images/insta.png"
                    : campaignData.platform === "Facebook"
                    ? "/Images/facebook.png"
                    : "/Images/default.png" // Default image for other platforms
                }
                alt="Platform Icon"
              />
            </div>
            <h2 className="text-lg text-gray-600 mt-2">
              {campaignData.category}
            </h2>
            <p className="text-gray-700 mt-4">
              {campaignData.description ||
                "No Description added by the campaign owner"}
            </p>
            <p className="text-gray-700 mt-4">
              {campaignData.instruction ||
                "No instructions added by the campaign owner"}
            </p>
            {campaignData?.campEndDate && (
              <div className="mt-4 flex items-center">
                <span className="font-bold text-gray-800">
                  Campaign End Date:
                </span>
                <span className="ml-2 text-green-500 font-semibold underline">
                  {/* {campaignData?.campEndDate} */}
                  {moment(campaignData?.campEndDate).format("lll")}
                </span>
              </div>
            )}
          </div>
          <div className="mt-6 flex justify-between items-center">
            <span className="font-extrabold text-lg text-black">
              {campaignData.cpm ? `CPM: ${campaignData.cpm}` : "CPM: N/A"}
            </span>
            {campaignData.url && (
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600"
                onClick={() => (window.location.href = campaignData.url)}
              >
                View Link
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Applied Influencers  */}
      <div className="bg-white shadow-md rounded-lg p-6 overflow-x-auto">
        <h2 className="text-lg font-bold mb-4 bg-blue-400 inline-block px-3 py-1 rounded-md text-white">
          Applied Influencers
        </h2>
        {appliedInfluencers.length === 0 ? (
          <div className="text-center text-gray-500 mt-4">
            No influencers have applied for this campaign yet
          </div>
        ) : (
          <table className="table-auto w-full text-center border-collapse">
            <thead className="bg-gray-200">
              <tr>
                <th className="border border-gray-300 px-4 py-2">INFLUENCER</th>
                <th className="border border-gray-300 px-4 py-2">Followers</th>
                <th className="border border-gray-300 px-4 py-2">Likes</th>
                <th className="border border-gray-300 px-4 py-2">Comments</th>
                <th className="border border-gray-300 px-4 py-2">Engagement</th>
                <th className="border border-gray-300 px-4 py-2">Status</th>
              </tr>
            </thead>
            <tbody>
              {appliedInfluencers.map((influencer) => (
                <tr key={influencer.influencerId}>
                  <td className="border border-gray-300 px-4 py-2">
                    {influencer.influencerName}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {influencer.instagram?.followers ||
                      influencer.youtube?.subscriberCount ||
                      "N/A"}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {influencer.instagram?.likes ||
                      influencer.youtube?.likes ||
                      "N/A"}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {influencer.instagram?.comments ||
                      influencer.youtube?.comments ||
                      "N/A"}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {influencer.youtube?.engagement || "N/A"}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {influencer.status}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {/* Accepted Influencers  */}
      <div className="bg-white shadow-md rounded-lg p-6 overflow-x-auto">
        <h2 className="text-lg font-bold mb-4 bg-blue-400 inline-block px-3 py-1 rounded-md text-white">
          Accepted Influencers
        </h2>
        {acceptedInfluencers.length === 0 ? (
          <div className="text-center text-gray-500 mt-4">
            No influencers have been accepted for this campaign yet
          </div>
        ) : (
          <table className="table-auto w-full text-center border-collapse">
            <thead className="bg-gray-200">
              <tr>
                <th className="border border-gray-300 px-4 py-2">INFLUENCER</th>
                <th className="border border-gray-300 px-4 py-2">Followers</th>
                <th className="border border-gray-300 px-4 py-2">Likes</th>
                <th className="border border-gray-300 px-4 py-2">Comments</th>
                <th className="border border-gray-300 px-4 py-2">Engagement</th>
                <th className="border border-gray-300 px-4 py-2">Status</th>
              </tr>
            </thead>
            <tbody>
              {acceptedInfluencers.map((influencer) => (
                <tr key={influencer.influencerId}>
                  <td className="border border-gray-300 px-4 py-2">
                    {influencer.influencerName}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {influencer.instagram?.followers ||
                      influencer.youtube?.subscriberCount ||
                      "N/A"}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {influencer.instagram?.likes ||
                      influencer.youtube?.likes ||
                      "N/A"}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {influencer.instagram?.comments ||
                      influencer.youtube?.comments ||
                      "N/A"}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {influencer.youtube?.engagement || "N/A"}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {influencer.status}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default CampaignAdmin3;
