import React from "react";
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Line,
  Tooltip,
  Legend,
  Label,
} from "recharts";

// Format data function to map and format dates
const formatData = (data) => {
  const dataMap = new Map();

  data.forEach((item) => {
    const date = new Date(item.date);
    const day = date.getDate(); // Extract only the day from the date
    const formattedDate = day.toString(); // Store only the day as a string
    if (!dataMap.has(formattedDate)) {
      dataMap.set(formattedDate, 0);
    }
    dataMap.set(formattedDate, dataMap.get(formattedDate) + item.total_spent);
  });

  const formattedData = Array.from(dataMap.entries()).map(
    ([date, total_spent]) => ({
      date, // Using day number as the 'date' field
      total_spent,
    })
  );

  return formattedData;
};

const LineChartReact = ({ data }) => {
  const formattedData = formatData(data);

  return (
    <div className="">
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={formattedData}>
          {/* Remove horizontal and vertical grid lines */}
          <CartesianGrid horizontal={false} vertical={false} />
          <XAxis
            dataKey="date"
            tickFormatter={(date) => date} // Return only the day value
          >
            <Label value="Day" offset={-3} position="insideBottom" />
          </XAxis>
          <YAxis>
            <Label
              value="Total Spent"
              angle={-90}
              position="insideLeft"
              style={{ textAnchor: "middle" }}
              offset={-0.2}
            />
          </YAxis>
          <Tooltip content={<CustomTooltip />} />
          <Legend verticalAlign="top" height={36} />
          <Line
            type="monotone"
            dataKey="total_spent"
            strokeWidth={4}
            dot={{ r: 2 }}
            activeDot={{ r: 6 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

// Custom tooltip to show detailed information
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const dataPoint = payload[0].payload;

    return (
      <div className="custom-tooltip bg-white p-2 border border-gray-300 rounded-lg shadow-md">
        <p className="text-gray-800">{`Day: ${dataPoint.date}`}</p>
        <p className="text-gray-800">{`Total Spent: ${dataPoint?.total_spent?.toFixed(2)}`}</p>
      </div>
    );
  }
  return null;
};

export default LineChartReact;
