import React, { useState, useEffect, useRef } from "react";
import SendIcon from "@mui/icons-material/Send";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import {
  query,
  collection,
  onSnapshot,
  addDoc,
  serverTimestamp,
  orderBy,
} from "firebase/firestore";
import { firestore } from "../firebase";

const ChatBox = ({ chatId, influencerId, companyId }) => {
  const [chats, setMessages] = useState([]);
  const [messageText, setMessageText] = useState("");
  const [showChat, setShowChat] = useState(false);
  const chatBoxRef = useRef(null);

  useEffect(() => {
    const q1 = query(
      collection(firestore, "user", chatId, "chats"),
      orderBy("time", "asc")
    );
    const q2 = query(
      collection(firestore, "user", "chats", chatId),
      orderBy("time", "asc")
    );

    const unsubscribe1 = onSnapshot(q1, (QuerySnapshot) => {
      const fetchedMessages1 = [];
      QuerySnapshot.forEach((doc) => {
        fetchedMessages1.push({ ...doc.data(), id: doc.id, source: "first" });
      });
      updateMessages(fetchedMessages1);
    });

    const unsubscribe2 = onSnapshot(q2, (QuerySnapshot) => {
      const fetchedMessages2 = [];
      QuerySnapshot.forEach((doc) => {
        fetchedMessages2.push({ ...doc.data(), id: doc.id, source: "second" });
      });
      updateMessages(fetchedMessages2);
    });

    return () => {
      unsubscribe1();
      unsubscribe2();
    };
  }, [chatId]);

  const updateMessages = (newMessages) => {
    setMessages((prevMessages) => {
      const messageMap = new Map();
      prevMessages.forEach((msg) => messageMap.set(msg.id, msg));
      newMessages.forEach((msg) => messageMap.set(msg.id, msg));

      return Array.from(messageMap.values()).sort((a, b) => a.time - b.time);
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (chatBoxRef.current && !chatBoxRef.current.contains(event.target)) {
        setShowChat(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [chatBoxRef]);

  const handleMessageSend = async (e) => {
    e.preventDefault();
    if (!messageText.trim()) return;

    try {
      await addDoc(collection(firestore, "user", "chats", chatId), {
        from: companyId,
        to: influencerId,
        message: messageText,
        likedMessageUser: [],
        time: serverTimestamp(),
      });

      setMessageText("");
      console.log("Message sent successfully!");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <div className="relative">
      <button
        onClick={() => setShowChat(!showChat)}
        className="bg-indigo-600 px-2 py-2 rounded-full flex items-center justify-center"
      >
        <QuestionAnswerIcon style={{ fontSize: "25px", color: "white" }} />
      </button>
      {showChat && (
        <div
          ref={chatBoxRef}
          className="border border-gray-300 fixed right-5 bottom-5 h-96 rounded-lg w-80 flex flex-col justify-between shadow-lg bg-gray-100 z-50"
        >
          <div className="overflow-y-scroll p-2 flex-grow space-y-2">
            {chats.map((i) => (
              <div
                key={i.id}
                className={`flex ${
                  companyId === i.from ? "justify-end" : "justify-start"
                }`}
              >
                <div
                  className={`${
                    i.source === "first"
                      ? "bg-indigo-300 text-gray-900"
                      : "bg-white text-black"
                  } p-2 rounded-lg shadow-md max-w-xs`}
                >
                  <p className="text-sm">{i.message}</p>
                  <p className="text-xs text-gray-600 mt-1">
                    {i.time ? new Date(i.time.seconds * 1000).toLocaleTimeString() : "Sending..."}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="flex items-center p-2 bg-white border-t">
            <input
              type="text"
              className="flex-grow p-2 border rounded-lg focus:outline-none"
              placeholder="Enter your message"
              value={messageText}
              onChange={(e) => setMessageText(e.target.value)}
            />
            <button
              onClick={handleMessageSend}
              className="ml-2 p-2 bg-indigo-600 rounded-full text-white"
            >
              <SendIcon />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatBox;



// import React, { useState, useEffect, useRef } from "react";
// import SendIcon from "@mui/icons-material/Send";
// import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
// import {
//   query,
//   collection,
//   onSnapshot,
//   addDoc,
//   serverTimestamp,
//   orderBy,
// } from "firebase/firestore";
// import { firestore } from "../firebase";

// const ChatBox = ({ chatId, influencerId, companyId }) => {
//   const [chats, setMessages] = useState([]);
//   const [messageText, setMessageText] = useState("");
//   const [showChat, setShowChat] = useState(false);
//   const chatBoxRef = useRef(null);

//   useEffect(() => {
//     async function fetchData() {
//       const q = query(
//         collection(firestore, "user", "chats", chatId),
//         orderBy("time", "asc")
//       );

//       const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
//         const fetchedMessages = [];
//         QuerySnapshot.forEach((doc) => {
//           fetchedMessages.push({ ...doc.data(), id: doc?.id });
//         });
//         const sortedMessages = fetchedMessages.sort(
//           (a, b) => b.createdAt - a.createdAt
//         );
//         setMessages(sortedMessages);
//       });

//       return () => unsubscribe;
//     }

//     fetchData();
//   }, []);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (chatBoxRef.current && !chatBoxRef.current.contains(event.target)) {
//         setShowChat(false);
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [chatBoxRef]);

//   const handleMessageSend = async (e) => {
//     e.preventDefault();
//     try {
//       await addDoc(collection(firestore, "user", "chats", chatId), {
//         from: companyId,
//         to: influencerId,
//         message: messageText,
//         likedMessageUser: [],
//         time: serverTimestamp(),
//       });

//       setMessageText("");
//       console.log("Message sent successfully!");
//     } catch (error) {
//       console.error("Error sending message:", error);
//     }
//   };

//   return (
//     <div className="relative">
//       <button
//         onClick={() => setShowChat(!showChat)}
//         className="bg-[#3b82f6] hover:bg-[#2563eb] transition duration-300 px-2 py-2 rounded-full flex items-center justify-center shadow-md"
//       >
//         <QuestionAnswerIcon style={{ fontSize: "25px", color: "white" }} />
//       </button>
//       {showChat ? (
//         <div
//           ref={chatBoxRef}
//           className="border z-50 border-[#c0c0c0] fixed right-5 bottom-5 h-[390px] rounded-lg w-[300px] flex flex-col justify-between pb-2 shadow-lg bg-white"
//         >
//           <div className="overflow-y-scroll p-2 h-full space-y-2">
//             {chats &&
//               chats.map((i) => (
//                 <div
//                   key={i.id}
//                   className={
//                     companyId === i.from ? "text-right px-4" : "text-left px-4"
//                   }
//                 >
//                   <h1
//                     className={`inline-block rounded-full py-1 px-4 ${
//                       companyId === i.from
//                         ? "bg-blue-600 text-white"
//                         : "bg-gray-200 text-black"
//                     } shadow-sm`}
//                   >
//                     {i.message}
//                   </h1>
//                 </div>
//               ))}
//           </div>

//           <div className="flex items-center justify-between p-2 border-t border-gray-200">
//             <input
//               type="text"
//               className="w-[85%] rounded-full pl-3 py-2 bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
//               placeholder="Enter your message"
//               value={messageText}
//               onChange={(e) => setMessageText(e.target.value)}
//             />
//             <button
//               type="submit"
//               onClick={handleMessageSend}
//               className="bg-[#3b82f6] hover:bg-[#2563eb] transition duration-300 p-2 rounded-full flex items-center justify-center shadow-md"
//             >
//               <SendIcon style={{ color: "white" }} />
//             </button>
//           </div>
//         </div>
//       ) : null}
//     </div>
//   );
// };

// export default ChatBox;
