import ApiInterceptor from "../../../utils/ApiInterceptor";

// Fetch all businesses
const getAllBusiness = (setDataBusiness) => {
  ApiInterceptor.get("Business")
    .then(({ data }) => {
      setDataBusiness(data["company"]);
    })
    .catch((error) => {
      console.error("Error fetching business data:", error);
    });
};

// Fetch all campaigns
const getAllCompaign = (setCampaign) => {
  ApiInterceptor.get("allcampaigns")
    .then(({ data }) => {
      setCampaign(data);
    })
    .catch((error) => {
      console.error("Error fetching campaign data:", error);
    });
};

// Fetch campaigns by user ID
const getAllCompaignByUserId = (setCampaign, id) => {
  ApiInterceptor.get(`campaign/user/${id}`)
    .then(({ data }) => {
      if (!Array.isArray(data)) {
        data = [data];
      }
      setCampaign(data);
    })
    .catch((error) => {
      console.error("Error fetching campaigns by user ID:", error);
    });
};

export { getAllBusiness, getAllCompaign, getAllCompaignByUserId };
