import React from "react";
import {
  ArrowLeft,
  Share2,
  Copy,
  Download,
  Instagram,
  Youtube,
  Upload,
} from "lucide-react";

const CampaignDetailScreen = ({
  name,
  cpm,
  requiredInfluencers,
  formattedDate,
  type,
  category,
  platform,
  summary,
  desc,
  previewSource1,
}) => {
  return (
    <div className="flex items-center justify-center ">
      <div className="relative mx-auto border-gray-800 border-[14px] rounded-[2.5rem] h-[600px] w-[300px] shadow-xl">
        {/* Phone Frame Buttons */}
        <div className="h-[32px] w-[3px] bg-gray-800 absolute -start-[17px] top-[72px] rounded-s-lg"></div>
        <div className="h-[46px] w-[3px] bg-gray-800 absolute -start-[17px] top-[124px] rounded-s-lg"></div>
        <div className="h-[46px] w-[3px] bg-gray-800 absolute -start-[17px] top-[178px] rounded-s-lg"></div>
        <div className="h-[64px] w-[3px] bg-gray-800 absolute -end-[17px] top-[142px] rounded-e-lg"></div>

        {/* Main Content Container */}
        <div className="rounded-[2rem] overflow-hidden w-[272px] h-[572px] bg-gray-50">
          <div className="flex flex-col h-full overflow-y-auto">
            {/* Header */}
            <div className="sticky top-0 bg-white shadow-sm p-4">
              <div className="flex justify-between items-center">
                <button className="p-1 hover:bg-gray-100 rounded-full">
                  <ArrowLeft className="w-5 h-5" />
                </button>
                <h1 className="font-semibold">Campaign Details</h1>
                <button className="p-1 hover:bg-gray-100 rounded-full">
                  <Share2 className="w-5 h-5" />
                </button>
              </div>
            </div>

            {/* Campaign Image */}
            <div className="p-3">
              <div className="rounded-lg overflow-hidden shadow-md">
                {previewSource1 ? (
                  <img
                    className="w-full h-48 object-contain"
                    src={previewSource1}
                    alt="Campaign"
                  />
                ) : (
                  <div className="flex items-center justify-center h-40 bg-gray-200 flex-col">
                    <Upload className="w-8 h-8 text-gray-400" />
                    <span>Uplaod Image</span>
                  </div>
                )}
              </div>
            </div>

            {/* Campaign Info */}
            <div className="px-4 space-y-4">
              {/* Stats */}
              <div className="flex justify-between text-sm">
                <div className="space-y-1">
                  <p className="text-gray-600">Amount</p>
                  <p className="font-semibold text-green-600">
                    &#8377;{(cpm * 2000) / 1000}
                  </p>
                </div>
                <div className="space-y-1">
                  <p className="text-gray-600">Required Followers</p>
                  <p className="font-semibold">{requiredInfluencers}</p>
                </div>
              </div>

              {/* Creator Info */}
              <div className="flex justify-between items-center">
                <div className="space-y-1">
                  <p className="font-medium">{name}</p>
                  <p className="text-xs text-gray-500">{formattedDate}</p>
                </div>
                <div className="text-right">
                  <p className="text-xs text-gray-600">Campaign Type</p>
                  <p className="text-sm font-medium">{type}</p>
                </div>
              </div>

              {/* Categories */}
              <div className="flex items-center gap-2">
                <span className="text-sm text-gray-600">Categories:</span>
                <span className="px-3 py-1 text-xs bg-white rounded-full border border-blue-200 shadow-sm">
                  {category}
                </span>
              </div>

              {/* Platform */}
              <div className="flex items-center gap-2">
                <span className="text-sm text-gray-600">Platform:</span>
                {platform === "Instagram" ? (
                  <Instagram className="w-5 h-5 text-pink-600" />
                ) : (
                  <Youtube className="w-5 h-5 text-red-600" />
                )}
              </div>

              {/* Description */}
              <div className="space-y-4">
                <div>
                  <h4 className="text-sm font-medium mb-2">Post Description</h4>
                  <p className="text-xs p-3 bg-white rounded-lg border border-blue-100 shadow-sm break-words">
                    {summary.length > 80
                      ? `${summary.slice(0, 80)}...`
                      : summary}
                  </p>
                </div>
                <div>
                  <h4 className="text-sm font-medium mb-2">Instructions</h4>
                  <p className="text-xs p-3 bg-white rounded-lg border border-blue-100 shadow-sm break-words">
                    {desc.length > 80 ? `${desc.slice(0, 80)}...` : desc}
                  </p>
                </div>
              </div>

              {/* Action Buttons */}
              <div className="space-y-3 pb-4">
                <div className="flex gap-2">
                  <button className="flex items-center gap-1 px-3 py-1.5 bg-blue-500 hover:bg-blue-600 text-white rounded-full text-xs">
                    <Copy className="w-4 h-4" />
                    Copy Link
                  </button>
                  <button className="flex items-center gap-1 px-3 py-1.5 bg-blue-500 hover:bg-blue-600 text-white rounded-full text-xs">
                    <Download className="w-4 h-4" />
                    Download Assets
                  </button>
                </div>
                <button className="w-full py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-full text-sm font-medium">
                  Apply for Campaign
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignDetailScreen;
