import { io } from "socket.io-client";
import { config } from "./config";

let socket;

export const initializeSocket = () => {
  // socket = io("https://api.cloutin.co");
  console.log(
    // "config.REACT_APP_BACKEND_SERVICE_URL",
    config.REACT_APP_BACKEND_SERVICE_URL
  );
  socket = io(config.REACT_APP_BACKEND_SERVICE_URL);
  socket.on("connect", () => {
    console.log("Socket Connected");
  });
};

export const registerUser = (userId) => {
  if (!socket) {
    throw new Error("Socket not initialized");
  }
  socket.emit("register", userId);
};

export const subscribeToNotifications = (userId, callback) => {
  if (!socket) {
    throw new Error("Socket not initialized");
  }
  socket.on("notification", callback);
};

export const disconnectSocket = () => {
  if (!socket) {
    throw new Error("Socket not initialized");
  }
  socket.disconnect();
};
