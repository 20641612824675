import axios from "axios";
import ApiInterceptor from "../utils/ApiInterceptor";

const generateAndSaveInvoice = async (invoice) => {
  const { orderNumber, date, totalAmount } = invoice;
  const { data } = await ApiInterceptor.post("invoiceGeneration", {
    orderNumber,
    date,
    totalAmount,
  });
  return data;
};

export default generateAndSaveInvoice;
