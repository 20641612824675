import ApiInterceptor from "../../utils/ApiInterceptor";

// Get affiliate by affiliate ID
const getAffiliatesByAffiliateId = (setAffiliate, affiliateId) => {
  ApiInterceptor.get(`affiliate/getAffiliate/${affiliateId}`)
    .then((response) => {
      setAffiliate(response.data);
    })
    .catch((error) => {
      console.error("Error fetching affiliate data:", error);
    });
};

// Get influencer by influencer ID
const getInfluencerByInfluencerId = async (influencerId) => {
  try {
    const { data } = await ApiInterceptor.get(`influencer/${influencerId}`);
    return data;
  } catch (error) {
    console.error("Error fetching influencer data:", error);
    return undefined;
  }
};

export { getAffiliatesByAffiliateId, getInfluencerByInfluencerId };
