import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import ApiInterceptor from "../../../utils/ApiInterceptor";

const InfluencerAdmin2 = () => {
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const getData = async () => {
    try {
      const res = await ApiInterceptor.get(
        `postsInfluencers/allPosts`
      );
      setPosts(res?.data);
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = posts.slice(indexOfFirstItem, indexOfLastItem);
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <div className="p-4 lg:p-12 m-4 lg:m-12 w-full">
      <div>
        <h1 className="font-bold text-black text-2xl">Influencers</h1>
      </div>
      {/* <div className="overflow-x-auto"> */}
      <div className=" py-6 w-full md:w-full ">
        <table className="w-full table-auto bg-white shadow-md rounded-md">
          <thead>
            <tr className="text-sm font-semibold text-left bg-blue-500 text-white">
              <th className="px-4 py-2">Influencer Name</th>
              <th className="px-4 py-2">Total Posts</th>
              <th className="px-4 py-2">Total Deals</th>
            </tr>
          </thead>
          <tbody>
            {currentItems
              ?.filter((item) => item?.influencerName?.length > 0)
              ?.map((post, index) => (
                <tr key={index} className="text-sm border-b">
                  <td
                    className="px-4 py-2 cursor-pointer "
                    onClick={() =>
                      navigate(`/admin/allpost/page5/${post?.influencerId}`)
                    }
                  >
                    <div className="flex items-center">
                      <span className=" hover:bg-gray-100 hover:underline text-blue-500 font-semibold">
                        {post?.influencerName}
                      </span>
                    </div>
                  </td>
                  <td className="px-4 py-2">{post?.totalPosts}</td>
                  <td className="px-4 py-2">{post?.totalDeals}</td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="flex justify-center mt-3">
          <Pagination
            count={Math.ceil(posts.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
          />
        </div>
      </div>
    </div>
  );
};

export default InfluencerAdmin2;
