import React from "react";
import FacebookLogin from "react-facebook-login";
import axios from "axios";
import ApiInterceptor from "../utils/ApiInterceptor";

const FacebookLoginButton = () => {
  const responseFacebook = async (response) => {
    try {
      const { data } = await ApiInterceptor.post(
        "instagramAnalytics",
        response
      );
      const send1 = {
        accessToken: data.response[0].access_token,
        id: data.response[0].id,
      };

      const resp1 = await ApiInterceptor.post(
        "instagramAnalytics/analytics",
        send1
      );
    } catch (error) {
      console.log("Error encountered");
    }
  };

  return (
    <FacebookLogin
      appId="870437341353868"
      autoLoad={false}
      fields="name,email"
      scope="instagram_basic,pages_show_list,pages_read_engagement,read_insights,pages_manage_posts,email,public_profile"
      callback={responseFacebook}
    />
  );
};

export default FacebookLoginButton;
