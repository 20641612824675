import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import ApiInterceptor from "../../utils/ApiInterceptor";

const initialState = {
  status: "idle",
  isVerified: false,
};

export const checkEmailStatus = createAsyncThunk(
  "/user/verify/email",
  async (currentUserId, { rejectWithValue }) => {
  console.log("currentUserId", currentUserId);
    try {
      const response = await ApiInterceptor.get(
        `get/email/verified/status/${currentUserId}`
      );

      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error?.message);
    }
  }
);

export const emailSlice = createSlice({
  name: "email",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(checkEmailStatus.pending, (state, action) => {
        state.error = null;
      })
      .addCase(checkEmailStatus.fulfilled, (state, action) => {
        state.isVerified = action.payload;
      })
      .addCase(checkEmailStatus.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export const selectEmailVerificationStatus = (state) => state.email.isVerified;

export default emailSlice.reducer;
