import React, { useState } from "react";
import axios from "axios";
import ApiInterceptor from "../../utils/ApiInterceptor";

const ButtonCampaign = ({ label, id, setInfluencer, color, tcolor }) => {
  const [isEnabled, setIsEnabled] = useState(label === "Active");

  const handleToggle = () => {
    if (isEnabled === true) {
      ApiInterceptor
        // .put(`https://test-branch-ulc7.onrender.com/api/Business/edit/${id}`,
        .put(`Business/edit/${id}`, {
          status: "Inactive",
        })
        .then(({ data }) => {
          setInfluencer(data["company"]);
          setIsEnabled(!isEnabled);
          ApiInterceptor.post("notifications/post", {
            data: {
              influencerId: id,
              notificationTitle: "Status",
              notificationDesc: "Your status is Inactivated by administrator",
            },
          });
        });
    } else {
      ApiInterceptor
        // .put(`https://test-branch-ulc7.onrender.com/api/Business/edit/${id}`,
        .put(`Business/edit/${id}`, {
          status: "Active",
        })
        .then(({ data }) => {
          setInfluencer(data["company"]);
          setIsEnabled(!isEnabled);
          ApiInterceptor.post("notifications/post", {
            data: {
              influencerId: id,
              notificationTitle: "Status",
              notificationDesc: "Your status is Activated by administrator",
            },
          });
        });
    }
  };

  return (
    <button
      className={`cursor-pointer rounded-xl py-2 px-2 font-bold text-xs transition duration-300 ease-in-out ${
        label === "Active"
          ? "bg-gray-300 text-black"
          : label === "Pending"
          ? "bg-green-500 text-white"
          : "bg-blue-500 text-white"
      }`}
      onClick={handleToggle}
    >
      {label === "Active"
        ? "Disable"
        : label === "Pending"
        ? "Approve"
        : "Enable"}
    </button>
  );
};

export default ButtonCampaign;
