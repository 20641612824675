// SocketContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import io from "socket.io-client";
import Cookies from "js-cookie";
import { config } from "../config";

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const currentUserId = Cookies.get("userid");

  useEffect(() => {
    const newSocket = io(`${config.REACT_APP_BASE_URL}`);
    setSocket(newSocket);

    newSocket.on("connect", () => {});

    newSocket.emit("register", currentUserId);

    return () => newSocket.close();
  }, [socket]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export const useSocket = () => {
  return useContext(SocketContext);
};
