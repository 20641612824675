import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
// import Cookie from "js-cookie";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { SpinnerCircular } from "spinners-react";
import { getAllMarketPlaceData } from "./Api";
import Typewriter from "typewriter-effect";
import { FaArrowDown, FaChevronDown } from "react-icons/fa6";
import ApiInterceptor from "../../utils/ApiInterceptor";
const InfluencerInfo = () => {
  const id1 = useParams();
  const [campEndDate, setCampEndDate] = useState("");
  const [errors, setErros] = useState(false);

  const [summary, setSummary] = useState("");
  const [url, setUrl] = useState("");
  const [campaign_tracking_type, setCampaignTrackingType] = useState("");
  const [cover, setCover] = useState("");
  // const [sample, setSample] = useState("");

  // images useState starts

  const [file1, setFile1] = useState([]);
  const [file2, setFile2] = useState([]);
  const [file3, setFile3] = useState([]);

  const ImageUpload = ({
    previewSource,
    sample,
    selectedImg,
    loading,
    handleFileInputChange,
    handleImage,
    uploadImage,
  }) => (
    <div className="shadow-1xl">
      <div className="bg-white px-5 py-[30px]">
        {!loading ? (
          <label className="flex items-center flex-col" htmlFor={selectedImg}>
            <img
              className="w-32 h-24 cursor-pointer"
              src={
                previewSource
                  ? previewSource
                  : sample
                  ? sample
                  : "/Images/imagecenter.png"
              }
              alt="drop"
            />
          </label>
        ) : (
          <SpinnerCircular
            size="90"
            className="w-full flex items-center mx-auto"
            thickness="100"
            speed="600"
            color="white"
            secondaryColor="black"
          />
        )}

        <input
          id={selectedImg}
          accept="image/png, image/jpeg, image/jpg"
          type="file"
          hidden
          value={selectedImg}
          onChange={handleFileInputChange}
        />

        {selectedImg && (
          <button className="cursor-pointer" onClick={handleImage}>
            ok
          </button>
        )}
      </div>
    </div>
  );

  const [sample, setSample] = useState("");
  const [sample1, setSample1] = useState("");
  const [sample2, setSample2] = useState("");

  const [selectedImg2, setSelectedImg2] = useState("");
  const [selectedImg3, setSelectedImg3] = useState("");
  const [selectedImg4, setSelectedImg4] = useState("");

  const [previewSource2, setPreviewSource2] = useState("");
  const [previewSource3, setPreviewSource3] = useState("");
  const [previewSource4, setPreviewSource4] = useState("");

  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);

  const [fileInputState2, setFileInputState2] = useState("");
  const [fileInputState3, setFileInputState3] = useState("");
  const [fileInputState4, setFileInputState4] = useState("");

  useEffect(() => {
    if (selectedImg2) {
      handleImage2();
    }
  }, [selectedImg2]);

  useEffect(() => {
    if (selectedImg3) {
      handleImage3();
    }
  }, [selectedImg3]);

  useEffect(() => {
    if (selectedImg4) {
      handleImage4();
    }
  }, [selectedImg4]);

  const handleFileInputChange2 = (e) => {
    const file = e.target.files[0];
    previewFile2(file);
    setSelectedImg2(file);
    setFileInputState2(e.target.value);
  };

  const handleFileInputChange3 = (e) => {
    const file = e.target.files[0];
    previewFile3(file);
    setSelectedImg3(file);
    setFileInputState3(e.target.value);
  };
  const handleFileInputChange4 = (e) => {
    const file = e.target.files[0];
    previewFile4(file);
    setSelectedImg4(file);
    setFileInputState4(e.target.value);
  };

  const previewFile2 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource2(reader.result);
    };
  };

  const previewFile3 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource3(reader.result);
    };
  };
  const previewFile4 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource4(reader.result);
    };
  };

  const handleImage2 = async () => {
    if (!selectedImg2) return;

    try {
      const maxSizeMB = 10;
      const maxSizeBytes = maxSizeMB * 1024 * 1024;

      if (selectedImg2.size > maxSizeBytes) {
        toast.error(`Image size should be less than ${maxSizeMB} MB`);
        return;
      }

      setLoading2(true);

      const formData = new FormData();
      formData.append("file", selectedImg2);

      const { data } = await ApiInterceptor.post("upload/files", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      });

      setFileInputState2("");
      setPreviewSource2("");
      setSample(data.publicUrl);
      setLoading2(false);
      toast.success("Image uploaded");
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };
  const handleImage3 = async () => {
    if (!selectedImg3) return;

    try {
      const maxSizeMB = 10;
      const maxSizeBytes = maxSizeMB * 1024 * 1024;

      if (selectedImg3.size > maxSizeBytes) {
        toast.error(`Image size should be less than ${maxSizeMB} MB`);
        return;
      }

      setLoading3(true);

      const formData = new FormData();
      formData.append("file", selectedImg3);

      const { data } = await ApiInterceptor.post("upload/files", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      });

      setFileInputState3("");
      setPreviewSource3("");
      setSample1(data.publicUrl);
      setLoading3(false);
      toast.success("Image uploaded");
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.message);
    }
  };

  const handleImage4 = async () => {
    if (!selectedImg4) return;

    try {
      const maxSizeMB = 150;
      const maxSizeBytes = maxSizeMB * 1024 * 1024;

      if (selectedImg4.size > maxSizeBytes) {
        toast.error(`Video size should be less than ${maxSizeMB} MB`);
        return;
      }

      setLoading4(true);

      const formData = new FormData();
      formData.append("file", selectedImg4);

      const { data } = await ApiInterceptor.post("upload/files", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      });

      setFileInputState4("");
      setPreviewSource4(data.publicUrl);
      setSample2(data.publicUrl);
      setLoading4(false);
      toast.success("Video uploaded");
    } catch (err) {
      // console.error(err);
      toast.error(err?.response?.data?.message || "Video size is too large");
    }
  };
  const convertImageToBase64 = (image) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  // formaffiliateinpurt code ends

  const [isBarterChecked, setIsBarterChecked] = useState(false);
  const [productName, setProductName] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [data, setData] = useState([]);
  const [gstPercentage, setGstPercentage] = useState(0);
  const [platformCharge, setPlatFormCharge] = useState(0);
  const [comissionRate, setComissionRate] = useState(0);
  const [totalProductPrice, setTotalProductPrice] = useState(0);
  const [showAdditionalCharges, setShowAdditionalCharges] = useState(false);

  useEffect(() => {
    const fetchTaxData = async () => {
      try {
        const response = await ApiInterceptor.get("admin/taxes/gettaxdata");
        // console.log(response.data);
        const { comission_rate, gst_rate, platform_charge } = response.data;
        setComissionRate(comission_rate);
        setGstPercentage(gst_rate);
        setPlatFormCharge(platform_charge);
      } catch (error) {
        setErros(true);
      } finally {
        setErros(false);
      }
    };

    fetchTaxData();
  }, []);

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const handleBarterChange = () => {
    setIsBarterChecked(!isBarterChecked);
  };
  const companyId = Cookies.get("userid");

  // const { search } = useLocation();
  // const params = new URLSearchParams(search);
  // const popular = params.get("popular");

  const urlSearchParams = new URLSearchParams(window.location.search);
  const popular = urlSearchParams.get("popular");
  const name = urlSearchParams.get("name");
  const price = urlSearchParams.get("price");
  const id = urlSearchParams.get("id");

  const platform = urlSearchParams.get("platform");

  useEffect(() => {
    setProductName(name);
    setProductPrice(price);
  }, [name, price]);

  useEffect(() => {
    const priceAsNumber = parseFloat(price);

    const gstAmount = (priceAsNumber * gstPercentage) / 100;
    setGstPercentage(gstAmount);

    const platformChargesAmount = (priceAsNumber * platformCharge) / 100;
    setPlatFormCharge(platformChargesAmount);

    const totalPrice = priceAsNumber + gstAmount + platformChargesAmount;
    setTotalProductPrice(totalPrice);
  }, [price]);
  // console.log("totalProductPrice", totalProductPrice);
  // console.log("platformCharge", platformCharge);
  // console.log("gstPercentage", gstPercentage);

  useEffect(() => {
    getAllMarketPlaceData(setData, id1.id6);
  }, []);
  // console.log("data", data[0]?.followers);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const create = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitting(true); // Set loading state to true
      const imagePaths = [sample, sample1, sample2];
      if (campaign_tracking_type === "") {
        toast.error("Campaign tracking type is required", {
          duration: 1500,
        });
        return;
      }

      if (campaign_tracking_type !== "Brand awareness" && url === "") {
        toast.error("Redirect URL is required", {
          duration: 1500,
        });
        return;
      }
      if (summary.length < 30) {
        toast.error(
          "Please Provide a detailed instruction for the influencer",
          {
            duration: 1500,
          }
        );
        return;
      }

      if (!campEndDate) {
        toast.error("Order Expire Date is required", {
          duration: 1500,
        });
        return;
      }

      if (!sample || !sample1) {
        toast.error("Please Upload all two Images", {
          duration: 1500,
        });
        return;
      }
      const response = await ApiInterceptor.post(
        "marketplace/createorder/",
        {
          influencer_id: id1.id6, // Assuming id1 is the influencer ID obtained from useParams
          company_id: companyId, // Assuming id1 is the influencer ID obtained from useParams
          campaign_tracking_type: campaign_tracking_type,
          barter: isBarterChecked ? "Product" : "", // Adjust based on your schema
          // product_price: isBarterChecked ? productPrice : 0, // Adjust based on your schema
          product_price: productPrice, // Adjust based on your schema
          // product_name: isBarterChecked ? productName : 0, // Adjust based on your schema
          product_name: productName, // Adjust based on your schema
          // placement: "Instagram Feed",
          url: url, // Adjust based on your schema
          // package_id: url,
          // redirect_url: url,
          package_id: id,
          instruction: summary,
          cover_image_url: imagePaths,
          is_popular_package: popular,
          orderExpireDate: campEndDate,
          // sample_image_url: sample,
        },
        config
      );

      if (response?.status == 200) {
        toast.success("Campaign created successfully");
        setCampaignTrackingType("");
        setUrl("");
        setSummary("");
        setSample("");
        setSample1("");
        setSample2("");
        setProductName("");
        setProductPrice("");
        setCover("");
        setFile1([]);
        setFile2([]);
        setFile3([]);
        setPreviewSource2("");
        setPreviewSource3("");
        setPreviewSource4("");
        setGstPercentage(0);
        setPlatFormCharge(0);
        setTotalProductPrice(0);
        setShowAdditionalCharges(false);
        navigate("/maindashboard");
      } else {
        
        toast.error(response?.data.error || "Something went wrong");
      }
    } catch (error) {
      console.log(error,'error')
      toast(error.response?.data.error || "Something went wrong");
    } finally {
      setIsSubmitting(false);
    }
  };

  const [isValidUrl, setIsValidUrl] = useState(true);

  const threeDaysAhead = () => {
    const date = new Date();
    date.setDate(date.getDate() + 3);
    return date;
  };

  return (
    <div className="p-[20px] pt-0 md:p-2 w-full max-w-7xl mx-auto  bg-white">
      <div className="flex flex-row gap-3 ml-4 text-3xl text-blue-500">
        <h1 className="font-extrabold text-blue-800">✍</h1>
        <Typewriter
          options={{
            strings: ["Create Your First Campaign"],
            loop: true,
            autoStart: true,
            typeSpeed: 100,
          }}
        />
      </div>
      <div className="md:w-full md:p-[15px]">
        <form onSubmit={create}>
          {/* campaign tracking type starts */}
          <div className=" ">
            <h1 className="font-bold mt-[10px] ">Campaign Tracking Type1</h1>
            <select
              className="w-full py-2 pl-2  mt-[10px] focus:outline-none bg-white shadow-md rounded-md cursor-pointer"
              onChange={(e) => {
                setCampaignTrackingType(e.target.value);
              }}
            >
              <option hidden={true} className="!cursor-pointer">
                Select Campaign Tracking Type
              </option>
              <option value="Shopify Ecommerce" className="cursor-pointer">
                {/* Enable Shopify Tracking */}
                Shopify Ecommerce
              </option>
              <option value="Brand awareness" className="cursor-pointer">
                Brand awareness
              </option>
              {/* <option value="Conversions">Conversions</option>
              <option value="Website Traffic">Website Traffic</option> */}
            </select>
          </div>
          {/* campaign tracking type ends */}

          {/* redirect url box starts */}
          {campaign_tracking_type !== "Brand awareness" && (
            <div className=" ">
              <h1 className="font-bold my-[10px]">Redirect url</h1>
              <input
                className="pl-6 border border-blue-300 rounded-3xl text-blue-500 p-3 w-full focus:outline-none"
                type="text"
                placeholder="Link in bio or swipe up url(story)"
                value={url}
                // onChange={handleUrlChange}
                onChange={(e) => setUrl(e.target.value)}
              />
              {!isValidUrl && (
                <div style={{ color: "red" }}>Invalid website URL</div>
              )}
            </div>
          )}
          {/* redirect url box ends */}

          {/* instructions for influencer starts */}
          <div className=" ">
            <h1 className="font-bold my-[10px]">
              Instructions for influencers
            </h1>
            <textarea
              className="w-full pl-3 focus:outline-none p-3 text-gray-700 border border-blue-300 shadow-md rounded-md resize-none"
              placeholder="Instructions for the Influencers..... "
              rows={6}
              value={summary}
              onChange={(e) => setSummary(e.target.value)}
            />
          </div>
          {/* instructions for influencer ends */}

          <div>
            <div className="flex gap-2 flex-wrap py-2">
              <h1 className="text-black font-bold">Order Expire Date:</h1>
              <DatePicker
                selected={campEndDate}
                minDate={threeDaysAhead()}
                onChange={(date) => {
                  setCampEndDate(date);
                }}
                className="border rounded-lg p-2"
                placeholderText="Select expire date"
              />
            </div>
          </div>

          {/* Drop your Images section starts */}
          <div className="mt-3">
            <h1 className="font-bold mb-[10px]">Add Images </h1>
          </div>
          {/* Drop your Images section ends */}

          <div className="flex justify-center space-x-8">
            {/* First input for images */}
            <div className="shadow-1xl ">
              <div className="bg-white px-5 py-[30px] border border-gray-400 rounded-md">
                {!loading2 ? (
                  <label className="flex items-center flex-col" htmlFor="pt2">
                    <img
                      className="w-32 h-24 cursor-pointer"
                      src={sample ? sample : "/Images/imagecenter.png"}
                      alt="drop"
                    />
                  </label>
                ) : (
                  <SpinnerCircular
                    size="90"
                    className="w-full flex items-center mx-auto"
                    thickness="100"
                    speed="600"
                    color="white"
                    secondaryColor="black"
                  />
                )}

                <input
                  id="pt2"
                  accept="image/png, image/jpeg, image/jpg"
                  type="file"
                  hidden
                  value={fileInputState2}
                  onChange={(e) =>
                    handleFileInputChange2(
                      e,
                      "image",
                      "previewSource2",
                      "loading2"
                    )
                  }
                />
              </div>
            </div>

            {/* Second input for videos */}
            <div className="shadow-1xl">
              <div className="bg-white px-5 py-[30px] border border-gray-500 rounded-md">
                {!loading3 ? (
                  <label className="flex items-center flex-col" htmlFor="pt3">
                    {previewSource3 && fileInputState3.startsWith("video/") ? (
                      <video className="w-32 h-24" controls>
                        <source src={previewSource3} type={fileInputState3} />
                      </video>
                    ) : (
                      <img
                        className="w-32 h-24 cursor-pointer"
                        src={sample1 ? sample1 : "/Images/imagecenter.png"}
                        alt="drop"
                      />
                    )}
                  </label>
                ) : (
                  <SpinnerCircular
                    size="90"
                    className="w-full flex items-center mx-auto"
                    thickness="100"
                    speed="600"
                    color="white"
                    secondaryColor="black"
                  />
                )}

                <input
                  id="pt3"
                  accept="image/png, image/jpeg, image/jpg"
                  type="file"
                  hidden
                  value={fileInputState3}
                  onChange={(e) =>
                    handleFileInputChange3(
                      e,
                      "video",
                      "previewSource3",
                      "loading3"
                    )
                  }
                />
              </div>
            </div>

            {/* Third input for images or videos */}
            <div className="shadow-1yxl">
              <div className="bg-white px-5 py-[30px] border border-gray-400 rounded-md">
                {!loading4 ? (
                  <label className="flex items-center flex-col" htmlFor="pt4">
                    {previewSource4 ? (
                      <video className="w-32 h-24" controls>
                        <source src={previewSource4} type={fileInputState4} />
                      </video>
                    ) : (
                      <img
                        className="w-32 h-24 cursor-pointer"
                        src={sample2 ? sample2 : "/Images/imagecenter.png"}
                        alt="drop"
                      />
                    )}
                  </label>
                ) : (
                  <SpinnerCircular
                    size="90"
                    className="w-full flex items-center mx-auto"
                    thickness="100"
                    speed="600"
                    color="white"
                    secondaryColor="black"
                  />
                )}

                <input
                  id="pt4"
                  accept="video/*"
                  type="file"
                  hidden
                  // value={fileInputState4}
                  onChange={(e) =>
                    handleFileInputChange4(
                      e,
                      "image",
                      "previewSource4",
                      "loading4"
                    )
                  }
                />
              </div>
              <h6 className="text-center text-blue-400 mt-[1vw] ">
                Only video allowed
              </h6>
            </div>
            {/* </div>
          </div> */}
          </div>

          {/* summary section starts */}

          <div className="pt-4">
            <div>
              <h1 className="font-bold text-lg text-gray-800">Summary</h1>
            </div>
            <div className="pt-2 flex flex-col md:flex-row justify-between items-center gap-4">
              <div className="flex flex-col p-4 gap-2 bg-white shadow-lg rounded-lg hover:shadow-md transform hover:scale-105 transition duration-300 ease-in-out">
                <h1 className="text-gray-500 text-sm">Package Name</h1>
                <p className="font-semibold text-lg text-center text-gray-800 uppercase">
                  {productName}
                </p>
              </div>
              <div className="flex flex-col p-4 gap-2 bg-white shadow-lg rounded-lg hover:shadow-md transform hover:scale-105 transition duration-300 ease-in-out">
                <h1 className="text-gray-500 text-sm">Package Price</h1>
                <p className="font-semibold text-lg text-center text-green-600">
                  &#8377; {productPrice}
                </p>
              </div>
              <div className="flex flex-col p-4 gap-2 bg-white shadow-lg rounded-lg hover:shadow-md transform hover:scale-105 transition duration-300 ease-in-out">
                <h1 className="text-gray-500 text-sm">Followers Reach</h1>
                <p className="font-semibold text-lg text-center text-blue-600">
                  {platform === "youtube" ? data?.ytfollowers : data?.followers}
                </p>
              </div>
            </div>
          </div>

          {/* summary section ends */}

          {/* gst price details starts */}
          {/* <div className="flex justify-start gap-4 items-center mt-2">
            <p className="inline-block cursor-pointer text-blue-500 underline hover:text-blue-600">
              Show Additional Charges
            </p>
            <span
              onClick={() => setShowAdditionalCharges(!showAdditionalCharges)}
              className={`${
                showAdditionalCharges ? "transform rotate-180" : ""
              } cursor-pointer`}
            >
              <FaChevronDown />
            </span>
          </div>
          {showAdditionalCharges ? (
            <>
              {" "}
              <div className=" px-4 py-2 my-2 bg-white border border-blue-300 shadow-md rounded-sm">
                <p>
                  GST Charges Added -{" "}
                  <span className="font-bold">{gstPercentage}</span>{" "}
                </p>
                <p>
                  Platform Charges Added -{" "}
                  <span className="font-bold">{platformCharge}</span>{" "}
                </p>
                <p>
                  Total Package Price -{" "}
                  <span className="font-bold">{totalProductPrice}</span>{" "}
                </p>
              </div>
            </>
          ) : (
            <></>
          )} */}
          <div className="flex justify-start gap-4 items-center mt-2">
            <p
              className="inline-block cursor-pointer text-blue-500 underline hover:text-blue-600"
              onClick={() => setShowAdditionalCharges(!showAdditionalCharges)}
            >
              Show Additional Charges
            </p>
            <span
              onClick={() => setShowAdditionalCharges(!showAdditionalCharges)}
              className={`${
                showAdditionalCharges ? "transform rotate-180" : ""
              } transition-transform duration-300 cursor-pointer text-blue-500 hover:text-blue-600`}
            >
              <FaChevronDown />
            </span>
          </div>

          {showAdditionalCharges && (
            <div className="px-6 py-4 my-3 bg-white border-l-4 border-blue-400 shadow-lg rounded-lg">
              <p className="text-gray-700 mb-1">
                GST Charges Added -{" "}
                <span className="font-bold text-blue-700">{gstPercentage}</span>
              </p>
              <p className="text-gray-700 mb-1">
                Platform Charges Added -{" "}
                <span className="font-bold text-blue-700">
                  {platformCharge}
                </span>
              </p>
              <p className="text-gray-700">
                Total Package Price -{" "}
                <span className="font-bold text-green-700">
                  {totalProductPrice}
                </span>
              </p>
            </div>
          )}

          {/* gst price details ends */}

          {/* submit Button Starts */}
          {isSubmitting ? (
            <button
              className="text-center cursor-pointer py-2 text-white w-full my-[10px] rounded-lg bg-blue-500 hover:bg-blue-700"
              type="submit"
            >
              Submiting
            </button>
          ) : (
            <button
              className="text-center cursor-pointer py-2 text-white w-full my-[10px] rounded-lg  bg-blue-500 hover:bg-blue-700"
              type="submit"
            >
              Submit
            </button>
          )}

          {/* submit Button ends */}
        </form>
      </div>
    </div>
  );
};

export default InfluencerInfo;
