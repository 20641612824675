import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  FaImage,
  FaEnvelope,
  FaCalendarAlt,
  FaDollarSign,
} from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import ApiInterceptor from "../../utils/ApiInterceptor";

const AdminRefundDetail = () => {
  const params = useParams();

  const id = params.id;

  const [data, setData] = useState([]);
  const [showEditRequests, setShowEditRequests] = useState(false);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiInterceptor.get(
          `admin/refunds/getRefundsData/${id}`
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleProcessRefund = async () => {
    try {
      setLoading("process");

      const response = await ApiInterceptor.post(
        `admin/refunds/update/refund/${id}`
      );

      if (response?.status == 200) {
        setLoading(null);
        toast.success("Refund successfully processed!");
      }
    } catch (error) {
      setLoading(null);
      toast.error("Refund already processed!");
    }
  };

  const handleRejectRefund = async () => {
    try {
      setLoading("reject");
      const response = await ApiInterceptor.post(
        `admin/refunds/cancel/refund/${id}`
      );

      if (response?.status == 200) {
        setLoading(null);
        toast.error("Refund request rejected!");
      }
    } catch (error) {
      setLoading(null);
      toast.error("Refund already cancelled!");
    }
  };

  const styles = {
    container: {
      maxWidth: "1200px",
      margin: "0 auto",
      padding: "20px",
      background: "#f2f4f7",
      borderRadius: "10px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      fontFamily: "Montserrat, Arial, sans-serif",
    },
    header: {
      textAlign: "center",
      fontSize: "36px",
      fontWeight: "700",
      color: "#2c3e50",
      marginBottom: "30px",
    },
    section: {
      marginBottom: "30px",
      padding: "20px",
      borderRadius: "10px",
      background: "#ffffff",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    },
    sectionTitle: {
      fontSize: "28px",
      fontWeight: "600",
      color: "#2c3e50",
      marginBottom: "15px",
      display: "flex",
      alignItems: "center",
    },
    sectionIcon: {
      marginRight: "10px",
      color: "#3498db",
      fontSize: "30px",
    },
    sectionContent: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      gap: "15px",
    },
    contentCard: {
      flex: "1",
      minWidth: "290px",
      padding: "20px",
      borderRadius: "8px",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      background: "#ffffff",
    },
    imageContainer: {
      position: "relative",
      width: "100%",
      height: "250px", // Increased height
      overflow: "hidden",
      borderRadius: "8px",
      border: "1px solid #ddd",
      marginBottom: "10px",
    },
    image: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    link: {
      color: "#3498db",
      textDecoration: "none",
    },
    toggleRequests: {
      background: "#3498db",
      color: "#ffffff",
      border: "none",
      padding: "10px 20px",
      borderRadius: "8px",
      cursor: "pointer",
      marginBottom: "15px",
      fontSize: "16px",
    },
    editRequests: {
      background: "#f8f9fa",
      borderRadius: "8px",
      padding: "15px",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    },
    editRequestsTitle: {
      fontSize: "20px",
      fontWeight: "600",
      color: "#2c3e50",
      marginBottom: "10px",
    },
    editRequestsList: {
      listStyle: "none",
      padding: "0",
      margin: "0",
    },
    editRequestItem: {
      background: "#ffffff",
      borderRadius: "5px",
      padding: "10px",
      boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
      marginBottom: "5px",
    },
    amountHighlight: {
      display: "inline-block",
      padding: "5px 15px",
      backgroundColor: "#28a745",
      color: "#ffffff",
      borderRadius: "8px",
      fontSize: "18px",
      fontWeight: "600",
    },
    categoryButton: {
      display: "inline-block",
      padding: "8px 15px",
      margin: "5px",
      borderRadius: "8px",
      background: "#3498db",
      color: "#ffffff",
      border: "none",
      fontSize: "14px",
      cursor: "pointer",
      textDecoration: "none",
    },
    categoryButtonContainer: {
      display: "flex",
      flexWrap: "wrap",
      gap: "10px",
    },
    actionButtons: {
      display: "flex",
      justifyContent: "flex-end",
      gap: "15px",
      marginTop: "15px",
    },
    actionButton: {
      padding: "10px 15px",
      borderRadius: "8px",
      fontSize: "16px",
      color: "#ffffff",
      cursor: "pointer",
      border: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "230px",
      fontFamily: "Montserrat, Arial, sans-serif",
    },
    processButton: {
      background: "#28a745",
    },
    rejectButton: {
      background: "#dc3545",
    },
    loading: {
      background: "#6c757d",
      cursor: "not-allowed",
    },
  };

  return (
    <div style={styles.container}>
      <ToastContainer />

      <h1 style={styles.header}>Admin Refund Detail</h1>

      {/* Campaign Details */}
      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>
          <FaImage style={styles.sectionIcon} /> Campaign Details
        </h2>
        <div style={styles.sectionContent}>
          <div style={styles.contentCard}>
            <div style={styles.imageContainer}>
              <img
                src={data?.campaign_id?.cover_image_url}
                alt="Campaign Cover"
                style={styles.image}
              />
            </div>
            <p>
              <strong>Name:</strong> {data?.campaign_id?.name}
            </p>
            <p>
              <strong>Company Mail:</strong> {data?.campaign_id?.company_mail}
            </p>
            <p>
              <strong>Category:</strong> {data?.campaign_id?.category}
            </p>
            <p>
              <strong>Platform:</strong> {data?.campaign_id?.platform}
            </p>
            <p>
              <strong>Status:</strong> {data?.campaign_id?.statusCampaign}
            </p>
            <p>
              <strong>Type:</strong> {data?.campaign_id?.type}
            </p>
          </div>
          <div style={styles.contentCard}>
            <div style={styles.imageContainer}>
              <img
                src={data?.campaign_id?.sample_image_url}
                alt="Campaign Sample"
                style={styles.image}
              />
            </div>
            <p>
              <strong>URL:</strong>{" "}
              <a href={data?.campaign_id?.url} style={styles.link}>
                {data?.campaign_id?.url}
              </a>
            </p>
            <p>
              <strong>End Date:</strong> {data?.campaign_id?.campEndDate}
            </p>
            <p>
              <strong>Tracking Type:</strong> {data?.campaign_id?.tracking_type}
            </p>
            <p>
              <strong>Cost:</strong> ₹{data?.campaign_id?.cost}
            </p>
            <p>
              <strong>Influencers Required:</strong>{" "}
              {data?.campaign_id?.requiredInfluencers}
            </p>
          </div>
        </div>
      </section>

      {/* Influencer Details */}
      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>
          <FaEnvelope style={styles.sectionIcon} /> Influencer Details
        </h2>
        <div style={styles.sectionContent}>
          <div style={styles.contentCard}>
            <div style={styles.imageContainer}>
              <img
                src={data?.influencer_id?.profile_image_url}
                alt="Influencer"
                style={styles.image}
              />
            </div>
            <p>
              <strong>Name:</strong> {data?.influencer_id?.name}
            </p>
            <p>
              <strong>Email:</strong> {data?.influencer_id?.email}
            </p>
            <p>
              <strong>City:</strong> {data?.influencer_id?.city}
            </p>
          </div>
          <div style={styles.contentCard}>
            <p>
              <strong>Categories:</strong>
            </p>
            <div style={styles.categoryButtonContainer}>
              {data?.influencer_id?.categories
                ?.split(",")
                .map((category, index) => (
                  <button key={index} style={styles.categoryButton}>
                    {category.trim()}
                  </button>
                ))}
            </div>
            <p>
              <strong>Description:</strong> {data?.influencer_id?.description}
            </p>
          </div>
        </div>
      </section>

      {/* Business Details */}
      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>
          <FaCalendarAlt style={styles.sectionIcon} /> Business Details
        </h2>
        <div style={styles.sectionContent}>
          <div style={styles.contentCard}>
            <p>
              <strong>Name:</strong> {data?.business_id?.name}
            </p>
            <p>
              <strong>Username:</strong> {data?.business_id?.username}
            </p>
            <p>
              <strong>Company:</strong> {data?.business_id?.company}
            </p>
            <p>
              <strong>Email:</strong> {data?.business_id?.email}
            </p>
            <p>
              <strong>Phone:</strong> {data?.business_id?.phone}
            </p>
            <p>
              <strong>Requested Amount:</strong>{" "}
              <span style={styles.amountHighlight}>${data?.amount}</span>
            </p>
          </div>
        </div>
      </section>

      {/* Influencer Post Details */}
      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>
          <FaDollarSign style={styles.sectionIcon} /> Influencer Post Details
        </h2>
        <div style={styles.sectionContent}>
          <div style={styles.contentCard}>
            <div style={styles.imageContainer}>
              <img
                src={data?.influencerPost_id?.postImage}
                alt="Influencer Post"
                style={styles.image}
              />
            </div>
            <p>
              <strong>Status:</strong> {data?.influencerPost_id?.post_status}
            </p>
            <p>
              <strong>Caption:</strong> {data?.influencerPost_id?.caption}
            </p>
            <p>
              <strong>Post URL:</strong>{" "}
              <a href={data?.influencerPost_id?.post_url} style={styles.link}>
                {data?.influencerPost_id?.post_url}
              </a>
            </p>

            {/* Edit Requests */}
            <div style={{ ...styles.contentCard, marginTop: "15px" }}>
              <button
                onClick={() => setShowEditRequests(!showEditRequests)}
                style={styles.toggleRequests}
              >
                {showEditRequests ? "Hide Edit Requests" : "Show Edit Requests"}
              </button>
              {showEditRequests && (
                <div style={styles.editRequests}>
                  <p style={styles.editRequestsTitle}>
                    Edit Requests (
                    {data?.influencerPost_id?.editRequests?.count})
                  </p>
                  <ul style={styles.editRequestsList}>
                    {data?.influencerPost_id?.editRequests?.rejectionReasons.map(
                      (reason, index) => (
                        <li key={index} style={styles.editRequestItem}>
                          {reason || "No reason provided"}
                        </li>
                      )
                    )}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* Action Buttons */}
      <div style={styles.actionButtons}>
        <button
          onClick={handleRejectRefund}
          style={{
            ...styles.actionButton,
            ...styles.rejectButton,
            ...(loading === "reject" ? styles.loading : {}),
          }}
          disabled={loading !== null}
        >
          {loading === "reject" ? "Processing..." : "Reject Refund Request"}
        </button>
        <button
          onClick={handleProcessRefund}
          style={{
            ...styles.actionButton,
            ...styles.processButton,
            ...(loading === "process" ? styles.loading : {}),
          }}
          disabled={loading !== null}
        >
          {loading === "process" ? "Processing..." : "Process Refund"}
        </button>
      </div>
    </div>
  );
};

export default AdminRefundDetail;
