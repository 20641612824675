import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Label,
} from "recharts";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Posted from "./Posted";
import toast from "react-hot-toast";
import axios from "axios";
import PostPageOfInfluencer from "./PostPageOfInfluencer";
import { useDispatch, useSelector } from "react-redux";
import { showLoading, hideLoading } from "../../../redux/Slice/loadingSlice";
import { SpinnerCircular } from "spinners-react";
import ApiInterceptor from "../../../utils/ApiInterceptor";
function AllPosts_Admin() {
  const navigate = useNavigate();
  // const [active, setActive] = useState([]);
  // const [data, setData] = useState([]);

  const data = [
    {
      _id: "60f9b0b3e6b3a40015f1a3a1",
      cover: "/Images/cocacola.png",
      name: "CocaCola",
      status: "Active",
    },
    {
      _id: "60f9b0b3e6b3a40015f1a3a2",
      cover: "/Images/cocacola.png",
      name: "CocaCola",
      status: "Active",
    },
    {
      _id: "60f9b0b3e6b3a40015f1a3a3",
      cover: "/Images/cocacola.png",
      name: "CocaCola",
      status: "Active",
    },
    {
      _id: "60f9b0b3e6b3a40015f1a3a4",
      cover: "/Images/cocacola.png",
      name: "CocaCola",
      status: "Active",
    },
    {
      _id: "60f9b0b3e6b3a40015f1a3a5",
      cover: "/Images/cocacola.png",
      name: "CocaCola",
      status: "Active",
    },
    {
      _id: "60f9b0b3e6b3a40015f1a3a6",
      cover: "/Images/cocacola.png",
      name: "CocaCola",
      status: "Active",
    },
  ];

  // console.log(active);
  //   useEffect(() => {
  //   const accept = async (id, money) => {
  //     try {
  //       const { data } = await axios.post(
  //         "https://backend-vsal.onrender.com/api/order/" + id,
  //         { money: money },
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: Bearer ${localStorage.getItem("token")},
  //           },
  //         }
  //       );
  //       setData(data);
  //       console.log("Admin2s list",data);
  //     } catch (error) {
  //       console.log(error);
  //       if (error?.status === 404) {
  //         toast.error("Please recharge your wallet");
  //       }
  //       toast.error("Something went wrong");
  //     }
  //   };
  //   accept();
  // }, []);

  const [selectedOption, setSelectedOption] = useState("Year");
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const getCurrentMonth = () => {
    const currentMonthIndex = new Date().getMonth();
    return currentMonthIndex;
  };

  const datachYear = [
    { year: getCurrentYear() - 3, uv: 120, pv: 2400, amt: 2400 },
    { year: getCurrentYear() - 2, uv: 280, pv: 1000, amt: 1200 },
    { year: getCurrentYear() - 1, uv: 380, pv: 1800, amt: 1200 },
    { year: getCurrentYear(), uv: 150, pv: 2000, amt: 1200 },
  ];

  const datachMonth = [
    { month: months[getCurrentMonth() - 3], uv: 120, pv: 2400, amt: 2400 },
    { month: months[getCurrentMonth() - 2], uv: 280, pv: 1000, amt: 1200 },
    { month: months[getCurrentMonth() - 1], uv: 380, pv: 1800, amt: 1200 },
    { month: months[getCurrentMonth()], uv: 150, pv: 2000, amt: 1200 },
  ];

  const [influencerPostsData, setInfluencerPostsData] = useState([]);
  const { loading } = useSelector((state) => state.loading);
  const influencerId = useParams().id10;
  const [influencerDashboardData, setInfluencerDashboardData] = useState({});
  const dispatch = useDispatch();
  const fetchInfluencerPosts = async () => {
    try {
      dispatch(showLoading());
      const response = await ApiInterceptor.get(
        `postsInfluencers/allPosts/${influencerId}`
      );
      setInfluencerPostsData(response?.data);
      // console.log(response?.data);
      toast.success("Posts fetched successfully");
      dispatch(showLoading());
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
      dispatch(hideLoading());
    } finally {
      dispatch(hideLoading());
    }
  };
  useEffect(() => {
    fetchInfluencerPosts();
  }, [influencerId]);

  const fetchInnfluncerDashboardData = async () => {
    try {
      const response = await ApiInterceptor.get(
        `postsInfluencers/influencerRecord/${influencerId}`
      );
      // console.log(response?.data);
      setInfluencerDashboardData(response?.data);
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    fetchInnfluncerDashboardData();
  }, [influencerId]);

  const [graphData, setGraphData] = useState([]);
  const [graphFilter, setGraphFilter] = useState("Days");
  const [graphFilterSelectedVal, setGraphFilterSelectedVal] = useState(7);

  const fetchGraphData = async () => {
    try {
      const response = await ApiInterceptor.get(
        `postsInfluencers/record/${influencerId}?${graphFilter}=${graphFilterSelectedVal}`
      );
      // setGraphData(response?.data);
      // console.log(response?.data, "response data of the graph");
      const processedData = response?.data.map((item) => ({
        Date: item.Date,
        purchase_value: item.purchase_value,
      }));
      setGraphData(processedData);
    } catch (error) {
      toast.error(error?.response?.data?.message || "something went wrong");
    }
  };
  useEffect(() => {
    fetchGraphData();
  }, [graphFilter, graphFilterSelectedVal]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate()}/${date.getMonth() + 1}/${
      date.getFullYear() % 100
    }`;
  };

  return (
    <div className="p-[20px] mr-[10px] md:p-2 space-y-[20px] ">
      <div className="flex lg:flex-row flex-col lg:space-y-0 space-y-[10px] 8k:w-[115vh] 4k:w-[77vw]  8k:w-[280vh] 3xl:mt-[1.5vw] lgk:pr-[2vw]">
        {/* graph  */}
        {/* <div
          className="xl:w-[400px] lg:w-[300px] lg:ml-[10px] lg:flex flex-col justify-center px-[20px] py-[10px] bg-white 8k:w-[60vh] 8k:p-3 8k:mt-9 4k:w-[55vw] 4k:mt-9 3xl:w-[60%] rk:py-[1vw]
          1440k:h-[29vw]  lgk:w-[32vw] 2xl:w-[100%]"
        > */}
        <div className="xl:w-[450px] lg:w-[250px] lg:ml-[10px] lg:flex flex-col justify-center px-[20px] py-[10px] bg-white 8k:w-[60vh] 8k:p-3 8k:mt-9 4k:w-[55vw] 4k:mt-9 3xl:w-[60%] rk:py-[1vw] 1440k:h-[29vw] lgk:w-[32vw] 2xl:w-[60%] border border-[#a7a7a7] rounded-lg shadow-lg hover:shadow-xl transition duration-300">
          <div className="flex justify-between items-center">
            <h1 className="text-black font-extrabold text-md md:text-lg mr-[10px] 8k:text-3xl 8k:font-bold 4k:text-xl 4k:font-bold 3xl:text-[1.3vw] 3xl:font-bold rk:text-[1vw] rk:font-bold 1440k:font-bold lgk:font-bold lgk:text-[1.2vw] ">
              Insights of Influencer
            </h1>
            {/* <div className=" mb-[10px] text-[#D9D9D9] w-fit border rounded-full flex justify-between space-x-[20px] 8k:text-xl 4k:text-lg mobile:w-[15vh] mobile:h-[4vh] mobile:text-xs lgk:text-[1vw] lgk:w-[10vw] lgk:px-0 ">
              <select
                className="w-full rounded-full py-2 pl-6 focus:outline-none mobile:p-1 lgk:px-[0.5vw] "
                value={selectedOption}
                onChange={handleSelectChange}
              >
                <option value="Year">Year</option>
                <option value="Month">Month</option>
              </select>
            </div> */}
            <div className=" mb-[10px] w-fit border rounded-full flex justify-between space-x-[20px] 8k:text-xl 4k:text-lg mobile:w-[15vh] mobile:h-[4vh] mobile:text-xs lgk:text-[1vw] lgk:w-[10vw] lgk:px-0 ">
              <select
                className="w-full rounded-full py-2 pl-6 focus:outline-none mobile:p-1 lgk:px-[0.5vw] bg-blue-500 text-white"
                value={graphFilter}
                onChange={(e) => setGraphFilter(e.target.value)}
              >
                <option value="Days">Days</option>
                <option value="Weeks">Weeks</option>
                <option value="Months">Months</option>
                <option value="Years">Years</option>
              </select>
              <input
                type="number"
                value={graphFilterSelectedVal}
                onChange={(e) => setGraphFilterSelectedVal(e.target.value)}
                className="w-20 rounded-full py-2 pl-2 pr-6 focus:outline-none text-black"
              />
            </div>
          </div>

          {/* <div className="graph  md:w-full w-[99%] 3xl:h-[100%]  4k:h-[100%] 1440k:h-[100%] lgk:h-[100%] lgk:w-[100%] "> */}
          {/* <ResponsiveContainer>
            {selectedOption === "Year" ? (
              <LineChart data={datachYear}>
                <Line
                  type="monotone"
                  dataKey="uv"
                  stroke="#8884d8"
                  strokeWidth={8}
                />
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <YAxis />
                <Tooltip />
                <XAxis dataKey={"year"} />
              </LineChart>
            ) : (
              <LineChart data={datachMonth}>
                <Line
                  type="monotone"
                  dataKey="uv"
                  stroke="#8884d8"
                  strokeWidth={8}
                />
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <YAxis />
                <Tooltip />
                <XAxis dataKey={"month"} />
              </LineChart>
            )}
          </ResponsiveContainer> */}

          <ResponsiveContainer width="100%" height={320}>
            <LineChart>
              <Line
                type="monotone"
                dataKey="purchase_value"
                stroke="#8884d8"
                strokeWidth={2}
                data={graphData}
                label="Purchase Value"
              />
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              {/* <YAxis /> */}
              <YAxis
                ticks={[0, 4000, 8000, 12000, 14000, 16000]}
                interval="preserveStartEnd"
              >
                <Label
                  angle={-90}
                  value="Purchase Value"
                  position="insideLeft"
                  style={{ textAnchor: "middle" }}
                />
              </YAxis>
              {/* <Tooltip labelFormatter={(value) => formatDate(value)} /> */}
              <XAxis
                dataKey="Date"
                type="category"
                tickFormatter={(dateStr) =>
                  new Date(dateStr).toLocaleDateString()
                }
              >
                <Label
                  value="Date"
                  position="insideBottom"
                  style={{ textAnchor: "middle" }}
                />
              </XAxis>
              <Tooltip
                labelFormatter={(value) => new Date(value).toLocaleDateString()}
              />
            </LineChart>
          </ResponsiveContainer>
          {/* <Line options={options} data={data} /> */}
          {/* </div> */}
        </div>
        {/* section  */}
        {/* <div className="xl:w-[484px] space-y-[25px] w-full lg:w-[350px] flex flex-col mb-[20px] lg:mb-0 px-[10px] pb-[20px] lg:pb-0 8k:w-[60vh] 4k:w-[40vw]  3xl:w-[40%]  3xl:h-[25vw]  4k:space-y-[1vw] 4k:ml-[1vw] rk:w-[100%]"> */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 ml-3">
          {/* section-1 */}
          {/* <div className="flex flex-col lg:flex-row space-y-[20px] lg:space-y-0 4k:w-[26vw] 4k:mt-[2vw]  8k:ml-[2vh] 8k:mt-[2vh] 8k:h-[12vh]   3xl:text-[1.3vw] 3xl:h-[8vw]  lgk:text-[1vw] lgk:w-[40vw]"> */}
          {/* 1card  */}
          {/* <div className="flex flex-col p-4 w-full h-full lg:h-full lg:w-[60%] bg-white 1440k:w-[30vw] 1440k:h-[9vw]  3xl:w-[50%] rk:w-[60%] lgk:w-[40vw] lgk:h-[10vw]">
              <div className="flex justify-between">
                <img
                  className="w-5 h-5 8k:w-[3vh] 8k:h-[3vh]"
                  src="/Images/influencer_main.png"
                  alt="increase"
                />
                <div className="flex items-center">
                  <img
                    className="w-5 h-5 mr-[5px] 8k:w-[3vh] 8k:h-[2vh] "
                    src="/Images/increment.png"
                    alt="increment"
                  />
                  <h1 className="text-green-500 8k:text-2xl">+10 %</h1>
                </div>
              </div>
              <div className="mt-[10px] mb-[10px] text-lg font-extrabold text-black 8k:text-3xl 8k:mt-7 1440k:text-[1.2vw] lgk:text-[1.2vw]  lgk:mb-[0]">
                8.4K+
              </div>
              <div className="text-[#A0A0A0] font-bold text-xs lg:text-xs 8k:text-3xl 8k:mt-4 3xl:text-[1vw] 1440k:text-[1vw] lgk:text-[1.2vw] ">
                Approved Influencers
              </div>
            </div> */}
          <InfoCard
            iconImage={"/Images/influencer_main.png"}
            incre_decre_img={"/Images/increment.png"}
            incre_decre_count={"10"}
            dash_data={influencerDashboardData?.total_orders_completed || 0}
            dash_name={"Total Orders Completed"}
          />
          {/* 2 card  */}
          {/* <div className="flex flex-col p-4 lg:ml-[20px] bg-white w-full h-full lg:h-full lg:w-[60%] 1440k:w-[30vw] 1440k:h-[9vw] 3xl:w-[50%] rk:w-[60%] lgk:w-[40vw] lgk:h-[10vw]">
              <div className="flex justify-between">
                <img
                  className="w-5 h-5 8k:w-[3vh] 8k:h-[3vh]"
                  src="/Images/click.png"
                  alt="increase"
                />
                <div className="flex items-center">
                  <img
                    className="w-5 h-5 mr-[5px] 8k:w-[2vh] 8k:h-[2vh]"
                    src="/Images/decrement.png"
                    alt="increment"
                  />
                  <h1 className="text-red-500 8k:text-2xl">-10 %</h1>
                </div>
              </div>
              <div className="text-lg font-extrabold text-black mt-[10px] mb-[10px] 8k:text-3xl 8k:mt-7 1440k:text-[1.2vw] lgk:text-[1.2vw] lgk:mb-[0]">
                2.4K+
              </div>
              <div className="text-[#A0A0A0] font-bold text-xs lg:text-xs 8k:text-3xl 8k:mt-4 3xl:text-[1vw] 1440k:text-[1vw]  lgk:text-[1.2vw]">
                clicks
              </div>
            </div> */}
          <InfoCard
            iconImage={"/Images/click.png"}
            incre_decre_img={"/Images/decrement.png"}
            incre_decre_count={"10"}
            dash_data={influencerDashboardData?.total_clicks || 0}
            dash_name={"Clicks"}
          />
          {/* </div> */}

          {/* <div className="flex mt-[10px] flex-col space-y-[10px] lg:space-y-0  4k:w-[26vw] lg:flex-row  8k:ml-[2vh] 8k:my-[5vh]  8k:h-[12vh] 3xl:text-[1.3vw] 3xl:h-[8vw] lgk:w-[40vw]"> */}
          {/* 3rd card  */}
          {/* <div className="flex flex-col p-4 w-full h-full lg:h-full lg:w-[60%] bg-white 1440k:w-[30vw] 1440k:h-[9vw]  3xl:w-[50%] rk:w-[60%] lgk:w-[40vw] lgk:h-[10vw]">
              <div className="flex justify-between">
                <img
                  className="w-5 h-5 8k:w-[3vh] 8k:h-[3vh]"
                  src="/Images/sales.png"
                  alt="increase"
                />
                <div className="flex items-center">
                  <img
                    className="w-5 h-5 mr-[5px] 8k:w-[3vh] 8k:h-[2vh]"
                    src="/Images/increment.png"
                    alt="increment"
                  />
                  <h1 className="text-green-500 8k:text-2xl">+10 %</h1>
                </div>
              </div>
              <div className="mt-[10px] mb-[10px] text-lg font-extrabold text-black 8k:text-3xl 8k:mt-7 1440k:text-[1.2vw] lgk:text-[1.2vw] lgk:mb-[0]">
                8.4K+
              </div>
              <div className="text-[#A0A0A0] font-bold text-xs lg:text-xs 8k:text-3xl 8k:mt-4 3xl:text-[1vw] 1440k:text-[1vw]  lgk:text-[1.2vw]">
                Total spent
              </div>
            </div> */}
          <InfoCard
            iconImage={"/Images/sales.png"}
            incre_decre_img={"/Images/increment.png"}
            incre_decre_count={"10"}
            dash_data={influencerDashboardData?.total_campaigns_completed || 0}
            dash_name={"Total Campaings Completed"}
          />
          {/* 4 card  */}
          {/* <div className="flex flex-col p-4 lg:ml-[20px] bg-white w-full h-full lg:h-full lg:w-[60%] 1440k:w-[30vw] 1440k:h-[9vw] 3xl:w-[50%] rk:w-[60%] lgk:w-[40vw] lgk:h-[10vw]">
              <div className="flex justify-between">
                <img
                  className="w-5 h-5 8k:w-[3vh] 8k:h-[3vh]"
                  src="/Images/influencer_main.png"
                  alt="increase"
                />
                <div className="flex items-center">
                  <img
                    className="w-5 h-5 mr-[5px] 8k:w-[2vh] 8k:h-[2vh]"
                    src="/Images/decrement.png"
                    alt="increment"
                  />
                  <h1 className="text-red-500 8k:text-2xl">-10 %</h1>
                </div>
              </div>
              <div className="mt-[10px] mb-[10px] text-lg font-extrabold text-black 8k:text-3xl 8k:mt-7 1440k:text-[1.2vw] lgk:text-[1.2vw] lgk:mb-[0]">
                2.4K+
              </div>
              <div className="text-[#A0A0A0] font-bold text-xs lg:text-xs 8k:text-3xl 8k:mt-4 3xl:text-[1vw] 1440k:text-[1vw]  lgk:text-[1.2vw]">
                Pending Influencers
              </div>
            </div> */}
          <InfoCard
            iconImage={"/Images/influencer_main.png"}
            incre_decre_img={"/Images/decrement.png"}
            incre_decre_count={"10"}
            dash_data={influencerDashboardData?.total_add_to_cart || 0}
            dash_name={"Total Add To Cart"}
          />
          {/* </div> */}

          {/* <div className="flex mt-[10px] flex-col space-y-[10px] lg:space-y-0 4k:w-[26vw] lg:flex-row 8k:ml-[2vh] 8k:mt-[2vh]  8k:h-[12vh]  lgk:w-[40vw] 3xl:text-[1.3vw] 3xl:h-[8vw]"> */}
          {/* 5 card  */}
          {/* <div className="flex flex-col p-4 w-full h-full lg:h-full lg:w-[60%] bg-white 1440k:w-[30vw] 1440k:h-[9vw] 3xl:w-[50%] rk:w-[60%] lgk:w-[40vw] lgk:h-[10vw]">
              <div className="flex justify-between">
                <img
                  className="w-5 h-5 8k:w-[3vh] 8k:h-[3vh]"
                  src="/Images/reach.png"
                  alt="increase"
                />
                <div className="flex items-center">
                  <img
                    className="w-5 h-5 mr-[5px] 8k:w-[3vh] 8k:h-[2vh]"
                    src="/Images/increment.png"
                    alt="increment"
                  />
                  <h1 className="text-green-500 8k:text-2xl">+10 %</h1>
                </div>
              </div>
              <div className="mt-[10px] mb-[10px] text-lg font-extrabold text-black 8k:text-3xl 8k:mt-7 1440k:text-[1.2vw] lgk:text-[1.2vw] lgk:mb-[0]">
                8.4K+
              </div>
              <div className="text-[#A0A0A0] font-bold text-xs lg:text-xs 8k:text-3xl 8k:mt-4 3xl:text-[1vw] 1440k:text-[1vw]  lgk:text-[1.2vw]">
                Potential reach
              </div>
            </div> */}
          <InfoCard
            iconImage={"/Images/reach.png"}
            incre_decre_img={"/Images/increment.png"}
            incre_decre_count={"10"}
            dash_data={influencerDashboardData?.total_purchase_value || 0}
            dash_name={"Total Purchase Value"}
          />
          {/* 6 card  */}
          {/* <div className="flex flex-col p-4 lg:ml-[20px] bg-white w-full h-full lg:h-full lg:w-[60%] 1440k:w-[30vw] 1440k:h-[9vw]  3xl:w-[50%] rk:w-[60%] lgk:w-[40vw] lgk:h-[10vw]">
              <div className="flex justify-between">
                <img
                  className="w-5 h-5 8k:w-[3vh] 8k:h-[3vh]"
                  src="/Images/social_main.png"
                  alt="increase"
                />
                <div className="flex items-center">
                  <img
                    className="w-5 h-5 mr-[5px] 8k:w-[2vh] 8k:h-[2vh]"
                    src="/Images/decrement.png"
                    alt="increment"
                  />
                  <h1 className="text-red-500 8k:text-2xl">-10 %</h1>
                </div>
              </div>
              <div className="mt-[10px] mb-[10px] text-lg font-extrabold text-black 8k:text-3xl 8k:mt-7 1440k:text-[1.2vw] lgk:text-[1.2vw] lgk:mb-[0]">
                2.4K+
              </div>
              <div className="text-[#A0A0A0] font-bold text-xs lg:text-xs 8k:text-3xl 8k:mt-4 3xl:text-[1vw] 1440k:text-[1vw]  lgk:text-[1.2vw]">
                Total Posts
              </div>
            </div> */}
          {/* </div> */}
        </div>
      </div>
      <div>
        <h1 className="text-3xl text-gray-500">Total Posts</h1>
      </div>
      <div className="grid lg:grid-cols-3 gap-[70px] md:grid-cols-2 grid-cols-1 8k:grid-cols-6  3xl:grid-cols-5 3xl:gap-[1px] 4k:grid-cols-4 ">
        {/* <Posted
          image={"img.png"}
          i={"facebook.png"}
          name={"Gareth Rodrigues"}
        />
        <Posted image={"img.png"} i={"youtube.png"} name={"Gareth Rodrigues"} />
        <Posted image={"img.png"} i={"insta.png"} name={"Gareth Rodrigues"} />
        <Posted
          image={"img.png"}
          i={"facebook.png"}
          name={"Gareth Rodrigues"}
        />
        <Posted image={"img.png"} i={"youtube.png"} name={"Gareth Rodrigues"} />
        <Posted image={"img.png"} i={"insta.png"} name={"Gareth Rodrigues"} /> */}

        {/* {loading ? (
          <div className="flex flex-row items-center justify-center h-[50vh]">
            <h1 className="flex row items-center justify-center text-3xl text-black">
              Loading Data..........
              <span>
                <SpinnerCircular speed={300} color="blue" />
              </span>
            </h1>
          </div>
        ) : (
          influencerPostsData?.map((post) => (
            <PostPageOfInfluencer key={post?._id} {...post} />
          ))
        )} */}

        {loading ? (
          <div className="flex flex-row items-center justify-center h-[50vh]">
            <h1 className="flex row items-center justify-center text-3xl text-black">
              Loading Data..........
              <span>
                <SpinnerCircular speed={300} color="blue" />
              </span>
            </h1>
          </div>
        ) : influencerPostsData?.length === 0 ? (
          <div className="flex flex-row items-center justify-center ">
            <h1 className="flex row items-center justify-center text-3xl text-black">
              No Post made by the influencer.......
            </h1>
          </div>
        ) : (
          influencerPostsData.map((post) => (
            <PostPageOfInfluencer key={post?._id} {...post} />
          ))
        )}
      </div>
    </div>
  );
}

export default AllPosts_Admin;

export const InfoCard = ({
  iconImage,
  incre_decre_img,
  incre_decre_count,
  dash_data,
  dash_name,
}) => {
  return (
    <div className="bg-white rounded-lg shadow-lg hover:shadow-xl transition duration-300">
      <div className="p-6">
        <div className="flex justify-between mb-4">
          <img src={iconImage} alt="Icon" className="w-8 h-8" />
          <div className="flex items-center">
            <img
              src={incre_decre_img}
              alt={dash_name}
              className="w-4 h-4 mr-2"
            />
            <span className="text-green-500">{incre_decre_count}%</span>
          </div>
        </div>
        <h1 className="text-2xl font-bold text-gray-500 mb-4">
          {dash_data || 0}
        </h1>
        <p className="text-sm text-gray-600">{dash_name}</p>
      </div>
    </div>
  );
};
