import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  FaImage,
  FaEnvelope,
  FaCalendarAlt,
  FaDollarSign,
  FaCog,
} from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import ApiInterceptor from "../../utils/ApiInterceptor";

const AdminRefundDetailforMarketPlace = () => {
  const params = useParams();
  const id = params.id;

  const [data, setData] = useState({});
  const [showEditRequests, setShowEditRequests] = useState(false);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiInterceptor.get(
          `admin/refunds/getRefundsData/${id}`
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [id]);

  const handleProcessRefund = async () => {
    try {
      setLoading("process");
      const response = await ApiInterceptor.post(
        `admin/refunds/update/refund/${id}`
      );
      if (response?.status === 200) {
        setLoading(null);
        toast.success("Refund successfully processed!");
      }
    } catch (error) {
      setLoading(null);
      toast.error("Refund already processed or error occurred!");
    }
  };

  const handleRejectRefund = async () => {
    try {
      setLoading("reject");
      const response = await ApiInterceptor.post(
        `admin/refunds/cancel/refund/${id}`
      );
      if (response?.status === 200) {
        setLoading(null);
        toast.error("Refund request rejected!");
      }
    } catch (error) {
      setLoading(null);
      toast.error("Refund already cancelled or error occurred!");
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg font-sans">
      <ToastContainer />
      <h1 className="text-3xl font-bold text-gray-800 text-center mb-6">
        Admin Refund Detail
      </h1>

      {/* Influencer Details */}
      <section className="mb-6 p-6 bg-gray-100 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4 flex items-center">
          <FaImage className="text-blue-500 text-xl mr-3" /> Influencer Details
        </h2>
        <div className="flex flex-wrap gap-6">
          <div className="flex-1 min-w-[290px] bg-white p-4 rounded-lg shadow-md">
            <img
              src={data?.influencer_id?.package_image_url}
              alt="Influencer"
              className="w-full h-64 object-cover rounded-lg mb-4"
            />
            <p>
              <strong>Name:</strong> {data?.influencer_id?.name}
            </p>
            <p>
              <strong>Email:</strong> {data?.influencer_id?.email}
            </p>
            <p>
              <strong>City:</strong> {data?.influencer_id?.description}
            </p>
            <div className="mt-4">
              <p>
                <strong>Categories:</strong>
              </p>
              <div className="flex flex-wrap gap-2">
                {data?.influencer_id?.categories
                  ?.split(",")
                  .map((category, index) => (
                    <span
                      key={index}
                      className="bg-blue-500 text-white px-3 py-1 rounded-full text-sm"
                    >
                      {category.trim()}
                    </span>
                  ))}
              </div>
            </div>
          </div>
          <div className="flex-1 min-w-[290px] bg-white p-4 rounded-lg shadow-md">
            <p>
              <strong>Description:</strong> {data?.influencer_id?.description}
            </p>
          </div>
        </div>
      </section>

      {/* Business Details */}
      <section className="mb-6 p-6 bg-gray-100 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4 flex items-center">
          <FaEnvelope className="text-blue-500 text-xl mr-3" /> Business Details
        </h2>
        <div className="bg-white p-4 rounded-lg shadow-md">
          <p>
            <strong>Name:</strong> {data?.business_id?.name}
          </p>
          <p>
            <strong>Username:</strong> {data?.business_id?.username}
          </p>
          <p>
            <strong>Company:</strong> {data?.business_id?.company}
          </p>
          <p>
            <strong>Status:</strong> {data?.business_id?.status}
          </p>
          <p>
            <strong>Email:</strong> {data?.business_id?.email}
          </p>
          <p>
            <strong>Phone:</strong> {data?.business_id?.phone}
          </p>
        </div>
      </section>

      {/* Marketplace Details */}
      {data?.marketplace_id && (
        <section className="mb-6 p-6 bg-gray-100 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4 flex items-center">
            <FaCalendarAlt className="text-blue-500 text-xl mr-3" /> Marketplace
            Details
          </h2>
          <div className="bg-white p-4 rounded-lg shadow-md">
            <p>
              <strong>Product Name:</strong>{" "}
              {data?.marketplace_id?.product_name}
            </p>
            <p>
              <strong>Product Price:</strong> $
              {data?.marketplace_id?.product_price}
            </p>
            <p>
              <strong>Campaign Tracking Type:</strong>{" "}
              {data?.marketplace_id?.campaign_tracking_type}
            </p>
            <p>
              <strong>Instructions:</strong> {data?.marketplace_id?.instruction}
            </p>
            <p>
              <strong>Order Expiry Date:</strong>{" "}
              {new Date(
                data?.marketplace_id?.orderExpireDate
              ).toLocaleDateString()}
            </p>
            <div className="mt-4">
              <p>
                <strong>Cover Images:</strong>
              </p>
              <div className="flex gap-4">
                {data?.marketplace_id?.cover_image_url
                  ?.filter((url) => url)
                  .map((url, index) => (
                    <img
                      key={index}
                      src={url}
                      alt={`Cover ${index}`}
                      className="w-32 h-32 object-cover rounded-lg"
                    />
                  ))}
              </div>
            </div>
          </div>
        </section>
      )}

      {/* Influencer Post Details */}
      <section className="mb-6 p-6 bg-gray-100 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4 flex items-center">
          <FaDollarSign className="text-blue-500 text-xl mr-3" /> Influencer
          Post Details
        </h2>
        <div className="bg-white p-4 rounded-lg shadow-md">
          <div className="relative w-full h-64 overflow-hidden rounded-lg border border-gray-300 mb-4">
            <img
              src={data?.influencerMarketPlacePost_id?.posts?.[0]?.postUrl?.replace(
                "https://www.youtube.com/shorts/",
                "https://img.youtube.com/vi/"
              )}
              alt="Influencer Post"
              className="w-full h-full object-cover"
            />
          </div>
          <p>
            <strong>Status:</strong>{" "}
            {data?.influencerMarketPlacePost_id?.postStatus === 1
              ? "Approved"
              : "Requested for Refund"}
          </p>
          <p>
            <strong>Post URL:</strong>{" "}
            <a
              href={
                data?.influencerMarketPlacePost_id?.posts?.[
                  data?.influencerMarketPlacePost_id?.posts.length - 1
                ]?.postUrl
              }
              className="text-blue-500 hover:underline"
            >
              {data?.influencerMarketPlacePost_id?.posts?.[0]?.postUrl}
            </a>
          </p>

          {/* Edit Requests */}
          {data?.influencerMarketPlacePost_id?.editRequests?.rejectionReasons
            ?.length > 0 && (
            <div className="mt-6 bg-gray-50 p-4 rounded-lg shadow-md">
              <button
                onClick={() => setShowEditRequests(!showEditRequests)}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg mb-4"
              >
                {showEditRequests ? "Hide Edit Requests" : "Show Edit Requests"}
              </button>
              {showEditRequests && (
                <div>
                  <p className="text-lg font-semibold mb-2">
                    Edit Requests (
                    {data?.influencerMarketPlacePost_id?.editRequests?.count})
                  </p>
                  <ul className="list-disc pl-5">
                    {data?.influencerMarketPlacePost_id?.editRequests?.rejectionReasons.map(
                      (reason, index) => (
                        <li
                          key={index}
                          className="bg-white p-2 rounded-lg shadow-sm mb-1"
                        >
                          {reason || "No reason provided"}
                        </li>
                      )
                    )}
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>
      </section>

      {/* Action Buttons */}
      <section className="flex justify-end gap-4">
        <button
          onClick={handleRejectRefund}
          className="bg-red-500 text-white px-6 py-2 rounded-lg hover:bg-red-600 disabled:opacity-50"
          disabled={loading === "reject"}
        >
          {loading === "reject" ? "Rejecting..." : "Reject Refund Request"}
        </button>
        <button
          onClick={handleProcessRefund}
          className="bg-green-500 text-white px-6 py-2 rounded-lg hover:bg-green-600 disabled:opacity-50"
          disabled={loading === "process"}
        >
          {loading === "process" ? "Processing..." : "Process Refund Request"}
        </button>
      </section>
    </div>
  );
};

export default AdminRefundDetailforMarketPlace;
