import React, { useState } from "react";
import LockResetIcon from "@mui/icons-material/LockReset";
import { Button, TextField } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import axios from "axios";
import { useParams } from "react-router-dom";
import ApiInterceptor from "../utils/ApiInterceptor";

const ResetPassword = () => {
  const { id } = useParams();

  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);

  const [password, setPassword] = useState("");
  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      if (urlParams.get('app')) {
        await ApiInterceptor.post(
          `influencer/resetPassword?id=${id}`,
          {
            password,
          }
        );
      }
      else {
         await ApiInterceptor.post(
          `company/resetPassword?id=${id}`,
          {
            password,
          }
        );
      }


      alert("password reset successful");

      // Handle the response data as needed (redirect, display a message, etc.)
    } catch (error) {

      alert(error);
      // Handle the error (display an error message, etc.)
    }
  };
  return (
    <>
      <div className="">
        {/* color box starts */}
        <div className=" h-[35vh] bg-primary"></div>
        {/* color box ends */}
        <div className="-mt-24   df text-center">
          <div className="border w-[35vw] bg-white border-[#c1c1c1] shadow-xl px-12   pt-10 pb-10">
            <div className="df">
              <LockResetIcon
                style={{ fontSize: "80px", textAlign: "center" }}
              />
            </div>
            <h1 className="text-center font-bold text-xl pt-[10px]">
              Reset Password
            </h1>
            <p className="text-center py-2">Enter your New password</p>
            <form onSubmit={submitHandler} className="df flex-col gap-6 py-4">
              <TextField
                id="outlined-basic"
                label="Enter Your New Password"
                variant="outlined"
                style={{ width: "100%" }}
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                style={{ width: "100%", backgroundColor: "green" }}
                variant="contained"
                type="submit"
              >
                Submit
              </Button>
            </form>

            {!urlParams.get("app")&&<div className="pt-4">
              <Link to={"/login"} className="text-center">
                <ArrowBackIosNewIcon style={{ fontSize: "20px" }} /> Back to
                Login
              </Link>
            </div>}
          </div>
        </div>
      </div>
    </>
  );
};
export default ResetPassword;
