import React, { useEffect, useState } from "react";
import Campaign2 from "../Component/Campaign/Campaign2";
import Affiliate2 from "../Component/Affiliate/Affiliate2";
import MainAdmin from "../Component/admin/Main_Admin";
import Setting from "../Component/Setting";
import Formaffiliate from "../Component/Affiliate/Formaffiliate";
import toast from "react-hot-toast";
import { logout } from "../redux/Slice/userSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SinglePage from "./SinglePage";
// import SingleCampaign from "../Component/SingleCampaign";
import Affiliatehome from "../Component/Affiliate/Affilicatehome";
import Cookies from "js-cookie";
import InfluencersAdmin from "../Component/admin/InfluencerAdmin/Influencers_Admin";
import CampaignAdmin from "../Component/admin/CampaignAdmin/Campaign_Admin";
import BusinessesAdmin from "../Component/admin/Businesses_Admin";
import AllPostsAdmin from "../Component/admin/AllPost/AllPosts_Admin";
import ReportAdmin from "../Component/admin/Report_Admin";
import InfluencerAdmin2 from "../Component/admin/InfluencerAdmin/Influencers_admin2";
import InfluencerAdmin3 from "../Component/admin/InfluencerAdmin/Influencers_admin3";
import AllPost2 from "../Component/admin/AllPost/AllPost2_Admin";
import CampaignAdmin2 from "../Component/admin/CampaignAdmin/Campaign_admin2";
import CampaignAdmin3 from "../Component/admin/CampaignAdmin/Campaign_admin3";
import AffiliateCampaignsAdmin from "../Component/admin/AffiliateCampaignsAdmin/AffiliateCampaigns";
import AffiliateCampaignDetails from "../Component/admin/AffiliateCampaignsAdmin/AffiliateCampaignDetails";
import BusinessTransaction from "../Component/admin/BusinessTransaction";
import AdminTransaction from "../Component/admin/AdminTransaction";
import AdminRevenue from "../Component/admin/AdminRevenue";
import { updateAdminStatus } from "../redux/Slice/AdminSlice";
import IndividualAffiliateCampaign from "../Component/admin/AffiliateCampaignsAdmin/IndividualAffiliateCampaign";
// import Affiliatehome from "../Component/Affilicatehome";
function AdminHome() {
  const [dashBoard, setDashBoard] = useState(true);
  const [campaigns, setCampaigns] = useState(false);
  const [affiliateCampaigns, setAffiliateCampaigns] = useState(false);
  const [influencers, setInfluencers] = useState(false);
  const [businesses, setBusinesses] = useState(false);
  const [transactions, setTransactions] = useState(false);
  const [revenue, setRevenue] = useState(false);
  const [allPosts, setAllPosts] = useState(false);
  const [report, setReport] = useState(false);
  const [setting, setSetting] = useState(false);
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const {
    id1,
    id2,
    id3,
    id4,
    id5,
    id6,
    id7,
    id8,
    id9,
    id10,
    id11,
    id12,
    id13,
    id20,
  } = useParams();
  // useEffect(() => {
  //   if (Cookies.get("token")) {
  //     console.log("Cookies ", Cookies.get("token"));
  //   } else {
  //     navigate("/login");
  //   }
  // }, []);

  const { isAdmin } = useSelector((state) => state.admin);
  useEffect(() => {
    if (!isAdmin) {
      navigate("/login");
    } else {
      navigate("/admin");
    }
  }, [isAdmin]);

  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(logout());
    dispatch(updateAdminStatus(false));
    Cookies.remove("email");
    Cookies.remove("username");
    Cookies.remove("token");
    navigate("/login");
    toast.success("Logout successfully ");
  };
  return (
    // complete admin p[anel ui box]
    <div className=" flex font-Poppins ">
      {/* complete left sidebar */}
      <div className=" lg:w-[20%] hidden lg:flex flex-col bg-white justify-between p-[20px] 8k:h-screen ">
        <div className=" start ">
          <div className="  flex items-center">
            <img
              className=" w-9 h-9 8k:w-[5vh] 8k:h-[5vh] 3xl:w-[2.5vw] 3xl:h-[2.5vw]"
              src="/Images/logo.png"
              alt="logo"
            />
            <h1 className="ml-[20px] font-bold 8k:text-3xl 3xl:text-[1.2vw] ">
              Cloutin Admin
            </h1>
          </div>
          <div className="space-y-[20px] mt-[40px] 8k:space-y-[40px] 8k:mt-[80px] 3xl:mt-[2vw]">
            {/* dash  */}
            <div
              className={
                dashBoard
                  ? "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF] 8k:p-3 3xl:h-[2.5vw]"
                  : "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 8k:p-3 3xl:h-[2.5vw] transition duration-300 ease-in-out hover:bg-sky-100"
              }
              onClick={() => {
                setDashBoard(true);
                setRevenue(false);
                setCampaigns(false);
                setInfluencers(false);
                setBusinesses(false);
                setAllPosts(false);
                setTransactions(false);
                setReport(false);
                setSetting(false);
                setAffiliateCampaigns(false);
                navigate("/admin");
              }}
            >
              <img
                className="w-6 h-6 8k:w-9 8k:h-9 3xl:h-[1.5vw] 3xl:w-[1.5vw] "
                src={
                  dashBoard ? "/Images/dashboard.png" : "/Images/dashboard2.png"
                }
                alt="logo"
              />
              <h1
                className={
                  dashBoard
                    ? "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-blue-500 8k:text-3xl 8k:p-3 3xl:text-[1vw] 3xl:text-[1vw]"
                    : "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-[#b2aeae] 8k:text-3xl 8k:p-3 3xl:text-[1vw] 3xl:text-[1vw]"
                }
              >
                Dashboard
              </h1>
            </div>
            {/* market  */}

            <div
              className={
                campaigns
                  ? "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF] 8k:p-3 3xl:h-[2.5vw]"
                  : "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 8k:p-3 3xl:h-[2.5vw] transition duration-300 ease-in-out hover:bg-sky-100"
              }
              onClick={() => {
                setDashBoard(false);
                setTransactions(false);
                setRevenue(false);
                setCampaigns(true);
                setInfluencers(false);
                setBusinesses(false);
                setAllPosts(false);
                setReport(false);
                setAffiliateCampaigns(false);
                setSetting(false);
                navigate("/admin");
              }}
            >
              <img
                className="w-6 h-6 8k:w-9 8k:h-9 3xl:h-[1.5vw] 3xl:w-[1.5vw]"
                src={
                  campaigns
                    ? "/Images/marketplace.png"
                    : "/Images/marketplace(2).png"
                }
                alt="logo"
              />
              <h1
                className={
                  !campaigns
                    ? "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-[#b2aeae] 8k:text-3xl 8k:p-3 3xl:text-[1vw]"
                    : "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-blue-500 8k:text-3xl 8k:p-3 3xl:text-[1vw]"
                }
              >
                Campaigns
              </h1>
            </div>

            <div
              className={
                affiliateCampaigns
                  ? "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF] 8k:p-3 3xl:h-[2.5vw]"
                  : "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 8k:p-3 3xl:h-[2.5vw] transition duration-300 ease-in-out hover:bg-sky-100"
              }
              onClick={() => {
                setDashBoard(false);
                setCampaigns(false);
                setAffiliateCampaigns(true);
                setInfluencers(false);
                setBusinesses(false);
                setAllPosts(false);
                setReport(false);
                setSetting(false);
                navigate("/admin");
              }}
            >
              <img
                className="w-6 h-6 8k:w-9 8k:h-9 3xl:h-[1.5vw] 3xl:w-[1.5vw]"
                src={
                  affiliateCampaigns
                    ? "/Images/marketplace.png"
                    : "/Images/marketplace(2).png"
                }
                alt="logo"
              />
              <h1
                className={
                  !affiliateCampaigns
                    ? "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-[#b2aeae] 8k:text-3xl 8k:p-3 3xl:text-[1vw]"
                    : "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-blue-500 8k:text-3xl 8k:p-3 3xl:text-[1vw]"
                }
              >
                Affiliate Campaigns
              </h1>
            </div>
            {/* campaign  */}
            <div
              className={
                !influencers
                  ? "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 8k:text-3xl 3xl:h-[2.5vw] transition duration-300 ease-in-out hover:bg-sky-100"
                  : "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF] 8k:text-3xl 3xl:h-[2.5vw]"
              }
              onClick={() => {
                setDashBoard(false);
                setTransactions(false);
                setRevenue(false);
                setCampaigns(false);
                setInfluencers(true);
                setBusinesses(false);
                setAllPosts(false);
                setReport(false);
                setAffiliateCampaigns(false);
                setSetting(false);
                navigate("/admin");
              }}
            >
              <img
                className="w-6 h-6 8k:w-9 8k:h-9 3xl:h-[1.5vw] 3xl:w-[1.5vw]"
                src={!influencers ? "/Images/campaign.png" : "Images/camp.png"}
                alt="logo"
              />
              <h1
                className={
                  !influencers
                    ? "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-[#b2aeae] 8k:text-3xl 8k:p-3 3xl:text-[1vw]"
                    : "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-blue-500 8k:text-3xl 8k:p-3 3xl:text-[1vw]"
                }
              >
                Influencers
              </h1>
            </div>
            {/* wallet  */}
            <div
              className={
                !businesses
                  ? "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 8k:p-3 3xl:h-[2.5vw] transition duration-300 ease-in-out hover:bg-sky-100"
                  : "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF] 8k:p-3 3xl:h-[2.5vw]"
              }
              onClick={() => {
                setDashBoard(false);
                setCampaigns(false);
                setRevenue(false);
                setInfluencers(false);
                setBusinesses(true);
                setAllPosts(false);
                setTransactions(false);
                setAffiliateCampaigns(false);
                setReport(false);
                setSetting(false);
                navigate("/admin");
              }}
            >
              <img
                className="w-6 h-6 8k:w-9 8k:h-9 3xl:h-[1.5vw] 3xl:w-[1.5vw]"
                src={
                  businesses ? "/Images/wallet(2).png" : "/Images/wallet.png"
                }
                alt="logo"
              />
              <h1
                className={
                  !businesses
                    ? " xl:text-[14px] ml-[22px] w-[80%] text-xs xl:w-[80%] text-[#b2aeae] 8k:text-3xl 8k:p-3 3xl:text-[1vw]"
                    : "xl:text-[14px] ml-[22px] w-[80%] text-xs xl:w-[80%] text-blue-500 8k:text-3xl 8k:p-3 3xl:text-[1vw]"
                }
              >
                Businesses
              </h1>
            </div>

            <div
              className={
                !transactions
                  ? "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 8k:p-3 3xl:h-[2.5vw] transition duration-300 ease-in-out hover:bg-sky-100"
                  : "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF] 8k:p-3 3xl:h-[2.5vw]"
              }
              onClick={() => {
                setDashBoard(false);
                setCampaigns(false);
                setInfluencers(false);
                setBusinesses(false);
                setTransactions(true);
                setRevenue(false);
                setAllPosts(false);
                setAffiliateCampaigns(false);
                setReport(false);
                setSetting(false);
                navigate("/admin");
              }}
            >
              <img
                className="w-6 h-6 8k:w-9 8k:h-9 3xl:h-[1.5vw] 3xl:w-[1.5vw]"
                src={
                  transactions
                    ? "https://static.vecteezy.com/system/resources/previews/000/290/969/non_2x/transaction-vector-icon.jpg"
                    : "https://static.vecteezy.com/system/resources/previews/000/290/969/non_2x/transaction-vector-icon.jpg"
                }
                alt="logo"
              />
              <h1
                className={
                  !transactions
                    ? " xl:text-[14px] ml-[22px] w-[80%] text-xs xl:w-[80%] text-[#b2aeae] 8k:text-3xl 8k:p-3 3xl:text-[1vw]"
                    : "xl:text-[14px] ml-[22px] w-[80%] text-xs xl:w-[80%] text-blue-500 8k:text-3xl 8k:p-3 3xl:text-[1vw]"
                }
              >
                Transactions
              </h1>
            </div>

            <div
              className={
                !revenue
                  ? "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 8k:p-3 3xl:h-[2.5vw] transition duration-300 ease-in-out hover:bg-sky-100"
                  : "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF] 8k:p-3 3xl:h-[2.5vw]"
              }
              onClick={() => {
                setDashBoard(false);
                setCampaigns(false);
                setInfluencers(false);
                setBusinesses(false);
                setTransactions(false);
                setRevenue(true);
                setAllPosts(false);
                setAffiliateCampaigns(false);
                setReport(false);
                setSetting(false);
                navigate("/admin");
              }}
            >
              <img
                className="w-6 h-6 8k:w-9 8k:h-9 3xl:h-[1.5vw] 3xl:w-[1.5vw]"
                src={
                  revenue
                    ? "https://media.istockphoto.com/id/1332254909/vector/growth-profit-icon-vector-business-and-finance-concept-for-graphic-design-logo-web-site.jpg?s=612x612&w=0&k=20&c=oDKkkH3TtRzsPcteUhOexQLW1SdxFF-7-ji_txeQ1uk="
                    : "https://media.istockphoto.com/id/1332254909/vector/growth-profit-icon-vector-business-and-finance-concept-for-graphic-design-logo-web-site.jpg?s=612x612&w=0&k=20&c=oDKkkH3TtRzsPcteUhOexQLW1SdxFF-7-ji_txeQ1uk="
                }
                alt="logo"
              />
              <h1
                className={
                  !revenue
                    ? " xl:text-[14px] ml-[22px] w-[80%] text-xs xl:w-[80%] text-[#b2aeae] 8k:text-3xl 8k:p-3 3xl:text-[1vw]"
                    : "xl:text-[14px] ml-[22px] w-[80%] text-xs xl:w-[80%] text-blue-500 8k:text-3xl 8k:p-3 3xl:text-[1vw]"
                }
              >
                Revenue
              </h1>
            </div>
            {/* report  */}
            <div
              className={
                !allPosts
                  ? "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 8k:p- 3xl:h-[2.5vw] transition duration-300 ease-in-out hover:bg-sky-100"
                  : "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF] 8k:p-3 3xl:h-[2.5vw]"
              }
              onClick={() => {
                setDashBoard(false);
                setCampaigns(false);
                setInfluencers(false);
                setBusinesses(false);
                setAllPosts(true);
                setTransactions(false);
                setReport(false);
                setRevenue(false);
                setAffiliateCampaigns(false);
                setSetting(false);
                navigate("/admin");
              }}
            >
              <img
                className="w-6 h-6 8k:w-9 8k:h-9 3xl:h-[1.5vw] 3xl:w-[1.5vw]"
                src={allPosts ? "/Images/report(1).png" : "/Images/report.png"}
                alt="logo"
              />
              <h1
                className={
                  !allPosts
                    ? " xl:text-[14px] ml-[22px] w-[80%] text-xs xl:w-[80%] text-[#b2aeae] 8k:text-3xl 8k:p-3 3xl:text-[1vw]"
                    : "xl:text-[14px] ml-[22px] w-[80%] text-xs xl:w-[80%] text-blue-500 8k:text-3xl 8k:p-3 3xl:text-[1vw]"
                }
              >
                All Posts
              </h1>
            </div>
            {/* affiliate  */}
            <div
              className={
                !report
                  ? "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 8k:p- 3xl:h-[2.5vw] transition duration-300 ease-in-out hover:bg-sky-100"
                  : "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF] 8k:p-3 3xl:h-[2.5vw]"
              }
              onClick={() => {
                setDashBoard(false);
                setCampaigns(false);
                setInfluencers(false);
                setBusinesses(false);
                setAllPosts(false);
                setTransactions(false);
                setRevenue(false);
                setReport(true);
                setSetting(false);
                setAffiliateCampaigns(false);
                navigate("/admin");
              }}
            >
              <img
                className="w-6 h-6 8k:w-9 8k:h-9 3xl:h-[1.5vw] 3xl:w-[1.5vw]"
                src={
                  report ? "/Images/affiliate(1).png" : "/Images/affiliate.png"
                }
                alt="logo"
              />
              <h1
                className={
                  !report
                    ? " xl:text-[14px] ml-[22px] w-[80%] text-xs xl:w-[80%] text-[#b2aeae] 8k:text-3xl 8k:p-3 3xl:text-[1vw]"
                    : "xl:text-[14px] ml-[22px] w-[80%] text-xs xl:w-[80%] text-blue-500 8k:text-3xl 8k:p-3 3xl:text-[1vw]"
                }
              >
                Report
              </h1>
            </div>
          </div>
        </div>

        <div className=" end border border-x-0 border-b-0 pt-[100px]  ">
          {/* end above  */}
          <div
            className={
              !setting
                ? "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 8k:p-3 transition duration-300 ease-in-out hover:bg-sky-200"
                : "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF] 8k:p-3"
            }
            onClick={() => {
              setDashBoard(true);
              setCampaigns(false);
              setInfluencers(false);
              setTransactions(false);
              setBusinesses(false);
              setRevenue(false);
              setAllPosts(false);
              setReport(false);
              setSetting(true);
              setAffiliateCampaigns(false);
              navigate("/admin");
            }}
          >
            <img
              className="w-6 h-6 8k:w-9 8k:h-9 3xl:h-[1.5vw] 3xl:w-[1.5vw]"
              src={!setting ? "/Images/setting.png" : "/Images/setting2.png"}
              alt="logo"
            />
            <h1
              className={
                !setting
                  ? "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-[#b2aeae] 8k:text-3xl 8k:p-3 3xl:text-[1vw]"
                  : "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-blue-500 8k:text-3xl 8k:p-3 3xl:text-[1vw]"
              }
            >
              Settings
            </h1>
          </div>
          <div
            className="flex cursor-pointer justify-center items-center rounded-lg p-2 mx-4 8k:p-3 transition duration-300 ease-in-out hover:bg-sky-200"
            onClick={logOut}
          >
            <img
              className="w-6 h-6 8k:w-9 8k:h-9 3xl:h-[1.5vw] 3xl:w-[1.5vw]"
              src="/Images/logout.png"
              alt="logo"
            />
            <h1 className="ml-[20px] xl:text-[14px] w-[45%] text-xs xl:w-[80%] text-[#b2aeae] 8k:text-3xl 8k:p-3 3xl:text-[1vw]">
              Logout
            </h1>
          </div>
        </div>
      </div>

      {/* complete left side */}
      <div className=" lg:w-[80%] bg-[#f3f2f2]">
        <div>
          {/* left side top bar */}
          <div className=" flex md:pl-[40px] justify-between bg-white h-[60px]  items-center  8k:h-[7vh] 3xl:h-[4vw]">
            <img
              className="w-5 h-5 mx-[10px] flex lg:hidden"
              src="/Images/hamburger.png"
              alt="ham"
              onClick={() => setVisible(true)}
            />

            <div className="border rounded-lg flex items-center w-[60%] 8k:h-[5vh] ">
              <img
                className="ml-[20px] mr-[10px] h-7 w-7 8k:w-[2vh] 8k:h-[2vh] mobile:w-5 mobile:h-5 3xl:h-[2vw] 3xl:w-[2vw]"
                src="/Images/search.png"
                alt=""
              />
              <input
                className="p-2 w-full rounded-lg focus:outline-none  8k:text-3xl mobile:text-xs 3xl:h-[3vw] 3xl:w-[100%] 3xl:text-[1.2vw]"
                type="text"
                placeholder="Search you Influencers"
              />
            </div>
            {/* search section  */}
            <div className="flex items-center space-x-5 mr-[30px] mobile:justify-around xsm:space-x-1 ">
              {/* profile  */}
              <div className="flex items-center">
                <img
                  className="w-9 h-9 rounded-full mr-[5px] 8k:h-[5vh] 8k:w-[5vh] 3xl:h-[3vw] 3xl:w-[3vw]"
                  src="/Images/pro1.png"
                  alt="profile"
                />
                <h1 className="text-xs md:text-lg mr-[20px] capitalize">
                  Admin
                </h1>
              </div>
            </div>
          </div>
          {!id1 &&
            !id2 &&
            !id3 &&
            !id4 &&
            !id5 &&
            !id6 &&
            !id7 &&
            !id8 &&
            !id9 &&
            !id10 &&
            !id11 &&
            !id12 &&
            !id13 &&
            !id20 && (
              <div>
                {dashBoard && (
                  <div className="py-[20px] px-[20px] 2xl:px-[20px] 2xl:py-[0px] h-[calc(100vh-60px)] overflow-y-scroll md:h-[calc(100vh-60px)] lg:h-[calc(100vh-62px)] w-screen lg:w-[100%] lg:px-[10px]  mobile:h-[140vh]">
                    <MainAdmin />
                  </div>
                )}
                {campaigns && (
                  <div className="py-[2px] 2xl:px-[30px] h-[calc(100vh-60px)] overflow-y-scroll md:h-[calc(100vh-60px)] lg:h-[calc(100vh-62px)] w-screen lg:w-[100%] px-[20px] lg:px-[40px] 1440k:px-[20px] 4k:items-center">
                    <CampaignAdmin />
                  </div>
                )}
                {affiliateCampaigns && (
                  <div className="py-[2px] 2xl:px-[30px] h-[calc(100vh-60px)] overflow-y-scroll md:h-[calc(100vh-60px)] lg:h-[calc(100vh-62px)] w-screen lg:w-[100%] px-[20px] lg:px-[40px] 1440k:px-[20px] 4k:items-center">
                    <AffiliateCampaignsAdmin />
                  </div>
                )}
                {influencers && (
                  <div className="py-[2px] 2xl:px-[30px] h-[calc(100vh-60px)] overflow-y-scroll md:h-[calc(100vh-60px)] lg:h-[calc(100vh-62px)] w-screen lg:w-[100%] px-[20px] lg:px-[40px] 1440k:px-[20px] 4k:items-center">
                    <InfluencersAdmin />
                  </div>
                )}
                {businesses && (
                  <div className="py-[20px] 2xl:px-[140px] h-[calc(100vh-60px)] overflow-y-scroll md:h-[calc(100vh-60px)] lg:h-[calc(100vh-62px)] w-screen lg:w-[100%] px-[20px] lg:px-[40px] 8k:px-[90px]">
                    <BusinessesAdmin />
                  </div>
                )}
                {transactions && (
                  <div className="py-[20px] 2xl:px-[140px] h-[calc(100vh-60px)] overflow-y-scroll md:h-[calc(100vh-60px)] lg:h-[calc(100vh-62px)] w-screen lg:w-[100%] px-[20px] lg:px-[40px] 8k:px-[90px]">
                    <AdminTransaction />
                  </div>
                )}
                {revenue && (
                  <div className="py-[20px] 2xl:px-[140px] h-[calc(100vh-60px)] overflow-y-scroll md:h-[calc(100vh-60px)] lg:h-[calc(100vh-62px)] w-screen lg:w-[100%] px-[20px] lg:px-[40px] 8k:px-[90px]">
                    <AdminRevenue />
                  </div>
                )}

                {allPosts && (
                  <div className="py-[2px] 2xl:px-[30px] h-[calc(100vh-60px)] overflow-y-scroll md:h-[calc(100vh-60px)] lg:h-[calc(100vh-62px)] w-screen lg:w-[100%] px-[20px] lg:px-[40px] 1440k:px-[20px] 4k:items-center">
                    <AllPostsAdmin />
                  </div>
                )}
                {report && (
                  <div className="py-[20px] h-[calc(100vh-60px)] overflow-y-scroll md:h-[calc(100vh-60px)] lg:h-[calc(100vh-62px)] w-screen lg:w-[100%] px-[20px] lg:px-[40px]   8k:h-[90vh] ">
                    <ReportAdmin />
                  </div>
                )}
                {setting && (
                  <div className=" overflow-y-scroll  py-[20px] h-[calc(100vh-60px)] w-screen flex justify-center lg:w-[100%] md:px-[20px] lg:px-[40px]">
                    <Setting />
                  </div>
                )}
              </div>
            )}
          {id1 && (
            <div className="py-[20px] 2xl:px-[100px] h-[calc(100vh-60px)] overflow-y-scroll md:h-[calc(100vh-60px)] lg:h-[calc(100vh-62px)] w-screen lg:w-[100%] px-[20px] lg:px-[40px] 8k:px-[50px] 4k:px-[50px] ">
              <SinglePage />
            </div>
          )}
          {id2 && (
            <div className="py-[20px] 2xl:px-[20px] h-[calc(100vh-60px)] overflow-y-scroll md:h-[calc(100vh-60px)] lg:h-[calc(100vh-62px)] w-screen lg:w-[100%] px-[20px] lg:px-[40px]">
              <Campaign2 />
            </div>
          )}
          {id3 && (
            <div className="py-[20px] 2xl:px-[20px] h-[calc(100vh-60px)] overflow-y-scroll md:h-[calc(100vh-60px)] lg:h-[calc(100vh-62px)] w-screen lg:w-[100%] px-[20px] md:px-[20px] lg:px-[180px] 8k:px-[30vw] 3xl:px-[23vw] 4k:px-[20vw] rk:px-[20vw] 1440k:px-[15vw] lgk:px-[11vw] ">
              <Formaffiliate />
            </div>
          )}
          {id4 && (
            <div className="py-[20px] 2xl:px-[20px] h-[calc(100vh-60px)] overflow-y-scroll md:h-[calc(100vh-60px)] lg:h-[calc(100vh-62px)] w-screen lg:w-[100%] px-[20px] md:px-[20px] lg:px-[180px] 8k:px-[30vw] 3xl:px-[23vw] 4k:px-[20vw] rk:px-[20vw] 1440k:px-[15vw] lgk:px-[11vw] ">
              <Affiliatehome />
            </div>
          )}
          {id5 && (
            <div className="py-[20px] 2xl:px-[20px] h-[calc(100vh-60px)] overflow-y-scroll md:h-[calc(100vh-60px)] lg:h-[calc(100vh-62px)] w-screen lg:w-[100%] px-[20px] md:px-[20px] lg:px-[180px] 8k:px-[30vw] 3xl:px-[23vw] 4k:px-[20vw] rk:px-[20vw] 1440k:px-[15vw] lgk:px-[11vw] ">
              <Affiliate2 />
            </div>
          )}
          {id6 && (
            <div className="py-[2px] 2xl:px-[30px] h-[calc(100vh-60px)] overflow-y-scroll md:h-[calc(100vh-60px)] lg:h-[calc(100vh-62px)] w-screen lg:w-[100%] px-[20px] lg:px-[40px] 1440k:px-[20px] 4k:items-center">
              <CampaignAdmin2 />
            </div>
          )}
          {id7 && (
            <div className="py-[2px] 2xl:px-[30px] h-[calc(100vh-60px)] overflow-y-scroll md:h-[calc(100vh-60px)] lg:h-[calc(100vh-62px)] w-screen lg:w-[100%] px-[20px] lg:px-[40px] 1440k:px-[20px] 4k:items-center ">
              <CampaignAdmin3 />
            </div>
          )}
          {id8 && (
            <div className="py-[2px] 2xl:px-[30px] h-[calc(100vh-60px)] overflow-y-scroll md:h-[calc(100vh-60px)] lg:h-[calc(100vh-62px)] w-screen lg:w-[100%] px-[20px] lg:px-[40px] 1440k:px-[20px] 4k:items-center">
              <InfluencerAdmin2 />
            </div>
          )}
          {id9 && (
            <div className="py-[2px] 2xl:px-[30px] h-[calc(100vh-60px)] overflow-y-scroll md:h-[calc(100vh-60px)] lg:h-[calc(100vh-62px)] w-screen lg:w-[100%] px-[20px] lg:px-[40px] 1440k:px-[20px] 4k:items-center">
              <BusinessTransaction />
            </div>
          )}
          {id10 && (
            <div className="py-[2px] 2xl:px-[30px] h-[calc(100vh-60px)] overflow-y-scroll md:h-[calc(100vh-60px)] lg:h-[calc(100vh-62px)] w-screen lg:w-[100%] px-[20px] lg:px-[40px] 1440k:px-[20px] 4k:items-center">
              <AllPost2 />
            </div>
          )}
          {id11 && (
            <div className="py-[2px] 2xl:px-[30px] h-[calc(100vh-60px)] overflow-y-scroll md:h-[calc(100vh-60px)] lg:h-[calc(100vh-62px)] w-screen lg:w-[100%] px-[20px] lg:px-[40px] 1440k:px-[20px] 4k:items-center">
              <AffiliateCampaignsAdmin />
            </div>
          )}
          {id12 && (
            <div className="py-[2px] 2xl:px-[30px] h-[calc(100vh-60px)] overflow-y-scroll md:h-[calc(100vh-60px)] lg:h-[calc(100vh-62px)] w-screen lg:w-[100%] px-[20px] lg:px-[40px] 1440k:px-[20px] 4k:items-center">
              <AffiliateCampaignDetails />
            </div>
          )}
          {id13 && (
            <div className="py-[2px] 2xl:px-[30px] h-[calc(100vh-60px)] overflow-y-scroll md:h-[calc(100vh-60px)] lg:h-[calc(100vh-62px)] w-screen lg:w-[100%] px-[20px] lg:px-[40px] 1440k:px-[20px] 4k:items-center">
              <BusinessTransaction />
            </div>
          )}
          {id20 && (
            <div className="py-[2px] 2xl:px-[30px] h-[calc(100vh-60px)] overflow-y-scroll md:h-[calc(100vh-60px)] lg:h-[calc(100vh-62px)] w-screen lg:w-[100%] px-[20px] lg:px-[40px] 1440k:px-[20px] 4k:items-center">
              <IndividualAffiliateCampaign />
            </div>
          )}
          {/* id === "SingleCampaign" */}
        </div>
      </div>

      {/* 
          Responsive navbar 
    */}

      {visible && (
        <div className="overflow-y-scroll left-0 top-0 fixed z-50 w-full h-screen bg-black/70 mobile:h-[150vh] ">
          <div className="flex flex-col p-2 top-0 left-0 w-[75%] h-full md:w-[45%] bg-white text-black mobile:w-[60]">
            <div className="flex mt-4 w-full items-center">
              <div className="flex ml-[30px] justify-between w-full items-center">
                <div className="flex items-center">
                  <img className="w-9 h-9" src="/Images/logo.png" alt="logo" />
                  <h1 className="ml-[20px] text-black font-bold">Collabb</h1>
                </div>
                {/* close button  */}
                <div
                  onClick={() => setVisible(!visible)}
                  className="flex cursor-pointer text-black/60 justify-center lg:hidden"
                >
                  {visible && (
                    <img
                      className="w-5 h-5 mr-[10px] cursor-pointer"
                      src="/Images/cross.png"
                      alt="arr"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-between h-full items-center mt-12 mobile:w-[40vh] mobile:mt-1">
              <div className="flex flex-col justify-between mt-2 h-full space-y-[50px]  items-center">
                <div className="start flex flex-col justify-between h-full ">
                  <div className="space-y-[20px] mt-[40px]">
                    {/* create section  */}
                    <div
                      className={
                        dashBoard
                          ? "flex cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF] 8k:p-5"
                          : "flex cursor-pointer items-center rounded-lg p-2 mx-4 8k:p-5"
                      }
                      onClick={() => {
                        setDashBoard(true);
                        setCampaigns(false);
                        setInfluencers(false);
                        setRevenue(false);
                        setTransactions(false);
                        setBusinesses(false);
                        setAllPosts(false);
                        setAffiliateCampaigns(false);
                        setReport(false);
                        setSetting(false);
                        navigate("/admin");
                      }}
                    >
                      <img
                        className="w-6 h-6"
                        src={
                          dashBoard
                            ? "/Images/dashboard.png"
                            : "/Images/dashboard2.png"
                        }
                        alt="logo"
                      />
                      <h1
                        className={
                          dashBoard
                            ? "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-blue-500"
                            : "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-[#b2aeae]"
                        }
                      >
                        Dashboard
                      </h1>
                    </div>
                    {/* Campaigns */}
                    <div
                      className={
                        campaigns
                          ? "flex cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF]"
                          : "flex cursor-pointer items-center rounded-lg p-2 mx-4"
                      }
                      onClick={() => {
                        setDashBoard(false);
                        setCampaigns(true);
                        setInfluencers(false);
                        setBusinesses(false);
                        setAllPosts(false);
                        setRevenue(false);
                        setTransactions(false);
                        setReport(false);
                        setAffiliateCampaigns(false);
                        setSetting(false);
                        navigate("/admin");
                      }}
                    >
                      <img
                        className="w-6 h-6"
                        src={
                          campaigns
                            ? "/Images/marketplace.png"
                            : "Images/marketplace(2).png"
                        }
                        alt="logo"
                      />
                      <h1
                        className={
                          !campaigns
                            ? "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-[#b2aeae]"
                            : "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-blue-500"
                        }
                      >
                        Campaigns
                      </h1>
                    </div>
                    {/* Affiliate Campaigns */}
                    <div
                      className={
                        affiliateCampaigns
                          ? "flex cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF]"
                          : "flex cursor-pointer items-center rounded-lg p-2 mx-4"
                      }
                      onClick={() => {
                        setDashBoard(false);
                        setCampaigns(false);
                        setAffiliateCampaigns(true);
                        setInfluencers(false);
                        setBusinesses(false);
                        setAllPosts(false);
                        setRevenue(false);
                        setTransactions(false);
                        setAffiliateCampaigns(false);
                        setReport(false);
                        setSetting(false);
                        navigate("/admin");
                      }}
                    >
                      <img
                        className="w-6 h-6"
                        src={
                          affiliateCampaigns
                            ? "/Images/marketplace.png"
                            : "Images/marketplace(2).png"
                        }
                        alt="logo"
                      />
                      <h1
                        className={
                          !affiliateCampaigns
                            ? "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-[#b2aeae]"
                            : "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-blue-500"
                        }
                      >
                        Affiliate Campaigns
                      </h1>
                    </div>

                    <div
                      className={
                        !influencers
                          ? "flex cursor-pointer items-center rounded-lg p-2 mx-4"
                          : "flex cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF]"
                      }
                      onClick={() => {
                        setDashBoard(false);
                        setCampaigns(false);
                        setInfluencers(true);
                        setBusinesses(false);
                        setAllPosts(false);
                        setReport(false);
                        setRevenue(false);
                        setTransactions(false);
                        setAffiliateCampaigns(false);
                        setSetting(false);
                        navigate("/admin");
                      }}
                    >
                      <img
                        className="w-6 h-6"
                        src={
                          !influencers
                            ? "/Images/campaign.png"
                            : "Images/camp.png"
                        }
                        alt="logo"
                      />
                      <h1
                        className={
                          !influencers
                            ? "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-[#b2aeae]"
                            : "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-blue-500"
                        }
                      >
                        Influencers
                      </h1>
                    </div>
                    <div
                      className={
                        !businesses
                          ? "flex cursor-pointer items-center rounded-lg p-2 mx-4"
                          : "flex cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF]"
                      }
                      onClick={() => {
                        setDashBoard(false);
                        setCampaigns(false);
                        setInfluencers(false);
                        setBusinesses(true);
                        setRevenue(false);
                        setTransactions(false);
                        setAllPosts(false);
                        setReport(false);
                        setSetting(false);
                        setAffiliateCampaigns(false);
                        navigate("/admin");
                      }}
                    >
                      <img
                        className="w-6 h-6"
                        src={
                          businesses
                            ? "/Images/wallet(2).png"
                            : "/Images/wallet.png"
                        }
                        alt="logo"
                      />
                      <h1
                        className={
                          !businesses
                            ? "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-[#b2aeae]"
                            : "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-blue-500"
                        }
                      >
                        Businesses
                      </h1>
                    </div>

                    <div
                      className={
                        !transactions
                          ? "flex cursor-pointer items-center rounded-lg p-2 mx-4"
                          : "flex cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF]"
                      }
                      onClick={() => {
                        setDashBoard(false);
                        setCampaigns(false);
                        setInfluencers(false);
                        setBusinesses(false);
                        setRevenue(false);
                        setTransactions(true);
                        setAllPosts(false);
                        setReport(false);
                        setSetting(false);
                        setAffiliateCampaigns(false);
                        navigate("/admin");
                      }}
                    >
                      <img
                        className="w-6 h-6"
                        src={
                          transactions
                            ? "https://static.vecteezy.com/system/resources/previews/000/290/969/non_2x/transaction-vector-icon.jpg"
                            : "https://static.vecteezy.com/system/resources/previews/000/290/969/non_2x/transaction-vector-icon.jpg"
                        }
                        alt="logo"
                      />
                      <h1
                        className={
                          !transactions
                            ? "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-[#b2aeae]"
                            : "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-blue-500"
                        }
                      >
                        Transactions
                      </h1>
                    </div>

                    <div
                      className={
                        !revenue
                          ? "flex cursor-pointer items-center rounded-lg p-2 mx-4"
                          : "flex cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF]"
                      }
                      onClick={() => {
                        setDashBoard(false);
                        setCampaigns(false);
                        setInfluencers(false);
                        setBusinesses(false);
                        setRevenue(true);
                        setTransactions(false);
                        setAllPosts(false);
                        setReport(false);
                        setSetting(false);
                        setAffiliateCampaigns(false);
                        navigate("/admin");
                      }}
                    >
                      <img
                        className="w-6 h-6"
                        src={
                          revenue
                            ? "https://media.istockphoto.com/id/1332254909/vector/growth-profit-icon-vector-business-and-finance-concept-for-graphic-design-logo-web-site.jpg?s=612x612&w=0&k=20&c=oDKkkH3TtRzsPcteUhOexQLW1SdxFF-7-ji_txeQ1uk="
                            : "https://media.istockphoto.com/id/1332254909/vector/growth-profit-icon-vector-business-and-finance-concept-for-graphic-design-logo-web-site.jpg?s=612x612&w=0&k=20&c=oDKkkH3TtRzsPcteUhOexQLW1SdxFF-7-ji_txeQ1uk="
                        }
                        alt="logo"
                      />
                      <h1
                        className={
                          !revenue
                            ? "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-[#b2aeae]"
                            : "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-blue-500"
                        }
                      >
                        Revenue
                      </h1>
                    </div>

                    <div
                      className={
                        !allPosts
                          ? "flex cursor-pointer items-center rounded-lg p-2 mx-4"
                          : "flex cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF]"
                      }
                      onClick={() => {
                        setDashBoard(false);
                        setCampaigns(false);
                        setInfluencers(false);
                        setBusinesses(false);
                        setAllPosts(true);
                        setReport(false);
                        setRevenue(false);
                        setTransactions(false);
                        setSetting(false);
                        setAffiliateCampaigns(false);
                        navigate("/admin");
                      }}
                    >
                      <img
                        className="w-6 h-6"
                        src={
                          allPosts
                            ? "/Images/report(1).png"
                            : "/Images/report.png"
                        }
                        alt="logo"
                      />
                      <h1
                        className={
                          !allPosts
                            ? "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-[#b2aeae]"
                            : "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-blue-500"
                        }
                      >
                        All Posts
                      </h1>
                    </div>

                    <div
                      className={
                        !report
                          ? "flex cursor-pointer items-center rounded-lg p-2 mx-4"
                          : "flex cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF]"
                      }
                      onClick={() => {
                        setDashBoard(false);
                        setCampaigns(false);
                        setInfluencers(false);
                        setBusinesses(false);
                        setAllPosts(false);
                        setRevenue(false);
                        setTransactions(false);
                        setReport(true);
                        setSetting(false);
                        setAffiliateCampaigns(false);
                        navigate("/admin");
                      }}
                    >
                      <img
                        className="w-6 h-6"
                        src={
                          report
                            ? "/Images/affiliate(1).png"
                            : "/Images/affiliate.png"
                        }
                        alt="logo"
                      />
                      <h1
                        className={
                          !report
                            ? "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-[#b2aeae]"
                            : "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-blue-500"
                        }
                      >
                        Report
                      </h1>
                    </div>

                    {/* transfer  */}
                    <div
                      className={
                        !setting
                          ? "flex cursor-pointer items-center rounded-lg p-2 mx-4"
                          : "flex cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF]"
                      }
                      onClick={() => {
                        setDashBoard(true);
                        setCampaigns(false);
                        setInfluencers(false);
                        setRevenue(false);
                        setTransactions(false);
                        setBusinesses(false);
                        setAllPosts(false);
                        setReport(false);
                        setSetting(true);
                        setAffiliateCampaigns(false);
                        navigate("/");
                      }}
                    >
                      <img
                        className="w-6 h-6"
                        src={
                          !setting
                            ? "/Images/setting.png"
                            : "/Images/setting2.png"
                        }
                        alt="logo"
                      />
                      <h1
                        className={
                          !setting
                            ? "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-[#b2aeae]"
                            : "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-blue-500"
                        }
                      >
                        Settings
                      </h1>
                    </div>
                    <div
                      className="flex cursor-pointer justify-center items-center rounded-lg p-2 mx-4"
                      onClick={logOut}
                    >
                      <img
                        className="w-6 h-6"
                        src="/Images/logout.png"
                        alt="logo"
                      />
                      <h1 className="ml-[20px] xl:text-[14px] text-xs w-[100%] text-[#b2aeae] ">
                        Logout
                      </h1>
                    </div>
                    {/* ends  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminHome;
