import React, { useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
// import Cookies from "js-cookie";

import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import ApiInterceptor from "../../utils/ApiInterceptor";
const datach = [
  { name: "Page A", uv: 120, pv: 2400, amt: 2400 },
  { name: "Page B", uv: 280, pv: 1000, amt: 1200 },
  { name: "Page C", uv: 380, pv: 1800, amt: 1200 },
  { name: "Page D", uv: 150, pv: 2000, amt: 1200 },
];

function MainAdmin() {
  // const [optst1, setOptst1] = useState(true);
  // const [optst2, setOptst2] = useState(true);
  // const [optst3, setOptst3] = useState(true);
  // const [optst4, setOptst4] = useState(false);
  // const [optst5, setOptst5] = useState(false);

  useEffect(() => {
    const get = async () => {
      try {
        const data2 = await ApiInterceptor.get(
          "influencer?fields=status=active",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      } catch (error) {
        // toast.error("Something went wrong");
      }
    };
    get();
  }, []);

  const [dashData, setDashData] = useState();
  const fetchDashData = async () => {
    try {
      const res = await ApiInterceptor.get(
        "collabb/admin/campaign/admindashboard"
      );
      setDashData(res?.data);
      toast.success("Data fetched successfully");
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
      return;
    }
  };
  useEffect(() => {
    fetchDashData();
  }, []);

  return (
    <div className=" mobile:overflow-y-scroll p-[20px] lg:h-[calc(100vh-102px)] 3xl:text-2xl   ">
      {/* all cards graphs + boxes  */}
      <div className=" flex lg:flex-row flex-col  items-center xl:justify-between lg:justify-center md:justify-self-auto 3xl:justify-center 1440k:justify-center mobile:flex gap-4 ">
        {/* graph  */}
        <div className="  rounded-lg xl:w-[554px] h-fit lg:ml-[10px] lg:flex flex-col justify-center px-[20px] py-[10px] bg-white lg:w-[50%] lg:h-[340px] 4k:w-[49%] 4k:h-[27vw] 8k:w-[50%] 8k:h-[34vh] 3xl:w-[80%] 3xl:h-[21vw] rk:w-[40vw] 1440k:w-[74%] 1440k:h-[25vw] lgk:w-[90%] lgk:h-[27vw] ">
          <div className="flex justify-between items-center">
            <h1 className="font-bond text-xs md:text-lg   8k:text-3xl 1440k:text-[1.3vw] 1440k:font-bold 3xl:text-[1.3vw]  4k:text-[1.2vw] 4k:font-bold ">
              Amount Spent Over the period of time
            </h1>
            <div className=" mb-[10px] text-[#D9D9D9] w-fit border rounded-full flex justify-between space-x-[20px] 8k:mr-9">
              <select className="w-full rounded-full py-2 pl-6 focus:outline-none 8k:mr-[2.5vh]">
                <option value="Year">Year</option>
                <option value="Month">Month</option>
              </select>
            </div>
          </div>

          {/* <div className="graph  md:w-full w-[99%] 8k:w-[55vh]  4k:w-[37vw] 4k:h-[40vw] 3xl:h-[15vw] 1440k:h-[40vw]  lgk:w-[100%]"> */}
          <ResponsiveContainer>
            <LineChart data={datach}>
              <Line
                type="monotone"
                dataKey="uv"
                stroke="#8884d8"
                strokeWidth={8}
              />
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
            </LineChart>
          </ResponsiveContainer>
          {/* <Line options={options} data={data} />  */}
          {/* </div> */}
        </div>
        {/* 4 cards  */}
        <div className=" md:my-4 xl:w-[484px] space-y-[10px] md:w-[50%] w-full h-fit lg:w-[350px] flex flex-col mb-[20px] lg:mb-0 px-[10px] py-[0px] 8k:justify-center 1440k:justify-center 4k:justify-center rk:justify-center 3xl:w-[100%] 3xl:h-[100%] 1440k:w-[100%] lgk:w-[100%] sm:my-4 ">
          {/* section 1 */}
          <div className="flex flex-col lg:flex-row space-y-[10px] lg:space-y-0 8k:w-[53vh] 4k:w-[37vw] 4k:mb-3 1440k:w-[40vw] 3xl:w-[100%] rk:w-[33vw] rk:text-[1.2vw] lgk:w-[37vw] ">
            {/* 1 card  */}
            <div className="rounded-lg flex flex-col p-4 w-full h-full lg:h-[150px] lg:w-[50%] bg-white xl:h-[150px] 8k:w-[30vh] 8k:h-[16vh] 8k:mb-2 8k:p-9 1440k:h-[12vw] 1440k:w-[25vw] 4k:h-[13vw] 3xl:h-[10vw] rk:h-[11vw] lgk:h-[12vw]">
              <div className="flex justify-between ">
                <img
                  className="w-5 h-5 8k:h-9 8k:w-9 1440k:h-9 1440k:w-9 4k:h-[2vw] 4k:w-[2vw]"
                  src="/Images/followers.png"
                  alt="followers"
                />
                <div className="flex items-center">
                  <img
                    className="w-5 h-5 mr-[5px] 8k:h-[2vh] 8k:w-[3vh] 1440k:h-[2vw] 1440k:w-[2vw] 4k:h-[1.5vw] 4k:w-[2vw]"
                    src="/Images/increment.png"
                    alt="increment"
                  />
                  <h1 className="text-green-500 8k:mt-1 8k:text-3xl 1440k:text-[1.5vw] 1440k:font-bold 3xl:text-[1.3vw] 4k:text-[1vw] ">
                    {dashData?.percentageChangeInTotalFollowers.toFixed(3)}
                  </h1>
                </div>
              </div>
              <div className="mt-[10px] mb-[10px] font-bold 8k:text-3xl 8k:pt-9 1440k:text-[1.5vw] 1440k:pt-[0.5vw]  1440k:font-bold 3xl:pt-[0.5vw] 3xl:text-[1.3vw] 4k:text-[1.3vw] 4k:mt-[1.5vw] rk:pt-[1.2vw] ">
                {dashData?.totalFollowers}
              </div>
              <div className="text-black text-lg  lgk:text-[1.3vw] xl:text-sm 8k:text-2xl 8k:mt-9 8k:text-3xl 1440k:mt-1 1440k:text-[1.3vw] 4k:text-[1.2vw] 3xl:text-[1.3vw] 3xl:pt-[1.5vw] rk:text-[1.2vw] ">
                Total followers reach
              </div>
            </div>
            {/* 2 card  */}
            <div className="rounded-lg flex flex-col p-4 lg:mr-[32px] lg:ml-[10px] bg-white w-full h-full lg:h-full lg:w-[50%] xl:h-[150px] 8k:w-[30vh]  8k:h-[16vh] 8k:mb-2 8k:p-9 1440k:h-[12vw] 1440k:w-[25vw] 4k:h-[13vw] 3xl:h-[10vw] rk:h-[11vw] lgk:h-[12vw] ">
              <div className="flex justify-between">
                <img
                  className="w-5 h-5 8k:h-9 8k:w-9 1440k:h-9 1440k:w-9 4k:h-[2vw] 4k:w-[2vw]"
                  src="/Images/sales.png"
                  alt="sales"
                />
                <div className="flex items-center">
                  <img
                    className="w-5 h-5 mr-[5px] 8k:h-[2vh] 8k:w-[3vh] 1440k:h-[2vw] 1440k:w-[2vw] 4k:h-[1.5vw] 4k:w-[2vw]"
                    src="/Images/decrement.png"
                    alt="increment"
                  />
                  <h1 className="text-red-500 8k:text-3xl 1440k:text-[1.5vw] 1440k:font-bold 3xl:text-[1.3vw] 4k:text-[1vw]">
                    4.2 %
                  </h1>
                </div>
              </div>
              <div className="mt-[15px] mb-[10px] font-bold   8k:text-3xl 8k:pt-9 1440k:text-[1.5vw] 1440k:pt-[0.4vw] 1440k:font-bold 3xl:pt-[0.5vw] 3xl:text-[1.3vw] 4k:text-[1.3vw] 4k:mt-[1.5vw] rk:mt-[1.6vw] ">
                56000
              </div>
              <div className="text-black text-sm lgk:text-[1.3vw]   8k:mt-9 8k:text-3xl 1440k:mt-1 1440k:text-[1.3vw] 4k:text-[1.2vw] 3xl:text-[1.3vw] 3xl:pt-[1.5vw] rk:text-[1.2vw]  ">
                Sales increase weekly
              </div>
            </div>
          </div>
          {/* section 2  */}
          <div className="flex mt-[10px] flex-col space-y-[10px] lg:space-y-0  lg:flex-row 8k:w-[53vh] 1440k:w-[40vw] 4k:w-[37vw] 3xl:w-[100%] rk:w-[33vw] rk:text-[1.2vw] lgk:w-[37vw]">
            {/* 3 card  */}
            <div className=" rounded-lg flex flex-col p-4 w-full h-full lg:h-[150px] lg:w-[50%] bg-white xl:h-[150px] 8k:w-[40vh] 8k:h-[16vh] 8k:mb-2 8k:p-9 1440k:h-[12vw] 1440k:w-[25vw] 4k:h-[13vw] 3xl:h-[10vw] rk:h-[11vw]">
              <div className="flex justify-between ">
                <img
                  className="w-5 h-5 8k:h-9 8k:w-9 1440k:h-9 1440k:w-9 4k:h-[2vw] 4k:w-[2vw]"
                  src="/Images/influencer_main.png"
                  alt="influencer"
                />
                <div className="flex items-center">
                  <img
                    className="w-5 h-5 mr-[5px] 8k:h-[2vh] 8k:w-[3vh] 1440k:h-[3vh] 1440k:w-[3vh] 4k:h-[1.5vw] 4k:w-[2vw]"
                    src="/Images/increment.png"
                    alt="increment"
                  />
                  <h1 className="text-green-500 8k:mt-1 8k:text-3xl 1440k:text-xl 1440k:font-bold 3xl:text-[1.3vw] 4k:text-[1vw] ">
                    {dashData?.percentageChangeInInfluencers?.toFixed(3)}
                  </h1>
                </div>
              </div>
              <div className="mt-[10px] mb-[10px] font-bold 8k:text-3xl 8k:pt-9 1440k:text-[1.5vw] 1440k:pt-[0.5vw] 1440k:font-bold 3xl:pt-[0.5vw] 3xl:text-[1.3vw] 4k:text-[1.3vw] 4k:mt-[1.5vw] rk:mt-[1.5vw]">
                {dashData?.totalInfluencers}
              </div>
              <div className="text-black text-lg lgk:text-[1.3vw]  xl:text-sm 8k:text-2xl 8k:mt-9 8k:text-3xl 1440k:mt-1 1440k:text-[1.3vw] 4k:text-[1.2vw] 3xl:text-[1.3vw] 3xl:pt-[1.5vw] rk:text-[1.2vw]">
                Total Influencers
              </div>
            </div>
            {/* 4 card  */}
            <div className=" rounded-lg flex flex-col p-4 lg:ml-[10px] lg:mr-[32px] bg-white w-full h-full lg:h-[150px] lg:w-[50%] xl:h-[150px] 8k:w-[40vh]  8k:h-[16vh] 8k:mb-2 8k:p-9 1440k:h-[12vw] 1440k:w-[25vw] 4k:h-[13vw] 3xl:h-[10vw] rk:h-[11vw]">
              <div className="flex justify-between">
                <img
                  className="w-5 h-5 8k:h-9 8k:w-9 1440k:h-9 1440k:w-9 4k:h-[2vw] 4k:w-[2vw]"
                  src="/Images/roi.png"
                  alt="roi"
                />
                <div className="flex items-center">
                  <img
                    className="w-5 h-5 mr-[5px] 8k:h-[2vh] 8k:w-[3vh] 1440k:h-[3vh] 1440k:w-[3vh] 4k:h-[1.5vw] 4k:w-[2vw]"
                    src="/Images/decrement.png"
                    alt="increment"
                  />
                  <h1 className="text-red-500 8k:text-3xl 1440k:text-xl 1440k:font-bold 3xl:text-[1.3vw] 4k:text-[1vw]">
                    1.9 %
                  </h1>
                </div>
              </div>
              <div className="mt-[15px] mb-[10px] font-bold   8k:text-3xl 8k:pt-9 1440k:text-[1.5vw] 1440k:pt-[0.4vw] 1440k:font-bold 3xl:pt-[0.5vw] 3xl:text-[1.3vw] 4k:text-[1.3vw] 4k:mt-[1.5vw] rk:mt-[1.5vw]">
                240000
              </div>
              <div className="text-black text-sm lgk:text-[1.3vw]  8k:text-3xl 8k:mt-9 1440k:mt-1 1440k:text-[1.3vw] 4k:text-[1.2vw] 3xl:text-[1.3vw] 3xl:pt-[1.5vw] rk:text-[1.2vw]">
                Return on investment
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainAdmin;
