import React, { useState, useEffect } from "react";
import ApiInterceptor from "../utils/ApiInterceptor";
import { config } from "../config";
const YOUR_AUTHORIZATION_ENDPOINT =
  "https://accounts.google.com/o/oauth2/v2/auth";

const YouTubeChannelInfo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [statistics, setStatistics] = useState(null);
  const YOUR_CLIENT_ID =
    "657817757163-bsa4doaa4j468jkm9gvk35bbe9lsfbvk.apps.googleusercontent.com";
  // const YOUR_REDIRECT_URL = "https://app.cloutin.co";
  const YOUR_REDIRECT_URL = config.REACT_APP_BACKEND_SERVICE_URL;

  const handleAuthorization = () => {
    const responseType = "code";
    const redirectUri = encodeURIComponent(YOUR_REDIRECT_URL);
    const scope = encodeURIComponent(
      "https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/youtube.force-ssl"
    );
    const state = encodeURIComponent("try_sample_request");

    const authorizationUrl = `${YOUR_AUTHORIZATION_ENDPOINT}?client_id=${YOUR_CLIENT_ID}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}&response_type=${responseType}`;

    window.location.href = authorizationUrl;
  };

  const handleOAuthCallback = async () => {
    setIsLoading(true);

    const urlParams = new URLSearchParams(window.location.search);
    const authorizationCode = urlParams.get("code");

    try {
      const response = await ApiInterceptor.get(
        `/youtube/oauth2callback?code=${authorizationCode}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setStatistics(data);
      } else {
        console.error("Failed to retrieve channel statistics");
      }
    } catch (error) {
      console.error("An error occurred during the OAuth callback:", error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (window.location.search.includes("code")) {
      handleOAuthCallback();
    }
  }, []);

  return (
    <div style={{ backgroundColor: "white" }}>
      {statistics ? (
        <div>
          <h3>Channel Statistics</h3>
          <p>Subscriber Count: {statistics.subscriberCount}</p>
          <p>View Count: {statistics.viewCount}</p>
          <p>Like Count: {statistics.likeCount}</p>
          <p>Comment Count: {statistics.commentCount}</p>
        </div>
      ) : (
        <div>
          <h3>Authorization Required</h3>
          <p>Please authorize this app to access your YouTube account.</p>
          <button onClick={handleAuthorization}>Authorize</button>
        </div>
      )}

      {isLoading && <p>Loading...</p>}
    </div>
  );
};

export default YouTubeChannelInfo;
