import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SpinnerCircular } from "spinners-react";
import toast from "react-hot-toast";
import { loginError, loginStart, loginSuccess } from "../redux/Slice/userSlice";
import {
  getUserAccountStatus,
  setUserAccountStatus,
} from "../redux/Slice/UserAccounStatusSlice";
import OverlayDisableAccountPage from "../Pages/OverlayDisableAccountPage";
import ApiInterceptor from "../utils/ApiInterceptor";

function Setting() {
  const { currentUser } = useSelector((state) => state.user);

  const [selectedImg, setSelectedImg] = useState("");
  const [profile, setProfile] = useState("");
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [campany, setCampany] = useState("");
  const [url, setUrl] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [userData, setUserData] = useState(null);
  const [image, setImage] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  const dispatch = useDispatch();
  const [isEditMode, setIsEditMode] = useState(false);
  const [fileInputState, setFileInputState] = useState(""); //Image
  const [invites, setInvites] = useState([]);
  const userid = Cookies.get("userid");
  const { userAccountStatus } = useSelector((state) => state.userAccountStatus);
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get("token")}`,
    },
  };

  useEffect(() => {
    const companyId = Cookies.get("userid");
    if (companyId) {
      dispatch(getUserAccountStatus({ companyId: companyId })).then(
        (response) => {
          dispatch(setUserAccountStatus(response?.payload));
        }
      );
    }
  }, [dispatch]);
  const checkIfEmailExistsInCompanyDatabase = async (email) => {
    const res = await ApiInterceptor.get("users", config);
    const companyEmails = res.data.map((user) => user.email);
    return companyEmails.includes(email);
  };
  const fetchUserData = async () => {
    try {
      setLoading(true);
      const { data } = await ApiInterceptor.get(
        `currentUser?userid=${userid}`,
        config
      );
      const updatedInvitesPromises = data.invitedUsers.map(async (invite) => {
        const isEmailInCompanyDatabase =
          await checkIfEmailExistsInCompanyDatabase(invite.email);
        // invite.status = isEmailInCompanyDatabase ? "Accepted" : "Pending";
        invite.status = isEmailInCompanyDatabase && "Pending";
        return invite;
      });

      // Use Promise.all to await all promises
      const updatedInvites = await Promise.all(updatedInvitesPromises);

      setName(data.name || ""); // Replace with the correct data field names
      setInvites(updatedInvites);
      setUserName(data.userName || "");
      setEmail(data.email || "");
      setPhone(data.phone || "");
      setCampany(data.company || "");
      setUrl(data.url || "");
      setUsername(data.username || "");
      setPassword(data.password || "");
      setProfile(data.profile || "");
      setImage(data.image || "");
      setUserData(data); // Store user data in the state
      setLoading(false);
    } catch (error) {
      dispatch(loginError());
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  // console.log(invites);
  useEffect(() => {
    handleImage();
  }, [selectedImg]);

  // to prev the image file
  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    previewFile(file);
    setSelectedImg(file);
    setFileInputState(e.target.value);
  };

  // to read the prev the image file
  const previewFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      // setPreviewSource(reader.result);
    };
  };

  // upload image url through reader
  const handleImage = () => {
    if (!selectedImg) {
      return;
    }
    setLoading(true);
    const reader = new FileReader();
    reader.readAsDataURL(selectedImg);
    reader.onloadend = () => {
      uploadImage(selectedImg).finally(() => setLoading(false));
    };
    reader.onerror = () => {
      // console.error("AHHHHHHHH!!");
    };
  };

  // upload image api
  const uploadImage = async (file) => {
    try {
      const maxSizeMB = 10;
      const maxSizeBytes = maxSizeMB * 1024 * 1024;

      if (file.size > maxSizeBytes) {
        toast.error(`Image size should be less than ${maxSizeMB} MB`);
        return;
      }

      const formData = new FormData();
      formData.append("file", file);

      const { data } = await ApiInterceptor.post("upload/files", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      });

      setFileInputState("");
      setPreviewImage(data.publicUrl);
      setImage(data.publicUrl);
      toast.success("Image uploaded");
    } catch (err) {
      // console.error(err);
      toast.error(err?.response?.data?.message || "Error uploading image");
    }
  };

  // to subit the edit form
  const submit = async (e) => {
    e.preventDefault();
    dispatch(loginStart());
    try {
      // console.log(userid);
      const { data } = await ApiInterceptor.put(
        `user/update`,
        {
          name: name,
          email: email,
          // password: password,
          username: username,
          phone: phone,
          url: url,
          campany: campany,
          profile: image,
          image,
          userid,
        },
        config
      );
      // console.log(data);
      dispatch(loginSuccess(data));
      toast.success("Updated successfully");
      setName(data.name || "");
      setUserName(data.userName || "");
      setEmail(data.email || "");
      setPhone(data.phone || "");
      setCampany(data.company || "");
      setUrl(data.url || "");
      setUsername(data.username || "");
      // setPassword(data.password || "");
      setProfile(data.profile || "");
      // window.location.reload();
      setIsEditMode(false);
    } catch (error) {
      dispatch(loginError());
      toast.error(error?.response?.data?.message || "Error updating profile");
    }
  };
  useEffect(() => {
    fetchUserData();
  }, [isEditMode]);

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
    if (previewImage) setPreviewImage(null);
  };

  const [openDisbalePageModal, setOpenDisablePageModal] = useState(false);

  useEffect(() => {
    if (userAccountStatus) {
      setOpenDisablePageModal(true);
    }
  }, [userAccountStatus]);

  if (userAccountStatus) return <OverlayDisableAccountPage isOpen={true} />;

  return (
    <div className="min-h-screen bg-gray-50 p-4 md:p-8">
      <div className="max-w-7xl mx-auto">
        {/* Header Section */}
        <div className="mb-8">
          <h1 className="text-2xl font-bold text-gray-800">Account Settings</h1>
          <p className="text-gray-600">Manage your profile and preferences</p>
        </div>

        <div className="grid lg:grid-cols-12 gap-8">
          {/* Profile Section - Left Side */}
          <div className="lg:col-span-4">
            <div className="bg-white rounded-2xl shadow-sm p-6">
              <div className="flex flex-col items-center">
                <div className="relative group">
                  {!loading ? (
                    <div className="relative">
                      {image || previewImage ? (
                        <img
                          className="w-32 h-32 rounded-full object-cover border-4 border-white shadow-lg"
                          src={previewImage || image}
                          alt="profile"
                        />
                      ) : (
                        <div className="relative">
                          <img
                            className="w-32 h-32 rounded-full object-cover border-4 border-white shadow-lg"
                            src="/Images/profileimage.jpg"
                            alt="profile"
                          />
                          <div className="absolute inset-0 flex items-center justify-center bg-green-500 bg-opacity-75 text-white rounded-full">
                            <div className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-600 opacity-75"></div>
                            <span className="relative text-sm font-medium">
                              Upload Image
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <SpinnerCircular
                      size="90"
                      thickness="100"
                      speed="600"
                      color="white"
                      secondaryColor="black"
                    />
                  )}

                  {isEditMode && (
                    <label
                      htmlFor="edit"
                      className="absolute bottom-0 right-0 bg-blue-500 p-2 rounded-full shadow-lg cursor-pointer hover:bg-blue-600 transition-colors"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-white"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                      </svg>
                    </label>
                  )}

                  <input
                    id="edit"
                    value={fileInputState}
                    onChange={handleFileInputChange}
                    hidden
                    type="file"
                    disabled={!isEditMode}
                  />
                </div>

                <div className="mt-4 text-center">
                  <h2 className="text-xl font-semibold text-gray-800">
                    {name || "Your Name"}
                  </h2>
                  <p className="text-gray-500">
                    {email || "email@example.com"}
                  </p>
                </div>

                <button
                  onClick={toggleEditMode}
                  className={`mt-6 inline-flex items-center px-4 py-2 rounded-lg text-sm font-medium transition-colors
                    ${
                      isEditMode
                        ? "bg-gray-200 text-gray-800 hover:bg-gray-300"
                        : "bg-blue-500 text-white hover:bg-blue-600"
                    }`}
                >
                  {isEditMode ? "Cancel Editing" : "Edit Profile"}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`ml-2 h-4 w-4 ${isEditMode ? "rotate-45" : ""}`}
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          {/* Form Section - Right Side */}
          <div className="lg:col-span-8">
            <div className="bg-white rounded-2xl shadow-sm p-6">
              <form onSubmit={submit} className="space-y-6">
                <div className="grid md:grid-cols-2 gap-6">
                  {/* Business Name */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Business Name
                    </label>
                    <input
                      className="mt-1 block w-full px-4 py-3 bg-gray-50 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 transition-colors disabled:bg-gray-100 disabled:cursor-not-allowed"
                      type="text"
                      placeholder="Enter business name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      disabled={!isEditMode}
                    />
                  </div>

                  {/* Username */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Username
                    </label>
                    <input
                      className="mt-1 block w-full px-4 py-3 bg-gray-50 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 transition-colors disabled:bg-gray-100 disabled:cursor-not-allowed"
                      type="text"
                      placeholder="Enter username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      disabled={!isEditMode}
                    />
                  </div>

                  {/* Company Name */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Company Name
                    </label>
                    <input
                      className="mt-1 block w-full px-4 py-3 bg-gray-50 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 transition-colors disabled:bg-gray-100 disabled:cursor-not-allowed"
                      type="text"
                      placeholder="Enter company name"
                      value={campany}
                      onChange={(e) => setCampany(e.target.value)}
                      disabled={!isEditMode}
                    />
                  </div>

                  {/* Website URL */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Website URL
                    </label>
                    <input
                      className="mt-1 block w-full px-4 py-3 bg-gray-50 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 transition-colors disabled:bg-gray-100 disabled:cursor-not-allowed"
                      type="text"
                      placeholder="Enter website URL"
                      value={url}
                      onChange={(e) => setUrl(e.target.value)}
                      disabled={!isEditMode}
                    />
                  </div>

                  {/* Phone */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Phone Number
                    </label>
                    <input
                      className="mt-1 block w-full px-4 py-3 bg-gray-50 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 transition-colors disabled:bg-gray-100 disabled:cursor-not-allowed"
                      type="number"
                      placeholder="Enter phone number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      disabled={!isEditMode}
                    />
                  </div>

                  {/* Email */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Email Address
                    </label>
                    <input
                      className="mt-1 block w-full px-4 py-3 bg-gray-50 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 transition-colors disabled:bg-gray-100 disabled:cursor-not-allowed"
                      type="email"
                      placeholder="Enter email address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      disabled={!isEditMode}
                    />
                  </div>
                </div>

                {isEditMode && (
                  <div className="flex justify-end mt-6">
                    <button
                      type="submit"
                      className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 transition-colors"
                    >
                      {loading ? "Saving changes..." : "Save Changes"}
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Setting;
